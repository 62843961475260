<div class="award-home">
  <div class="award-home__navbar">
    <mat-card (click)="filterAwards()"
              [class]="router.url === '/admin/award' ? 'card--active': 'card'">
      Wszystkie
    </mat-card>
    <mat-card (click)="filterAwards(AWARD_STATUS.NEW)"
              [class]="router.url === '/admin/award?status=' + AWARD_STATUS.NEW ? 'card--active': 'card'">
      Nowe
    </mat-card>
    <mat-card (click)="filterAwards(AWARD_STATUS.ACCEPTED)"
              [class]="router.url === '/admin/award?status=' + AWARD_STATUS.ACCEPTED ? 'card--active': 'card'">
      Zaakceptowane
    </mat-card>
    <mat-card (click)="filterAwards(AWARD_STATUS.REJECTED)"
              [class]="router.url === '/admin/award?status=' + AWARD_STATUS.REJECTED ? 'card--active': 'card'">
      Odrzucone
    </mat-card>
    <mat-card (click)="filterAwards(AWARD_STATUS.CLOSED)"
              [class]="router.url === '/admin/award?status=' + AWARD_STATUS.CLOSED ? 'card--active': 'card'">
      Zamknięte
    </mat-card>
    <mat-card (click)="this.router.navigate(['admin/award/settings'])"
              [class]="router.url === '/admin/award/settings' ? 'card--active': 'card'">
      Ustawienia
    </mat-card>
  </div>
  <div class="award-home__content">
    <router-outlet></router-outlet>
  </div>
</div>
