import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ProposalSettingsModel} from '../../../../models/requests/proposal/proposal-settings.model';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-proposal-settings',
  templateUrl: './proposal-settings.component.html',
  styleUrls: ['./proposal-settings.component.scss']
})
export class ProposalSettingsComponent implements OnInit {

  public proposalSettings: ProposalSettingsModel;
  public isLoading: boolean;

  constructor(
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getProposalSettings().subscribe((response: any) => {
        this.isLoading = false;
        this.proposalSettings = response.data[0];
      },
      error => {
        this.errorService.handleError(error);
      });
  }

  public onFormSubmit() {
    this.apiService.updateProposalSettings(this.proposalSettings).subscribe((response: any) => {
        this.sweetalertService.success();
      },
      error => {
        this.errorService.handleError(error);
      });
  }

}
