import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {FileDownloaderService} from "./file-downloader.service";

@Component({
  selector: 'app-file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.scss']
})
export class FileDownloaderComponent implements OnInit {
  public files = [];
  private isNewFile: boolean = true;

  constructor(
    private apiService: ApiService,
    private fileDownloader: FileDownloaderService,
  ) { }

  ngOnInit(): void {
    this.fileDownloader.filesList.subscribe( data => {
      if (this.isNewFile) {
        this.files = data;
        this.downloadFile(this.files[this.files.length - 1]);
      } else {
        this.isNewFile = true;
      }
    });
  }

  private downloadFile(file) {
    this.apiService.downloadFile(file.id).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const a = document.createElement('a')
      const url= window.URL.createObjectURL(blob);

      a.href = url
      a.download = file.name;
      a.click();
      URL.revokeObjectURL(url);

      this.isNewFile = false;
      this.fileDownloader.removeFile(file);
    });
  }
}
