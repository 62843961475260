import {Injectable} from '@angular/core';

@Injectable()
export class DateService {
  private months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];

  public prepareDate(date) {
    const unix = Date.parse(date);
    date = new Date(unix);

    return (date.getDate()) + ' ' + this.months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  }

  public prepareFullDate(date) {
    const unix = Date.parse(date);
    date = new Date(unix);

    return this.prepareDate(date) + ':' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
  }
}
