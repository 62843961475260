import {Component, Inject, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {DateService} from '../../../../services/date.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PoolStatusModel} from '../../../../models/requests/pool/pool-status.model';
import {POOL_STATUS} from '../../../../enums/pool-status.enum';
import {PoolListService} from '../pool-list/pool-list.service';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-pool-accept',
  templateUrl: './pool-accept.component.html',
  styleUrls: ['./pool-accept.component.scss']
})
export class PoolAcceptComponent implements OnInit {
  public isLoading = false;
  public disabled;
  public poolStatus: PoolStatusModel = {
    pool: null,
    message: ''
  };
  public POOL_STATUS: typeof POOL_STATUS = POOL_STATUS;
  @Input() public pool;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    public dateService: DateService,
    public poolListService: PoolListService,
  ) {
  }

  ngOnInit(): void {
  }

  public accept() {
    const requestPoolStatus: PoolStatusModel = {
      pool: this.data.poolId,
      message: this.poolStatus.message
    };

    this.disabled = true;

    if (this.poolStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.acceptPool(requestPoolStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.poolStatus.message = null;
      this.poolListService.refreshPoolList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public decline() {
    const requestPoolStatus: PoolStatusModel = {
      pool: this.data.poolId,
      message: this.poolStatus.message
    };

    this.disabled = true;

    if (this.poolStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.declinePool(requestPoolStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.poolStatus.message = null;
      this.poolListService.refreshPoolList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

}
