import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import {ErrorService} from '../../../../../services/error.service';
import {SweetalertService} from '../../../../../services/sweetalert.service';
import {AuthService} from '../../../../../services/auth.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {

  isLoading = false;
  isWaiting = false;
  proposalId: number;
  proposal = {
    authors: [],
    publishers: [],
  };
  proposalEmailsModel = [];

  emailForm: FormGroup = this.formBuilder.group({
    emails: this.formBuilder.array([])
  });

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    private sweetalertService: SweetalertService,
    private authService: AuthService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.proposalId = Number(route.snapshot.paramMap.get('id'));
  }

  ngOnInit(): void {
    this.loadProposal(this.proposalId);
  }

  public onSubmit(form: FormGroup) {

    if (!form.valid) {
      this.sweetalertService.formError('Formularz jest wypełniony błędnie. Popraw go i spróbuj ponownie.');
      return;
    }
    this.isWaiting = true;
    this.apiService.postProposalEmailsRequest(this.proposalId, form.getRawValue()).subscribe((response: any) => {
      this.isWaiting = false;
      if (response.status === 200) {
        this.sweetalertService.success();
        this.router.navigateByUrl('');
      }
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });
  }

  appendItem(data) {
    const form = this.formBuilder.group({
      id: [false, Validators.required],
      isAuthor: [false, Validators.required],
      isPublisher: [false, Validators.required],
      email: new FormControl({value: '', disabled: true}),
      newEmail: [null, Validators.email],
    });

    const arrayControl = <FormArray> this.emailForm.get('emails');
    form.patchValue(data);
    arrayControl.push(form);
  }

  private loadProposal(proposalId: number) {
    this.isLoading = true;
    this.apiService.getProposalByPublisher(proposalId).subscribe((response: any) => {
      this.isLoading = false;
      this.proposal = response.data;
      this.prepareData();
      this.buildForm();
    }, error => {
      this.errorService.handleError(error);
    });
  }

  private prepareData() {
    this.proposal.authors.forEach(author => {
      const data = {
        id: author.id,
        name: author.name,
        email: author.email,
        isAuthor: true,
        isPublisher: false,
      };

      this.proposalEmailsModel.push(data);
    });

    this.proposal.publishers.forEach(publisher => {
      const data = {
        id: publisher.id,
        name: publisher.publisher_name,
        email: publisher.email,
        isAuthor: false,
        isPublisher: true,
      };

      this.proposalEmailsModel.push(data);
    });

  }

  private buildForm() {

    this.proposalEmailsModel.forEach(item => {

      // item.newEmail = Math.floor(Math.random() * Math.floor(4));
      this.appendItem(item);
    });
  }
}
