<div class="account-home">
  <div class="row">
    <div class="col-lg"><h2>Zarządzanie użytkownikami</h2></div>
    <div class="col-lg"><button mat-mini-fab color="primary" (click)="openCreateUserDialog()" >
      <mat-icon>add</mat-icon>
    </button>
    </div>
  </div>
  <div [hidden]="accountHomeService.isLoading">
    <mat-form-field class="filter">
      <mat-label> Filtrowanie</mat-label>
      <input matInput (keyup)="applyFilter($event)" aria-label="filter" #input>
    </mat-form-field>

    <div class="mb mat-elevation-z8 account-home-wrapper">
      <table mat-table [dataSource]="users" class="account-home__table" matSort>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="id-column"> ID</th>
          <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa użytkownika</th>
          <td mat-cell *matCellDef="let element"> {{ element.username }} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
          <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
        </ng-container>
        <ng-container matColumnDef="isDeactivated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Aktywny?</th>
          <td mat-cell *matCellDef="let element">
            <div [ngClass]="element.isDeactivated ? 'status status--inactive': 'status status--active'"></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef class="options-column"> Opcje</th>
          <td mat-cell *matCellDef="let element">
            <button mat-mini-fab color="primary" (click)=openDialog(element.id)>
              <mat-icon> edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <ng-container *ngIf="users">
        <mat-card *ngIf="users.filteredData.length === 0" [hidden]="accountHomeService.isLoading">
          Brak wyników do wyświetlenia
        </mat-card>
      </ng-container>

      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </div>
  <div class="loading" *ngIf="accountHomeService.isLoading">
    <mat-spinner></mat-spinner>
  </div>
</div>
