import {Pipe, PipeTransform} from '@angular/core';
import {PROPOSAL_STATUS} from '../enums/proposal-status.enum';

@Pipe({name: 'proposalStatusToText'})
export class ProposalStatusToText implements PipeTransform {

  transform(value: number) {
    switch (value) {
      case PROPOSAL_STATUS.NEW:
        return 'Nowy';
      case PROPOSAL_STATUS.ACCEPTED:
        return 'Zaakceptowany';
      case PROPOSAL_STATUS.REJECTED:
        return 'Odrzucony';
      case PROPOSAL_STATUS.CLOSED:
        return 'Zakończony';
      default:
        return '';
    }
  }
}
