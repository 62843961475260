import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ErrorService} from '../../../../services/error.service';
import {ChangeEmailByTokenModel} from '../../../../models/requests/account/change-email-by-token.model';

@Component({
  selector: 'app-change-email-by-token',
  templateUrl: './change-email-by-token.component.html',
  styleUrls: ['./change-email-by-token.component.scss']
})
export class ChangeEmailByTokenComponent implements OnInit {

  private routeSub: Subscription;
  public isWaiting = false;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      const changeEmailByTokenModel: ChangeEmailByTokenModel = {
        hash: params.hash,
      };

      this.isWaiting = true;

      this.apiService.changeEmailByToken(changeEmailByTokenModel).subscribe((response: any) => {
        this.isWaiting = false;
        this.router.navigate(['/publisher/options']);
        this.sweetalertService.changedEmailSuccess();
      }, error => {
        this.isWaiting = false;
        this.router.navigate(['/publisher/options']);
        this.errorService.handleError(error);
      });
    });
  }
}
