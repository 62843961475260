<div class="proposal-upload">
  <mat-form-field class="proposal-name">
    <mat-label> Nazwa dofinansowania</mat-label>
    <input aria-label="proposal-name" matInput placeholder="np. Karol Nowak, konkurs wojewódzki" type="text"
    >
  </mat-form-field>
  <div class="files-card-wrapper">
    <!--    <mat-card class="file-card" *ngIf="!fileToUpload">-->
    <!--      <button mat-mini-fab color="primary" class="file-card__add">-->
    <!--        <mat-icon class="file-card__icon">-->
    <!--          <div class="form-group">-->
    <!--            <label for="file"> add</label>-->
    <!--            <input type="file" id="file" class="hide-input"-->
    <!--                   (change)="uploadFile($event.target.files)">-->
    <!--          </div>-->
    <!--        </mat-icon>-->
    <!--      </button>-->
    <!--    </mat-card>-->
    <!--    <mat-card class="file-card" *ngIf="fileToUpload">-->
    <!--      <mat-spinner class="file-card__mat-spinner" [diameter]="40"></mat-spinner>-->
    <!--    </mat-card>-->
    <!--    <mat-card class="file-card" *ngFor="let file of award.files">-->
    <!--      <div class="file-card__content">-->
    <!--        <div class="title">-->
    <!--          {{ file.name }}-->
    <!--        </div>-->
    <!--        <div class="sub-title">-->
    <!--          Utworzono {{ file.created_at }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <button mat-mini-fab color="warn" class="admin-sidenav-component__button" (click)="deleteFile(file.id)">-->
    <!--        <mat-icon> delete</mat-icon>-->
    <!--      </button>-->
    <!--    </mat-card>-->
  </div>
  <!--  <button mat-raised-button color="primary" (click)="onFormSubmit()" [disabled]="fileToUpload !== null"> Wyślij</button>-->
</div>
