<div *ngIf="scholarship" class="scholarship-manage">
  <div class="scholarship-manage__menu">
    <mat-card (click)="setActiveSection(1)"
              [class]="activeSection === 1 ? 'card--active': 'card'"> Podgląd
    </mat-card>
    <mat-card (click)="setActiveSection(2)"
              [class]="activeSection === 2 ? 'card--active': 'card'"> Edycja
    </mat-card>
    <mat-card (click)="setActiveSection(3)"
              [class]="activeSection === 3 ? 'card--active': 'card'"> Akceptacja
    </mat-card>
    <mat-card (click)="setActiveSection(4)"
              [class]="activeSection === 4 ? 'card--active': 'card'"> Rozliczenie
    </mat-card>
    <mat-card (click)="deleteScholarship(scholarship.id)" class="card--danger"> Usuń</mat-card>
  </div>
  <mat-divider></mat-divider>
  <section *ngIf="activeSection === 1">
    <app-scholarship-preview [scholarship]="scholarship"></app-scholarship-preview>
  </section>
  <section *ngIf="activeSection === 2">
    <app-scholarship-upload [scholarship]="scholarship"></app-scholarship-upload>
  </section>
  <section *ngIf="activeSection === 3">
    <app-scholarship-accept [scholarship]="scholarship"></app-scholarship-accept>
  </section>
  <section *ngIf="activeSection === 4">
    <app-scholarship-settlements [scholarship]="scholarship"></app-scholarship-settlements>
  </section>
</div>
<div *ngIf="!scholarship" class="scholarship-manage__loader">
  <mat-spinner></mat-spinner>
</div>
