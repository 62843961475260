import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'scholarshipStatusToText'})
export class ScholarshipStatusToText implements PipeTransform {

  transform(value: number) {
    switch (value) {
      case 0:
        return 'Nowe';
      case 1:
        return 'Zaakceptowane';
      case 2:
        return 'Odrzucone';
      case 3:
        return 'Zakończone';
      default:
        return '';
    }
  }
}
