import {Injectable} from "@angular/core";
import {ApiService} from "../../../../services/api.service";
import {SweetalertService} from "../../../../services/sweetalert.service";
import {Subject} from "rxjs";
import {ErrorService} from '../../../../services/error.service';

@Injectable()
export class ScholarshipManageService {
  private observer = new Subject();
  public scholarship = this.observer.asObservable();

  constructor(private apiService: ApiService, private errorService: ErrorService) {
  }

  public updateScholarship(id) {
    this.apiService.getScholarship(id).subscribe((response: any) => {
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    })
  }
}
