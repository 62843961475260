import {Injectable, ViewChild} from "@angular/core";
import {Subject} from "rxjs";
import {ApiService} from "../../../services/api.service";
import {SweetalertService} from "../../../services/sweetalert.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ErrorService} from '../../../services/error.service';

@Injectable()
export class AccountHomeService {
  private observer: any = new Subject();
  public users = this.observer.asObservable();
  public isLoading;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService
  ) {
  }

  public refreshUsersList() {
    this.isLoading = true;
    this.apiService.getAllUsers().subscribe((response: any) => {
      this.isLoading = false;
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    })
  }
}
