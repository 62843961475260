import {Component, Input} from '@angular/core';
import {ApiService} from '../../../services/api.service';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent {
  @Input() fileId: number;
  @Input() fileName: string;

  constructor(private apiService: ApiService) {
  }

  public downloadFile() {
    this.apiService.downloadFile(this.fileId).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = this.fileName;
      a.click();
      URL.revokeObjectURL(url);
    });
  }
}
