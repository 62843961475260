<div class="award-list">
  <button mat-raised-button color="primary" (click)="createNewAwardAgreement()">
    Dodaj nową nagrodę do rozpatrzenia
  </button>
  <div [hidden]="awardAgreementService.isLoading">
    <mat-form-field class="filter">
      <mat-label> Filtrowanie</mat-label>
      <input matInput (keyup)="applyFilter($event)" aria-label="filter" #input>
    </mat-form-field>
    <div class="mb mat-elevation-z8">
      <table mat-table [dataSource]="awards" class="mat-elevation-z8 award-list__table" matSort>

        <ng-container matColumnDef="id" class="">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="id-column"> ID</th>
          <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tytuł</th>
          <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Złożył użytkownik</th>
          <td mat-cell *matCellDef="let element"> {{element.user.username}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
          <td mat-cell *matCellDef="let element"> {{getStatusText(element.status)}}</td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data złożenia</th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt|date:'yyyy-MM-dd H:mm'}} </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef class="options-column"> Opcje</th>
          <td mat-cell *matCellDef="let element">
            <button mat-mini-fab color="primary" (click)=openDialog(element.id)>
              <mat-icon> edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <ng-container *ngIf="awards">
        <mat-card *ngIf="awards.filteredData.length === 0" [hidden]="awardAgreementService.isLoading">
          Brak wyników do wyświetlenia
        </mat-card>
      </ng-container>

      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </div>
  <div class="loading" *ngIf="awardAgreementService.isLoading">
    <mat-spinner></mat-spinner>
  </div>
</div>
