import {Component, Inject, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {DateService} from '../../../../services/date.service';
import {ProposalManageService} from '../proposal-manage/proposal-manage.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ProposalStatusModel} from '../../../../models/requests/proposal/proposal-status.model';
import {ErrorService} from '../../../../services/error.service';
import {ProposalListService} from '../proposal-list/proposal-list.service';
import {PROPOSAL_STATUS} from '../../../../enums/proposal-status.enum';

@Component({
  selector: 'app-proposal-accept',
  templateUrl: './proposal-accept.component.html',
  styleUrls: ['./proposal-accept.component.scss']
})
export class ProposalAcceptComponent implements OnInit {

  public isLoading = false;
  public PROPOSAL_STATUS: typeof PROPOSAL_STATUS = PROPOSAL_STATUS;
  public disabled;
  public proposalStatus: ProposalStatusModel = {
    proposal: null,
    message: ''
  };
  @Input() public proposal;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    public dateService: DateService,
    private errorService: ErrorService,
    public proposalManageService: ProposalManageService,
    public proposalListService: ProposalListService,
  ) {
  }

  ngOnInit(): void {
  }

  public accept() {
    const requestProposalStatus: ProposalStatusModel = {
      proposal: this.data.proposalId,
      message: this.proposalStatus.message
    };

    this.disabled = true;

    if (this.proposalStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.acceptProposal(requestProposalStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.proposalStatus.message = null;
      this.proposalListService.refreshProposalList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public decline() {
    const requestProposalStatus: ProposalStatusModel = {
      proposal: this.data.proposalId,
      message: this.proposalStatus.message
    };

    this.disabled = true;

    if (this.proposalStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.declineProposal(requestProposalStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.proposalStatus.message = null;
      this.proposalListService.refreshProposalList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

}
