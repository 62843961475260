<div class="account-upload">
  <h2> Aktualizacja danych użytkownika</h2>
  <form [formGroup]="formProfile"  (ngSubmit)="onFormSubmit(formProfile)">
  <div class="form-wrapper">
    <mat-form-field class="form-wrapper__input">
      <mat-label> Nazwa użytkownika</mat-label>
      <input formControlName="username" disabled="disabled" matInput name="username" placeholder=" Np. user123" type="text">
    </mat-form-field>

    <mat-form-field class="form-wrapper__input">
      <mat-label> Email</mat-label>
      <input formControlName="email" disabled="disabled" matInput name="email" placeholder=" Np. user123@domain.pl" type="text">
    </mat-form-field>
  </div>
  <div class="form-wrapper">
    <mat-form-field class="form-wrapper__input">
      <mat-label> Hasło</mat-label>
      <input formControlName="password" matInput name="password" placeholder=" Np. 58U#mEp46ADA\#NJ" type="password"
             minlength="6" >
      <mat-hint *ngIf="formProfile?.controls.password?.touched && formProfile?.controls.password?.errors" [class.error]="true">
        Hasło powinno mieć conajmniej 8 znaków w tym minimum jedną wielką literę, jedną cyfrę i jeden znak specjalny
      </mat-hint>

    </mat-form-field>

    <mat-form-field class="form-wrapper__input">
      <mat-label> Powtórz hasło</mat-label>
      <input formControlName="repeatPassword" matInput name="repeat-password" placeholder=" Powtórz poprzednie hasło" class=""
             type="password">
      <mat-hint *ngIf="formProfile?.controls.repeatPassword?.touched && formProfile?.controls.repeatPassword?.errors" [class.error]="true">
        Hasła różnią się od siebie
      </mat-hint>
    </mat-form-field>
  </div>
  <h3> Dostępy użytkownika</h3>
  <div class="row">
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.USER)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.USER) ? 'card card__success' : 'card card__danger'">
      Użytkownicy
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.FILE)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.FILE) ? 'card card__success' : 'card card__danger'">
      Pliki
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.PROPOSAL)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.PROPOSAL) ? 'card card__success' : 'card card__danger'">
      Wnioski
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.SCHOLARSHIP)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.SCHOLARSHIP) ? 'card card__success' : 'card card__danger'">
      Stypendia
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.AWARD)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.AWARD) ? 'card card__success' : 'card card__danger'">
      Nagrody
    </mat-card>
  </div>
  <div class="row">
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.LOG)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.LOG) ? 'card card__success' : 'card card__danger'">
      Logi
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.POOL)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.POOL) ? 'card card__success' : 'card card__danger'">
      Pule
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.PROPOSAL_SETTINGS)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.PROPOSAL_SETTINGS) ? 'card card__success' : 'card card__danger'">
      Ustawienia wniosków
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.SCHOLARSHIP_SETTINGS)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.SCHOLARSHIP_SETTINGS) ? 'card card__success' : 'card card__danger'">
      Ustawienia stypendiów
    </mat-card>
    <mat-card
      (click)="togglePermissions(USER_PERMISSIONS.AWARD_SETTINGS)"
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.AWARD_SETTINGS) ? 'card card__success' : 'card card__danger'">
      Ustawienia nagród
    </mat-card>
  </div>
  <button color="primary" mat-raised-button type="submit"> Wyślij</button>
  </form>
</div>
