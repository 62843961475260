<div class="sidenav-component">
  <img src="assets/images/logo.jpg" class="sidenav-component__logo" alt="logo">

  <mat-divider></mat-divider>

  <mat-list>
    <mat-divider></mat-divider>
    <mat-list-item>
      <button mat-button color="primary" class="sidenav-component__button" routerLink="/publisher/dashboard">
        Strona główna
      </button>
    </mat-list-item>
    <mat-list-item>
      <button mat-button color="primary" class="sidenav-component__button" routerLink="/publisher/proposals" routerLinkActive="mat-flat-button">
        Moje wnioski
      </button>
    </mat-list-item>
    <mat-list-item>
      <button mat-button color="primary" class="sidenav-component__button" routerLink="/publisher/proposal/create" routerLinkActive="mat-flat-button">
        Nowy wniosek
      </button>
    </mat-list-item>
    <mat-list-item>
      <button mat-button color="primary" class="sidenav-component__button" routerLink="/publisher/options" routerLinkActive="mat-flat-button">
        Opcje
      </button>
    </mat-list-item>

    <mat-divider></mat-divider>

    <mat-list-item>
      <button mat-button color="warn" class="sidenav-component__button" (click)="logout()">
        Wyloguj
      </button>
    </mat-list-item>
  </mat-list>
</div>
