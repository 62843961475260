<div class="pool-upload">
  <mat-form-field class="pool-input">
    <mat-label>Nazwa puli</mat-label>
    <input [(ngModel)]="pool.name" aria-label="pool name" matInput
           placeholder="np. Dofinansowanie książek kryminalnych - edycja 2020"
           type="text">
  </mat-form-field>
  <h2>Warunki</h2>
  <mat-form-field *ngFor="let condition of pool.poolConditions" class="pool-input">
    <mat-label>Warunek</mat-label>
    <input (change)="managePoolConditions()" [(ngModel)]="condition.name" aria-label="pool condition" matInput
           placeholder="np. Nakład większy niż 30000 kopii" type="text">
    <hr />
  </mat-form-field>
  <h2>Typy publikacji</h2>
  <ng-container *ngFor="let publicationType of pool.poolPublicationTypes">
    <mat-form-field class="pool-input">
      <mat-label>Typ</mat-label>
      <input [(ngModel)]="publicationType.name" aria-label="pool publication type" matInput required
             placeholder="np. Kryminał" type="text">
    </mat-form-field>
    <mat-form-field class="pool-input">
      <mat-label> Maksymalna kwota wynagrodzenia</mat-label>
      <input (change)="managePoolPublicationTypes()" [(ngModel)]="publicationType.maximumAmountAwards" aria-label="pool publication type" matInput
             placeholder="np. 30000" type="number">
    </mat-form-field>
    <hr />
  </ng-container>
  <h2>Pozostałe informacje</h2>
  <p>Maksymalne dofinansowanie dla tej puli: {{ poolMaximumAmountAwards }}</p>
  <div class="row">
    <mat-form-field appearance="fill">
      <mat-label>Data rozpoczęcia</mat-label>
      <input [(ngModel)]="pool.startDate" [matDatepicker]="startPicker" aria-label="start date" matInput>
      <mat-datepicker-toggle [for]="startPicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Data zakończenia</mat-label>
      <input [(ngModel)]="pool.endDate" [matDatepicker]="endPicker" aria-label="end date" matInput>
      <mat-datepicker-toggle [for]="endPicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <button (click)="onFormSubmit()" [disabled]="fileToUpload !== null" color="primary" mat-raised-button> Wyślij</button>
</div>
