import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../../../services/error.service';
import {ProposalsFilterModel} from '../../../../models/requests/proposal/proposals-filter.model';

@Injectable()
export class ProposalListService {
  public status;
  public pool;
  public isLoading;
  private observer: any = new Subject();
  public proposals = this.observer.asObservable();

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.url.startsWith('/admin/co-financing/proposal')) {
        this.status = params.status;
        this.pool = params.pool;
        this.refreshProposalList();
      }
    });
  }

  public refreshProposalList(params?: ProposalsFilterModel) {
    params = {
      ...params,
      status: this.status,
      pool: this.pool
    };
    this.isLoading = true;
    this.apiService.getProposals(params).subscribe((response: any) => {
      this.isLoading = false;
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    });
  }
}
