<h4>Publikacja {{publicationNumber}}</h4>
<div class="row">
  <app-summary-field name="Tytuły są zgłoszone w PLR">
    {{ publishing.reportedPlr | booleanToString }}
  </app-summary-field>
  <app-summary-field name="Tytuły są zgłoszone umowie powierzenia praw">
    {{ publishing.reportedSubContract | booleanToString }}
  </app-summary-field>
  <app-summary-field name="Tytuły są zgłoszone w deklaracji członkostwa">
    {{ publishing.reportedMembershipDeclaration | booleanToString }}
  </app-summary-field>
  <app-summary-field name="Debiutant bez dorobku literackiego" *ngIf="publishing.debutantWithoutLiteraryAchievements !== undefined">
    {{ publishing.debutantWithoutLiteraryAchievements | booleanToString }}
  </app-summary-field>
</div>
<div class="row">
  <app-summary-field name="Autor">
    {{publishing.author}}
  </app-summary-field>
  <app-summary-field name="Tytuł">
    {{publishing.title}}
  </app-summary-field>
  <app-summary-field name="ISBN">
    {{publishing.isbn}}
  </app-summary-field>
  <app-summary-field name="Wydawca">
    {{publishing.publisher}}
  </app-summary-field>
  <app-summary-field name="Rok wydania">
    {{publishing.publishDate}}
  </app-summary-field>
</div>
<div class="row">
  <app-summary-field name="Rola w publikacji">
    {{publishing.roleInPublication}}
  </app-summary-field>
</div>
