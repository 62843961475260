<div class="account-recovery">
  <img src="assets/images/logo.jpg" alt="logo" class="account-recovery__logo">
  <mat-card class="account-recovery-card">
    <mat-card-title class="account-recovery-card__title"> Odzyskiwanie konta</mat-card-title>
    <mat-card-subtitle>Wpisz adres e-mail podany przy rejestracji konta, do którego chcesz odzyskać hasło. Pod podany adres zostanie wysłana instrukcja z dalszymi krokami.</mat-card-subtitle>
    <mat-card-content class="account-recovery-card__content">
      <form [formGroup]="accountRecoveryForm" (ngSubmit)="onFormSubmit(accountRecoveryForm)">
        <mat-form-field class="account-recovery-card__input">
          <mat-label> E-mail</mat-label>
          <input matInput placeholder="Np. pat@example.com" aria-label="email" formControlName="email">
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary">Wyślij</button>
      </form>
    </mat-card-content>
    <mat-card-footer class="account-recovery-card__footer">
      <a routerLink="/login" routerLinkActive="active">Zaloguj się</a>
    </mat-card-footer>
  </mat-card>
</div>
<app-loader [isOpen]="isWaiting"></app-loader>
