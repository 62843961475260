<div *ngIf="proposal" class="proposal-manage">
  <div class="proposal-manage__menu" *ngIf="proposal">
    <mat-card (click)="setActiveSection(1)" [class]="activeSection === 1 ? 'card--active': 'card'">
      Informacje
    </mat-card>
    <mat-card (click)="setActiveSection(2)" [class]="activeSection === 2 ? 'card--active': 'card'">
      Podgląd
    </mat-card>
    <mat-card (click)="setActiveSection(3)" [class]="activeSection === 3 ? 'card--active': 'card'">
      Akceptacja
    </mat-card>
    <mat-card (click)="setActiveSection(4)" [class]="activeSection === 4 ? 'card--active': 'card'">
      Rozliczenie
    </mat-card>
  </div>
  <mat-divider></mat-divider>
  <section *ngIf="activeSection === 1">
    <app-proposal-information [proposal]="proposal"></app-proposal-information>
  </section>
  <section *ngIf="activeSection === 2 && isLoaded">
    <app-proposal-preview [proposal]="proposal"></app-proposal-preview>
  </section>
  <section *ngIf="activeSection === 3">
    <app-proposal-accept [proposal]="proposal"></app-proposal-accept>
  </section>
  <section *ngIf="activeSection === 4">
    <app-proposal-settlements [proposal]="proposal"></app-proposal-settlements>
  </section>
</div>
<div *ngIf="!proposal" class="proposal-manage__loader">
  <mat-spinner></mat-spinner>
</div>
