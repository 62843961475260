<div class="scholarship-preview">
  <div class="scholarship-preview__name"> {{ scholarship.name }}</div>
  <mat-list role="list">
    <mat-list-item role="listitem"><b> Utworzono:</b> {{ dateService.prepareDate(scholarship.createdAt) }}
    </mat-list-item>
    <mat-list-item role="listitem"><b> Ostatnia aktualizacja:</b> {{ dateService.prepareDate(scholarship.updatedAt) }}
    </mat-list-item>
  </mat-list>
  <div class="files-card-wrapper">
    <mat-card *ngFor="let file of scholarship.files" class="file-card">
      <div class="file-card__content">
        <div class="title">
          {{ file.name }}
        </div>
        <div class="sub-title">
          Utworzono {{ dateService.prepareDate(file.createdAt) }}
        </div>
      </div>
      <button
        (click)="downloadFile(file)" *ngIf="!fileDownloader.isDownloading(file)"
        color="primary" mat-mini-fab>
        <mat-icon> get_app</mat-icon>
      </button>
      <button
        *ngIf="fileDownloader.isDownloading(file)" class="admin-sidenav-component__button"
        color="primary"
        mat-mini-fab>
        <mat-icon> hourglass_bottom</mat-icon>
      </button>
    </mat-card>
    <mat-card *ngIf="scholarship.files.length === 0">
      <h2> Brak plików</h2>
    </mat-card>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Akceptacje</h2>
  <div class="accept-card__wrapper">
    <div *ngIf="scholarship.scholarshipStatuses.length === 0">
      Nikt jeszcze nie zaakceptował tego stypendium.
    </div>
    <ng-container *ngFor="let action of this.scholarship.scholarshipStatuses">
      <mat-card [class.accept-card--success]="action.isAccepted"
        [class.accept-card--warn]="!action.isAccepted" class="accept-card" *ngIf="action.isActive">
        <mat-card-title *ngIf="action.isAccepted"> Zaakceptowane</mat-card-title>
        <mat-card-title *ngIf="!action.isAccepted"> Odrzucone</mat-card-title>
        <mat-card-subtitle> Przez {{ action.user.username }}
          dnia {{ dateService.prepareFullDate(action.createdAt) }}</mat-card-subtitle>
        <mat-card-content>
          {{ action.message }}
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Rozliczenia</h2>
  <div class="settlement-card__wrapper">
    <div *ngIf="!scholarship.scholarshipSettlement">
      Jeszcze nie rozliczono tego stypendium.
    </div>
    <div *ngIf="scholarship.scholarshipSettlement">
      <mat-list role="list">
        <mat-list-item *ngIf="scholarship.scholarshipSettlement.isCorrect" role="listitem"> Rozliczono poprawnie
        </mat-list-item>
        <mat-list-item *ngIf="!scholarship.scholarshipSettlement.isCorrect" role="listitem"> Rozliczono niepoprawnie
        </mat-list-item>
        <mat-list-item role="listitem"><b> Komentarz:</b> {{ scholarship.scholarshipSettlement.comment }}
        </mat-list-item>
      </mat-list>
      <div class="files-card-wrapper">
        <mat-card *ngFor="let file of scholarship.scholarshipSettlement.files" class="file-card">
          <div class="file-card__content">
            <div class="title">
              {{ file.name }}
            </div>
            <div class="sub-title">
              Utworzono {{ dateService.prepareDate(file.createdAt) }}
            </div>
          </div>
          <button
            (click)="downloadFile(file)" *ngIf="!fileDownloader.isDownloading(file)"
            color="primary" mat-mini-fab>
            <mat-icon> get_app</mat-icon>
          </button>
          <button
            *ngIf="fileDownloader.isDownloading(file)" class="admin-sidenav-component__button"
            color="primary"
            mat-mini-fab>
            <mat-icon> hourglass_bottom</mat-icon>
          </button>
        </mat-card>
        <mat-card *ngIf="scholarship.files.length === 0">
          <h2> Brak plików</h2>
        </mat-card>
      </div>
    </div>
  </div>
</div>
