import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {AccountHomeService} from '../account-home.service';
import {ErrorService} from '../../../../services/error.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StrengthPasswordValidator} from '../../../../validators/strengthPasswordValidator';
import {RepeatedPasswordValidator} from '../../../../validators/repetedPasswordValidator';
import {USER_PERMISSIONS} from '../../../../enums/user-permissions.enum';
import {AccountUploadModel} from '../../../../models/requests/account/account-upload.model';

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.scss'],
  providers: [RepeatedPasswordValidator, StrengthPasswordValidator]
})
export class AccountCreateComponent implements OnInit {
  public hasPermissions = [];

  public USER_PERMISSIONS: typeof USER_PERMISSIONS = USER_PERMISSIONS;

  public formProfile: FormGroup = this.formBuilder.group({
    username: [null],
    email: [null],
    password: [null, [Validators.required]],
    repeatPassword: [null, [Validators.required]],
  }, {
    validator: [
      StrengthPasswordValidator.isStrength('password'),
      RepeatedPasswordValidator.mustMatch('password', 'repeatPassword'),
    ]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private dialogRef: MatDialog,
    private accountHomeService: AccountHomeService,
    private errorService: ErrorService,
    public formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.formProfile.reset();
    // this.formProfile.patchValue(this.account);
  }

  public togglePermissions(permission) {
    if (this.hasPermissions.includes(permission)) {
      this.hasPermissions.splice(this.hasPermissions.indexOf(permission), 1);
    } else {
      this.hasPermissions.push(permission);
    }
  }

  public onFormSubmit(form: FormGroup) {
    if (!form.valid) {
      this.sweetalertService.registerFormError();
      return;
    }

    const requestAccount: AccountUploadModel = {};
    requestAccount.password = form.get('password').value;
    requestAccount.hasPermissions = this.hasPermissions;
    requestAccount.email = form.get('email').value;
    requestAccount.username = form.get('username').value;
    this.apiService.createUser(requestAccount).subscribe((response: any) => {
      this.sweetalertService.success();
      this.accountHomeService.refreshUsersList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
      const passwordErr = error.error.validationErrors.password;
      if (typeof passwordErr !== 'undefined') {
        this.sweetalertService.formError(passwordErr[0]);
      } else {
        this.sweetalertService.formError('Podana nazwa użytkownika lub email już są zajęte');
      }
    });
  }
}
