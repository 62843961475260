import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'booleanToString'})
export class BooleanToString implements PipeTransform {

  transform(value: boolean) {
    switch (value) {
      case true:
        return 'Tak';
      case false:
        return 'Nie';
      default:
        return 'Nie';
    }
  }
}
