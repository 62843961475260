export enum USER_PERMISSIONS {
  USER = 'user',
  PROPOSAL = 'proposal',
  FILE = 'file',
  AWARD = 'award',
  SCHOLARSHIP = 'scholarship',
  LOG = 'log',
  POOL = 'pool',
  AWARD_SETTINGS = 'award-settings',
  SCHOLARSHIP_SETTINGS = 'scholarship-settings',
  PROPOSAL_SETTINGS = 'proposal-settings',
}
