import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ErrorService} from '../../../../services/error.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {ProposalModel} from '../../../../models/requests/proposal/proposal.model';
import {AuthorModel} from '../../../../models/requests/author/author.model';
import {MembershipModel} from '../../../../models/requests/membership/membership.model';
import {PublisherModel} from '../../../../models/requests/publisher/publisher.model';
import {PublicationModel} from '../../../../models/requests/publication/publication.model';
import {PublicationCostModel} from '../../../../models/requests/publicationCost/publicationCost.model';
import {NipValidator} from '../../../../validators/nipValidator';
import {PeselValidator} from '../../../../validators/peselValidator';
import {IsbnValidator} from '../../../../validators/isbnValidator';
import {PhoneValidator} from '../../../../validators/phoneValidator';
import {MinThreePublisherPublicationValidator} from '../../../../validators/minThreePublisherPublicationValidator';
import {MatSelectChange} from '@angular/material/select';
import {ProposalUtils} from '../../../../services/ProposalUtils';
import {MatVerticalStepper} from '@angular/material/stepper';
import {PROPOSAL_STATUS} from '../../../../enums/proposal-status.enum';
import {AuthorPublicationModel} from '../../../../models/requests/publication/authorPublication.model';
import {UniqueAuthorEmailValidator} from '../../../../validators/uniqueAuthorEmailValidator';
import {UniquePublisherEmailValidator} from '../../../../validators/uniquePublisherEmailValidator';
import {AvailableProposalPoolValidator} from '../../../../validators/availableProposalPoolValidator';


@Component({
  selector: 'app-proposal-create',
  templateUrl: './proposal-create.component.html',
  styleUrls: ['./proposal-create.component.scss'],
  providers: [MinThreePublisherPublicationValidator]
})
export class ProposalCreateComponent implements OnInit {

  @ViewChild('stepper') stepper: MatVerticalStepper;

  public isLoading = false;
  public isSending = false;
  public isCreated = false;

  public defaultEmail = '';
  public selectedPool = null;
  public stage1: FormGroup;
  public stage2: FormGroup = this.formBuilder.group({
    poolPublicationType: [null, Validators.required],
    amountFunding: [0],
    publishTitle: [null, Validators.required],
    isbn: [null, [Validators.required, IsbnValidator()]],
    authors: this.formBuilder.array([]),
    publisher: this.formBuilder.array([])
  });
  public stage3: FormGroup = this.formBuilder.group({
    authors: this.formBuilder.array([]),
    publisher: this.formBuilder.array([]),
  });
  public stage4: FormGroup = this.formBuilder.group({
    authorPublishings: this.formBuilder.array([]),
    publisherPublishings: this.formBuilder.array([]),
    descriptionPublication: [null, Validators.required],
    justificationPublishing: [null, Validators.required],
    descriptionCreativeActivityApplicant: [null, Validators.required],
  }, {
    validator: [
      MinThreePublisherPublicationValidator.validatePublisher('publisherPublishings', 3),
    ]
  });
  public stage5: FormGroup = this.formBuilder.group({
    author: [null],
    title: [null],
    publisher: [null],
    publishDate: [null, Validators.required],
    amountFunding: [null, Validators.required],
    publishingSheets: [null, Validators.required],
    numberPages: [null, Validators.required],
    numberIllustrations: [null, Validators.required],
    isbnPublication: [null, IsbnValidator()],
    bookForm: [null, Validators.required],
    preparationForPrinting: [null, Validators.required],
    printingCost: [null, [Validators.min(1)]],
    publisherCost: [null, Validators.required],
    copyrightFee: [null, Validators.required],
    retailPrice: [null, Validators.required],
    printRun: [null],
    publicationFinished: [null, Validators.required],
  });
  public stage7: FormGroup = this.formBuilder.group({
    consentOne: [null, Validators.required],
    consentTwo: [null, Validators.required],
    consentThree: [null, Validators.required],
  });
  public draft;
  public fileToUpload: File = null;
  public files = [];
  public typesPublication = [];
  // tslint:disable-next-line:variable-name
  private _maximumAmountForType = 0;
  // tslint:disable-next-line:variable-name
  private _maximumAmount = 0;
  public invalidAmountFunding = true;
  private poolObserver: any = new Subject();
  public pools = this.poolObserver.asObservable();

  private readonly uniqueAuthorEmailValidator: UniqueAuthorEmailValidator;
  private readonly uniquePublisherEmailValidator: UniquePublisherEmailValidator;
  private readonly availableProposalPoolValidator: AvailableProposalPoolValidator;

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    private sweetalertService: SweetalertService,
    public formBuilder: FormBuilder,
  ) {
    this.uniqueAuthorEmailValidator = new UniqueAuthorEmailValidator(apiService);
    this.uniquePublisherEmailValidator = new UniquePublisherEmailValidator(apiService);
    this.availableProposalPoolValidator = new AvailableProposalPoolValidator(apiService);

    this.stage1 = this.formBuilder.group({
      pool: [null, Validators.required, this.availableProposalPoolValidator.validate],
    });
  }

  get authors(): FormArray {
    return this.stage2.get('authors') as FormArray;
  }

  get publisher(): FormArray {
    return this.stage2.get('publisher') as FormArray;
  }

  get authorsMembership(): FormArray {
    return this.stage3.get('authors') as FormArray;
  }

  get publisherMembership(): FormArray {
    return this.stage3.get('publisher') as FormArray;
  }

  get authorPublishings(): FormArray {
    return this.stage4.get('authorPublishings') as FormArray;
  }

  get publisherPublishings(): FormArray {
    return this.stage4.get('publisherPublishings') as FormArray;
  }

  set maximumAmountForType(maximumAmount: number|null) {
    this._maximumAmountForType = maximumAmount;
    this.updateMaximumAmountFunding();
  }

  get maximumAmount() {
    return this._maximumAmount;
  }

  ngOnInit(): void {
    this.loadPools();
    this.defaultEmail = localStorage.getItem('email') ?? '';
    this.pools.subscribe(pools => {
      this.loadDrafts(pools);
    });

    this.addAuthor();
    this.addPublisher();
    this.addAuthorPublishings();
    this.addPublisherPublishings();

    ProposalUtils.updateFieldsOnBookFormChange(this.stage5 as FormGroup);
  }

  public stepperGoNext(formGroup: FormGroup) {
    if (!formGroup.valid) {
      formGroup.markAllAsTouched();
      return;
    }

    this.stepper.next();
  }

  public updateMaximumAmountFunding() {
    if (this.stage3.controls.publisher.value.length === 0) {
      return;
    }
    const publisherMemberAssociation = this.stage3.controls.publisher.value[0].memberAssociation;
    const publisherContractConcluded = this.stage3.controls.publisher.value[0].contractConcluded;

    const authorMemberAssociation = this.stage3.controls.authors.value[0].memberAssociation;
    const authorContractConcluded = this.stage3.controls.authors.value[0].contractConcluded;

    if (true === publisherMemberAssociation && true === publisherContractConcluded) {
      this._maximumAmount = this._maximumAmountForType;
    } else if (true === publisherMemberAssociation || true === publisherContractConcluded) {
      this._maximumAmount = this._maximumAmountForType * 0.5;
    } else if (true === authorMemberAssociation || true === authorContractConcluded) {
      this._maximumAmount = this._maximumAmountForType * 0.25;
    } else {
      this._maximumAmount = 0;
    }

    if (null == this.stage5.controls.amountFunding.value) {
      this.stage5.controls.amountFunding.setValue(this._maximumAmount);
    }
    this.invalidAmountFunding = this.stage5.controls.amountFunding.value > this.maximumAmount;
  }

  public buildAuthor(): FormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      lastname: [null, Validators.required],
      nickname: [null],
      email: [null, [Validators.required, Validators.email], this.uniqueAuthorEmailValidator.validate],
      phone: [null, [Validators.required, PhoneValidator()]],
      pesel: [null, [Validators.required, PeselValidator()]],
      street: [null, Validators.required],
      postcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
    });
  }

  public buildPublisher(): FormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      email: [this.defaultEmail, [Validators.required, Validators.email], this.uniquePublisherEmailValidator.validate],
      phone: [null, [Validators.required, PhoneValidator()]],
      street: [null, Validators.required],
      postcode: [null, Validators.required],
      city: [null, Validators.required],
      country: [null, Validators.required],
      nip: [null, [Validators.required, NipValidator()]],
      krsceidg: [null, Validators.required],
      isVatPayer: [null],
    });
  }

  public buildAuthorMembership(): FormGroup {
    return this.formBuilder.group({
      memberAssociation: [false, Validators.required],
      contractConcluded: [false, Validators.required],
    });
  }

  public buildPublisherMembership(): FormGroup {
    return this.formBuilder.group({
      memberAssociation: [false, Validators.required],
      contractConcluded: [false, Validators.required],
    });
  }

  public buildAuthorPublishings(): FormGroup {
    return this.formBuilder.group({
      author: [null, Validators.required],
      title: [null, Validators.required],
      isbn: [null, [Validators.required, IsbnValidator()]],
      publisher: [null, Validators.required],
      publishDate: [null, [Validators.required, Validators.min(1950), Validators.max(new Date().getFullYear())]],
      roleInPublication: [null, Validators.required],
      reportedPlr: [false, Validators.required],
      reportedSubContract: [false, Validators.required],
      reportedMembershipDeclaration: [false, Validators.required],
      debutantWithoutLiteraryAchievements: [false, Validators.required],
    });
  }

  public buildPublisherPublishings(): FormGroup {
    return this.formBuilder.group({
      author: [null, Validators.required],
      title: [null, Validators.required],
      isbn: [null, [Validators.required, IsbnValidator()]],
      publisher: [null, Validators.required],
      publishDate: [null, [Validators.required, Validators.min(1950), Validators.max(new Date().getFullYear())]],
      roleInPublication: [null, Validators.required],
      reportedPlr: [false, Validators.required],
      reportedSubContract: [false, Validators.required],
      reportedMembershipDeclaration: [false, Validators.required],
    });
  }

  addAuthor(): void {
    this.addAuthorMembership();
    this.authors.push(this.buildAuthor());
  }

  addPublisher(): void {
    this.addPublisherMembership();
    this.publisher.push(this.buildPublisher());
  }

  addAuthorMembership(): void {
    this.authorsMembership.push(this.buildAuthorMembership());
  }

  addPublisherMembership(): void {
    this.publisherMembership.push(this.buildPublisherMembership());
  }

  addAuthorPublishings(): void {
    this.authorPublishings.push(this.buildAuthorPublishings());
  }

  addPublisherPublishings(): void {
    this.publisherPublishings.push(this.buildPublisherPublishings());
  }

  public deleteAuthor(i) {
    this.authors.removeAt(i);
    this.authorsMembership.removeAt(i);
  }

  public deleteAuthorPublishing(i) {
    this.authorPublishings.removeAt(i);
  }

  public deletePublisherPublishing(i) {
    this.publisherPublishings.removeAt(i);
  }

  public validAmountFunding(event) {
    if (event.target.value > this.maximumAmount) {
      this.invalidAmountFunding = true;
      return;
    }
    this.invalidAmountFunding = false;
  }

  public onCheckboxChange(event) {
    this.updateMaximumAmountFunding();
  }

  public loadDrafts(pools) {
    this.isLoading = true;
    this.apiService.getDraft().subscribe(data => {
      /* tslint:disable */
      if (data['drafts'].length > 0) {
        this.sweetalertService.loadDraftConfirm().then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Swal.fire('Saved!', '', 'success');
            const
              uc = c => c >= 'a' && c <= 'z'
                ? String.fromCharCode(c.charCodeAt() - 32)
                : c,
              toUpperCase = (key, value) => typeof value === 'string'
                ? Array.from(value, uc).join('')
                : value;

            this.draft = data['drafts'].slice(-1)[0];
            this.draft = JSON.parse(JSON.stringify(this.draft), toUpperCase);

            if (this.draft.fields.stage1) this.stage1.patchValue(this.draft.fields.stage1);
            if (this.draft.fields.stage2) this.stage2.patchValue(this.draft.fields.stage2);
            if (this.draft.fields.stage3) this.stage3.patchValue(this.draft.fields.stage3);
            if (this.draft.fields.stage4) this.stage4.patchValue(this.draft.fields.stage4);
            if (this.draft.fields.stage5) this.stage5.patchValue(this.draft.fields.stage5);
            if (this.draft.fields.files) this.files = this.draft.fields.files;

            this.updateAllFormGroupStatusForPublisher();
            this.updateAllFormGroupStatusForAuthor();

            pools.forEach(pool => {
              if (pool.id == this.stage1.controls.pool.value) {
                this.typesPublication = pool.publicationTypes;

                this.typesPublication.forEach(poolType => {
                  if (poolType.id == this.stage2.controls.poolPublicationType.value) {
                    this.maximumAmountForType = poolType.maximumAmountAwards;
                  }
                });
              }
            });
          } else {
            Swal.fire('Changes are not saved', '', 'info');
          }
        });
      }
      this.isLoading = false;
    });
  }

  private updateAllFormGroupStatusForAuthor() {
    const formArray = this.stage4.get('authorPublishings') as FormArray;
    for (let publicationNumber = 0; publicationNumber < formArray.length; publicationNumber++) {
      const form = formArray.at(publicationNumber) as FormGroup;
      ProposalUtils.setPublishingFieldsStatus(form);
    }
  }

  public changeFormGroupStatusForAuthor(publicationNumber) {
    const formArray = this.stage4.get('authorPublishings') as FormArray;
    const form = formArray.at(publicationNumber) as FormGroup;
    ProposalUtils.setPublishingFieldsStatus(form);
  }

  private updateAllFormGroupStatusForPublisher() {
    const formArray = this.stage4.get('publisherPublishings') as FormArray;
    for (let publicationNumber = 0; publicationNumber < formArray.length; publicationNumber++) {
      const form = formArray.at(publicationNumber) as FormGroup;
      ProposalUtils.setPublishingFieldsStatus(form);
    }
  }

  public changeFormGroupStatusForPublisher(publicationNumber) {
    const formArray = this.stage4.get('publisherPublishings') as FormArray;
    const form = formArray.at(publicationNumber) as FormGroup;
    ProposalUtils.setPublishingFieldsStatus(form);
  }

  public loadPools() {
    this.isLoading = true;
    this.apiService.getAcceptedPools().subscribe((response: any) => {
      this.isLoading = false;
      this.poolObserver.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public selectedPublicationType(type) {
    if (type !== null) {
      for (let i = 0; i < this.typesPublication.length; i++) {
        if (this.typesPublication[i].id == type) {
          this.maximumAmountForType = this.typesPublication[i].maximumAmountAwards;
        }
      }
    }
  }

  public setSelectedPool(pool) {
    this.selectedPool = pool;
    this.typesPublication = this.selectedPool.publicationTypes;
    this.maximumAmountForType = null;
  }

  public saveDraft($event) {

    const data = {
      stage1: this.stage1.getRawValue(),
      stage2: this.stage2.getRawValue(),
      stage3: this.stage3.getRawValue(),
      stage4: this.stage4.getRawValue(),
      stage5: this.stage5.getRawValue(),
      files: this.files,
    };
    this.apiService.saveDraft(data).subscribe(response => {
      //do nothing
    });
  }

  public uploadFile(files: FileList) {
    this.fileToUpload = files.item(0);

    this.apiService.postFile(this.fileToUpload).subscribe((response: any) => {
      console.log('postFile response', response.data);
      this.files.push(response.data);
      this.fileToUpload = null;
    }, error => {
      error.error.validationErrors
      this.errorService.handleError(error);
      this.fileToUpload = null;
    });
  }

  public sendProposal(e) {
    if (this.stage5.controls.amountFunding.value > this.maximumAmount) {
      this.sweetalertService.formError('Podałeś za dużą kwotę dofinansowania. Maksymalna kwota dofinansowania w tej puli dla wybranego typu publikacji to ' + this.maximumAmount + 'PLN');
      e.preventDefault();
      return;
    }

    let authors: AuthorModel[] = [];
    this.stage2.controls['authors'].value.forEach((item, i) => {
      const membership: MembershipModel = {
        author: null,
        publisher: 0,
        memberAssociation: this.stage3.controls.authors.value[i].memberAssociation,
        contractConcluded: this.stage3.controls.authors.value[i].contractConcluded
      };
      authors.push({
        proposal: null,
        name: item.name,
        lastname: item.lastname,
        nickname: item.nickname,
        email: item.email,
        pesel: item.pesel,
        postCode: item.postcode,
        city: item.city,
        street: item.street,
        country: item.country,
        phone: item.phone,
        membership: membership,
      });
    });

    let publishers: PublisherModel[] = [];
    this.stage2.controls['publisher'].value.forEach((item, i) => {
      const membership: MembershipModel = {
        author: 0,
        publisher: null,
        memberAssociation: this.stage3.controls.publisher.value[i].memberAssociation,
        contractConcluded: this.stage3.controls.publisher.value[i].contractConcluded
      };
      publishers.push({
        proposal: null,
        email: item.email,
        publisherName: item.name,
        postCode: item.postcode,
        city: item.city,
        street: item.street,
        country: item.country,
        phone: item.phone,
        publisherNip: item.nip,
        krsCeidg: item.krsceidg,
        isVatPayer: item.isVatPayer,
        membership: membership,
      });
    });

    let previousAuthorPublications: AuthorPublicationModel[] = [];
    this.stage4.controls['authorPublishings'].value.forEach((item, i) => {
      previousAuthorPublications.push({
        proposal: null,
        author: item.author,
        publisher: item.publisher,
        title: item.title,
        isbn: item.isbn,
        publishDate: item.publishDate,
        roleInPublication: item.roleInPublication,
        reportedPlr: item.reportedPlr,
        reportedSubContract: item.reportedSubContract,
        reportedMembershipDeclaration: item.reportedMembershipDeclaration,
        debutantWithoutLiteraryAchievements: item.debutantWithoutLiteraryAchievements
      });
    });

    let previousPublisherPublications: PublicationModel[] = [];
    const publisherPublishingsValue = this.stage4.controls['publisherPublishings'].value;
    if (
      publisherPublishingsValue.length >= 3 ||
      (publisherPublishingsValue.length >= 1 && publisherPublishingsValue[0].reportedPlr === true) ||
      (publisherPublishingsValue.length >= 2 && publisherPublishingsValue[1].reportedPlr === true)
    ) {
      this.stage4.controls['publisherPublishings'].value.forEach((item, i) => {
        previousPublisherPublications.push({
          proposal: null,
          author: item.author,
          publisher: item.publisher,
          title: item.title,
          isbn: item.isbn,
          publishDate: item.publishDate,
          roleInPublication: item.roleInPublication,
          reportedPlr: item.reportedPlr,
          reportedSubContract: item.reportedSubContract,
          reportedMembershipDeclaration: item.reportedMembershipDeclaration
        });
      });
    } else {
      this.sweetalertService.formError('Masz mniej niż 3 publikacje lub nie są one ');
    }

    const publicationCost: PublicationCostModel = {
      proposal: null,
      author: this.stage5.controls.author.value,
      publisher: this.stage5.controls.publisher.value,
      title: this.stage5.controls.title.value,
      publishDate: this.stage5.controls.publishDate.value,
      amountFunding: this.stage5.controls.amountFunding.value,
      publishingSheets: this.stage5.controls.publishingSheets.value,
      numberPages: this.stage5.controls.numberPages.value,
      numberIllustrations: this.stage5.controls.numberIllustrations.value,
      isbnPublication: this.stage5.controls.isbnPublication.value,
      bookForm: this.stage5.controls.bookForm.value.toString(),
      preparationForPrinting: this.stage5.controls.preparationForPrinting.value,
      printingCost: this.stage5.controls.printingCost.value,
      publisherCost: this.stage5.controls.publisherCost.value,
      copyrightFee: this.stage5.controls.copyrightFee.value,
      retailPrice: this.stage5.controls.retailPrice.value,
      printRun: this.stage5.controls.printRun.value,
      publicationFinished: this.stage5.controls.publicationFinished.value,
    };

    const proposal: ProposalModel = {
      pool: this.stage1.controls.pool.value,
      poolPublicationType: Number(this.stage2.controls.poolPublicationType.value),
      amountFunding: String(this.stage5.controls.amountFunding.value),
      publishTitle: this.stage2.controls.publishTitle.value,
      isbn: this.stage2.controls.isbn.value,
      descriptionPublication: this.stage4.controls.descriptionPublication.value,
      justificationPublishing: this.stage4.controls.justificationPublishing.value,
      status: PROPOSAL_STATUS.NEW,
      descriptionCreativeActivityApplicant: this.stage4.controls.descriptionCreativeActivityApplicant.value,
      files: this.files.map(file => file.id),
      authors: authors,
      publishers: publishers,
      previousAuthorPublications: previousAuthorPublications,
      previousPublisherPublications: previousPublisherPublications,
      publicationCost: publicationCost
    };

    this.isSending = true;
    this.apiService.postProposal(proposal).subscribe((response: any) => {
      Swal.fire('Pomyślnie złożono nowy wniosek!', '', 'success');
      this.isSending = false;
      this.isCreated = true;
      this.apiService.getPreviewProposal(response.data.id).subscribe(previewData => {
        const url = window.URL.createObjectURL(previewData);
        window.open(url, 'Download');
      }, error => {
        console.log('Error downloading the file.');
        this.errorService.handleError(error);
      });
    }, error => {
      this.isSending = false;
      this.sweetalertService.formError(error.error.validationErrors);
      this.errorService.handleError(error.error.validationErrors);
    });
  }

  setUpCosts($event: MatSelectChange) {
      if ($event.value.includes('2')) {
        document.getElementById('prepareToPrintLabel').innerText = ' Koszty przygotowania pdf-a';
      } else {
        document.getElementById('prepareToPrintLabel').innerText = ' Przygotowanie do druku, druk';
      }
      ProposalUtils.updateFieldsOnBookFormChange(this.stage5 as FormGroup);
  }
}
