<div class="logs">
  <div class="logs__navbar" *ngIf="!options.simplified">
    <mat-card [class]="options.type === 'last' ? 'card--active': 'card'"
              (click)="changeType('last')"> Ostatnie
    </mat-card>
    <mat-card [class]="options.type === 'all' ? 'card--active': 'card'"
              (click)="changeType('all')"> Wszystkie
    </mat-card>
  </div>
  <div [hidden]="isLoading">
    <mat-form-field class="filter">
      <mat-label> Filtrowanie</mat-label>
      <input matInput (keyup)="applyFilter($event)" aria-label="filter" #input>
    </mat-form-field>
    <div class="mb mat-elevation-z8 logs-wrapper">
      <table mat-table [dataSource]="logs" class="mat-elevation-z8 logs__table" matSort>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data</th>
          <td mat-cell *matCellDef="let element"> {{ dateService.prepareFullDate(element.createdAt) }}</td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Użytkownik</th>
          <td mat-cell *matCellDef="let element"> {{ prepareUsername(element.user) }} </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Opis</th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <ng-container *ngIf="logs">
        <mat-card *ngIf="logs.filteredData.length === 0" [hidden]="isLoading">
          Brak wyników do wyświetlenia
        </mat-card>
      </ng-container>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
  <div class="loading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
</div>
