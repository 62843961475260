<div class="proposal" [hidden]="isLoading">
  <div class="row">
    <button mat-raised-button [routerLink]="'/publisher/proposals'" color="primary">
      Cofnij
    </button>
    <h1>Podgląd wniosku</h1>
  </div>
  <div class="form-group__wrapper">
    <div class="form-group__content">
      <app-proposal-summary [pools]="pools"
                            [stage1]="stage1.getRawValue()"
                            [stage2]="stage2.getRawValue()"
                            [stage3]="stage3.getRawValue()"
                            [stage4]="stage4.getRawValue()"
                            [stage5]="stage5.getRawValue()"
                            [files]="files"
      ></app-proposal-summary>
    </div>
  </div>
</div>
