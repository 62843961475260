import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {DateService} from "../../../services/date.service";
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  @Input() options = {
    simplified: false,
    type: 'last',
  }
  public displayedColumns: string[] = ['date', 'username', 'description'];
  public logs;
  public isLoading;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    public dateService: DateService,
  ) {
  }

  ngOnInit(): void {
    if (this.options.simplified) {
      this.displayedColumns = ['date', 'description'];
    }

    this.refreshLogs(this.options.type);
  }

  public refreshLogs(type) {
    this.isLoading = true;
    this.apiService.getLogs(type).subscribe((response: any) => {
      this.isLoading = false;
      this.logs = new MatTableDataSource(response.data);
      this.logs.paginator = this.paginator;
      this.logs.sort = this.sort;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public changeType(type) {
    this.options.type = type;
    this.refreshLogs(type);
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.logs.filter = filterValue.trim().toLowerCase();

    if (this.logs.paginator) {
      this.logs.paginator.firstPage();
    }
  }

  public prepareUsername(user) {
    if (user) {
      return user.username;
    }

    return 'Niezalogowany';
  }
}
