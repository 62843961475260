<div class="proposal-settlements" *ngIf="proposal.status == PROPOSAL_STATUS.ACCEPTED">
  <h2> Pliki potrzebne do rozliczenia</h2>
  <div class="row">
    <mat-form-field class="settlement-comment">
      <mat-label> Komentarz</mat-label>
      <textarea [(ngModel)]="proposal.proposalSettlement.comment" matInput placeholder="Maksymalnie 5000 znaków"> </textarea>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-checkbox [(ngModel)]="proposal.proposalSettlement.isCorrect" class="settlement-checkbox">
      Rozliczone poprawnie
    </mat-checkbox>
    <mat-form-field class="settlement-date">
      <mat-label> Data rozliczenia dla tego wniosku</mat-label>
      <input matInput [matDatepicker]="picker6" [(ngModel)]="billingDate">
      <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
      <mat-datepicker #picker6></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="files-card-wrapper">
    <mat-card *ngIf="!fileToUpload" class="file-card">
      <button class="file-card__add" color="primary" mat-mini-fab>
        <mat-icon class="file-card__icon">
          <div class="form-group">
            <label for="file"> add</label>
            <input (change)="uploadFile($event.target.files)" class="hide-input" id="file" type="file">
          </div>
        </mat-icon>
      </button>
    </mat-card>
    <mat-card *ngIf="fileToUpload" class="file-card">
      <mat-spinner [diameter]="40" class="file-card__mat-spinner"></mat-spinner>
    </mat-card>
    <mat-card *ngFor="let file of uploadedFiles" class="file-card">
      <div class="file-card__content">
        <div class="title">
          {{ file.name }}
        </div>
        <div class="sub-title">
          Utworzono {{ file.created_at }}
        </div>
      </div>
      <button (click)="deleteFile(file.id)" class="admin-sidenav-component__button" color="warn" mat-mini-fab>
        <mat-icon> delete</mat-icon>
      </button>
    </mat-card>
  </div>
  <button (click)="onFormSubmit()" [disabled]="fileToUpload !== null" color="primary" mat-raised-button> Wyślij</button>
</div>
<div class="proposal-settlements" *ngIf="proposal.status !== PROPOSAL_STATUS.ACCEPTED">
  <h2>Można rozliczyć jedynie zaakceptowane wnioski</h2>
</div>
