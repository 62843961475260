<div class="rules">

  <img src="assets/images/logo.jpg" alt="logo" class="rules__logo">
  <mat-card class="rules-card">
    <mat-card-title class="rules-card__title"> Regulamin uśude</mat-card-title>
    <mat-card-content class="rules-card__content">
      tutaj bedzie tresc regulaminu
    </mat-card-content>
  </mat-card>
</div>
<app-loader [isOpen]="isWaiting"></app-loader>
