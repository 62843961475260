import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ProposalManageService} from './proposal-manage.service';
import {ProposalListService} from '../proposal-list/proposal-list.service';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-proposal-manage',
  templateUrl: './proposal-manage.component.html',
  styleUrls: ['./proposal-manage.component.scss']
})
export class ProposalManageComponent implements OnInit {
  public activeSection = 1;
  public proposal;
  public isLoaded = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private dialogRef: MatDialog,
    private proposalManageService: ProposalManageService,
    private proposalAgreementService: ProposalListService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.proposalManageService.updateProposal(this.data.proposalId);
    this.proposalManageService.proposal.subscribe(data => {
      this.proposal = data;
      this.isLoaded = true;
      console.log(data);
    });
  }

  public setActiveSection(section: number) {
    this.activeSection = section;
  }
}
