import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AwardManageComponent} from "../award-manage/award-manage.component";
import {AwardUploadComponent} from "../award-upload/award-upload.component";
import {AwardListService} from "./award-list.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-award-list',
  templateUrl: './award-list.component.html',
  styleUrls: ['./award-list.component.scss']
})
export class AwardListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'name', 'user', 'status', 'createdAt', 'options'];
  public awards;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    public awardAgreementService: AwardListService,
  ) {
  }

  ngOnInit(): void {
    this.awardAgreementService.refreshAwardList();
    this.awardAgreementService.awards.subscribe(data => {
      this.awards = new MatTableDataSource(data);
      this.awards.paginator = this.paginator;
      this.awards.sort = this.sort;
    });
  }

  public getStatusText(status) {
    switch (status) {
      case 0:
        return 'Nowe';
        break;
      case 1:
        return 'Zaakceptowane';
        break;
      case 2:
        return 'Odrzucone';
        break;
      case 3:
        return 'Zakończone';
        break;
      default:
        return '';
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.awards.filter = filterValue.trim().toLowerCase();

    if (this.awards.paginator) {
      this.awards.paginator.firstPage();
    }
  }

  public openDialog(id: number) {
    const dialogRef = this.dialog.open(AwardManageComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '550px',
      panelClass: 'block-panel',
      data: {
        awardId: id
      },
    });
  }

  public createNewAwardAgreement() {
    const dialogRef = this.dialog.open(AwardUploadComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '550px',
      panelClass: 'block-panel',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
