<div class="proposal-list">
  <mat-grid-list cols="5" rowHeight="40px" class="mb">
    <mat-grid-tile>
      <mat-form-field>
        <mat-label>
          ID użytkownika
        </mat-label>
        <input
          [(ngModel)]="params.user" type="number"
          class="form-control" id="user" min="1"
          placeholder="ID użytkownika" (keydown.enter)="search()" matInput
        >
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field>
        <mat-label>
          E-mail użytkownika
        </mat-label>
        <input
          [(ngModel)]="params.userEmail" type="text"
          class="form-control" id="userEmail"
          placeholder="E-mail użytkownika" (keydown.enter)="search()" matInput
        >
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field>
        <mat-label>
          Nazwa wydawcy
        </mat-label>
        <input
          [(ngModel)]="params.publisherName" type="text"
          class="form-control" id="publisherName"
          placeholder="Nazwa wydawcy" (keydown.enter)="search()" matInput
        >
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field>
        <mat-label>
          Nip wydawcy
        </mat-label>
        <input
          [(ngModel)]="params.publisherNip" type="text"
          class="form-control" id="publisherNip"
          placeholder="Nip wydawcy" (keydown.enter)="search()" matInput
        >
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <button (click)="search()" class="mr" color="primary" mat-stroked-button>
        Szukaj
      </button>
      <button (click)="clearSearch()" color="warn" mat-stroked-button>
        Wyczyść
      </button>
    </mat-grid-tile>
  </mat-grid-list>
  <div [hidden]="proposalListService.isLoading">
    <div class="mb mat-elevation-z8">
      <table [dataSource]="proposals" class="mat-elevation-z8 proposal-list__table" mat-table matSort matSortActive="id" matSortDirection="desc">
        <ng-container matColumnDef="id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header class="id-column">
            ID
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.id }}
          </td>
        </ng-container>
        <ng-container matColumnDef="publishCategory">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Kategoria publikacji
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.publishCategory }}
          </td>
        </ng-container>
        <ng-container matColumnDef="poolName">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Nazwa puli
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.pool.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="amountFunding">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Kwota dofinansowania
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.amountFunding }}
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Wysłał
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.user.username }}
          </td>
        </ng-container>
        <ng-container matColumnDef="publishTitle">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Tytuł publikacji
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.publishTitle }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isbn">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            ISBN
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.isbn }}
          </td>
        </ng-container>
        <ng-container matColumnDef="descriptionPublication">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Opis publikacji
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.descriptionPublication }}
          </td>
        </ng-container>
        <ng-container matColumnDef="justificationPublishing">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Uzasadnienie konieczności wydania tej publikacji
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.justificationPublishing }}
          </td>
        </ng-container>
        <ng-container matColumnDef="descriptionCreativeActivityApplicant">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Opis działalności twórczej wnioskodawcy
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.descriptionCreativeActivityApplicant }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Status
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.status | proposalStatusToText }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Wysłano
          </th>
          <td *matCellDef="let element" mat-cell>
            {{ element.createdAt|date:'yyyy-MM-dd H:mm' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th *matHeaderCellDef mat-header-cell class="options-column">
            Opcje
          </th>
          <td *matCellDef="let element" mat-cell>
            <button (click)="openDialog(element.id)" class="mr" color="primary" mat-mini-fab>
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
      </table>
      <ng-container *ngIf="proposals">
        <mat-card *ngIf="proposals.filteredData.length === 0" [hidden]="proposalListService.isLoading">
          Brak wyników do wyświetlenia
        </mat-card>
      </ng-container>

      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </div>
  <div *ngIf="proposalListService.isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
