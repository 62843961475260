<div class="pool-list">
  <div class="pool-list__button-wrapper">
    <button (click)="createNewPoolAgreement()" color="primary" mat-raised-button>
      Dodaj nową pulę do rozpatrzenia
    </button>
  </div>
  <div [hidden]="poolAgreementService.isLoading">
    <mat-form-field class="filter">
      <mat-label> Filtrowanie</mat-label>
      <input #input (keyup)="applyFilter($event)" aria-label="filter" matInput>
    </mat-form-field>
    <div class="mb mat-elevation-z8">
      <table [dataSource]="pools" class="mat-elevation-z8 pool-list__table" mat-table matSort matSortActive="id" matSortDirection="desc">

        <ng-container matColumnDef="id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header class="id-column"> ID</th>
          <td *matCellDef="let element" mat-cell> {{ element.id }} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> Nazwa</th>
          <td *matCellDef="let element" mat-cell> {{ element.name }} </td>
        </ng-container>
        <ng-container matColumnDef="maximumAmountAwards">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> Maksymalne dofinansowanie dla puli</th>
          <td *matCellDef="let element" mat-cell> {{element.maximumAmountAwards}}PLN</td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> Data otwarcia puli</th>
          <td *matCellDef="let element" mat-cell> {{ element.startDate|date:'yyyy-MM-dd H:mm'}} </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> Data zamknięcia puli</th>
          <td *matCellDef="let element" mat-cell> {{element.endDate|date:'yyyy-MM-dd H:mm'}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> Status</th>
          <td *matCellDef="let element" mat-cell> {{element.status | poolStatusToText}}</td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> Utworzył</th>
          <td *matCellDef="let element" mat-cell> {{element.user.username}}</td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th *matHeaderCellDef mat-header-cell mat-sort-header> Data utworzenia</th>
          <td *matCellDef="let element" mat-cell> {{element.createdAt|date:'yyyy-MM-dd H:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th *matHeaderCellDef mat-header-cell class="options-column"> Opcje</th>
          <td *matCellDef="let element" mat-cell>
            <button (click)="openDialog(element.id)" class="mr" color="primary" mat-mini-fab>
              <mat-icon> edit</mat-icon>
            </button>
            <button color="secondary" mat-mini-fab (click)="openProposalsFilteredByPool(element.id)">
              <mat-icon> article</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
      </table>
      <ng-container *ngIf="pools">
        <mat-card *ngIf="pools.filteredData.length === 0" [hidden]="poolAgreementService.isLoading">
          Brak wyników do wyświetlenia
        </mat-card>
      </ng-container>

      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </div>
  <div *ngIf="poolAgreementService.isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
