import {Component, Inject, Input, OnInit} from '@angular/core';
import {ScholarshipModel} from "../../../../models/requests/scholarship/scholarship.model";
import {ApiService} from "../../../../services/api.service";
import {SweetalertService} from "../../../../services/sweetalert.service";
// import {ScholarshipManageService} from "../scholarship-manage/scholarship-manage.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ScholarshipListService} from "../scholarship-list/scholarship-list.service";
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-scholarship-upload',
  templateUrl: './scholarship-upload.component.html',
  styleUrls: ['./scholarship-upload.component.scss']
})
export class ScholarshipUploadComponent implements OnInit {
  @Input() public scholarship: ScholarshipModel = {
    name: '',
    files: []
  };
  public fileToUpload: File = null;
  private isNew;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    // private scholarshipManageService: ScholarshipManageService,
    private dialogRef: MatDialog,
    private scholarshipAgreementService: ScholarshipListService,
  ) {
  }

  ngOnInit(): void {
    (this.data) ? this.isNew = false : this.isNew = true;
  }

  public deleteFile(id: number) {
    let filePosition;

    for (let x = 0; x < this.scholarship.files.length; x++) {
      if (this.scholarship.files[x].id === id) {
        filePosition = x;
        break;
      }
    }

    this.scholarship.files.splice(filePosition, 1);
  }

  public uploadFile(files: FileList) {
    this.fileToUpload = files.item(0);

    this.apiService.postFile(this.fileToUpload).subscribe((response: any) => {
      this.scholarship.files.push(response.data);
      this.fileToUpload = null;
    }, error => {
      this.errorService.handleError(error);
      this.fileToUpload = null;
    })
  }

  public onFormSubmit() {
    let requestScholarship: ScholarshipModel = {
      name: '',
      files: []
    };

    requestScholarship.name = this.scholarship.name
    for (let x = 0; x < this.scholarship.files.length; x++) {
      requestScholarship.files[x] = this.scholarship.files[x].id;
    }

    if (this.isNew) {
      this.apiService.postScholarship(requestScholarship).subscribe((response: any) => {
        this.sweetalertService.success();
        this.scholarshipAgreementService.refreshScholarshipList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
      })
    } else {
      this.apiService.updateScholarship(this.scholarship.id, requestScholarship).subscribe((response: any) => {
        this.sweetalertService.success();
        this.scholarshipAgreementService.refreshScholarshipList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
      })
    }
  }
}
