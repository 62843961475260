import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ChangeEmailModel} from '../../../../models/requests/account/change-email.model';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

  public user = null;
  public changingEmail = false;

  public isWaiting = false;
  public emailChangeForm: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
  });

  constructor(
    public formBuilder: FormBuilder,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
    this.apiService.getUser().subscribe((response: any) => {
      this.user = response.user;
      localStorage.setItem('email', response.user.email);
    });
  }

  public startChangingEmail() {
    this.changingEmail = true;
  }

  public changeEmail(emailChangeForm) {
    const changeEmailModel: ChangeEmailModel = emailChangeForm.value;

    if (!emailChangeForm.valid) {
      this.sweetalertService.registerFormError();
      return;
    }
    this.isWaiting = true;

    this.apiService.changeEmail(changeEmailModel).subscribe((response: any) => {
      this.isWaiting = false;
      this.changingEmail = false;
      if (response.status === 200) {
        this.sweetalertService.changeEmailRequestSuccess();
      }
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });
  }
}
