<div class="award-settings">
  <h2> Ustawienia nagród</h2>
  <div *ngIf="!isLoading" class="row">
    <mat-card class="award-settings__card">
      <h4> Osoby potrzebne do zaakceptowania nagrody</h4>
      <mat-slider [(ngModel)]="awardSettings.neededToAccept" class="award-settings__slider" max="10" min="1" step="1"
                  thumbLabel="true">
      </mat-slider>
    </mat-card>
    <mat-card class="award-settings__card">
      <h4> Osoby potrzebne do odrzucenia nagrody</h4>
      <mat-slider [(ngModel)]="awardSettings.neededToDecline" class="award-settings__slider" max="10" min="1" step="1"
                  thumbLabel="true">
      </mat-slider>
    </mat-card>
  </div>
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row">
    <button (click)="onFormSubmit()" color="primary" mat-raised-button> Zapisz</button>
  </div>
</div>
