import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SweetalertService} from "../../../services/sweetalert.service";
import {ApiService} from "../../../services/api.service";
import {Router} from "@angular/router";
import {AccountRecoveryModel} from "../../../models/requests/account/account-recovery.model";

@Component({
  selector: 'app-account-recovery',
  templateUrl: './account-recovery.component.html',
  styleUrls: ['./account-recovery.component.scss']
})
export class AccountRecoveryComponent implements OnInit {
  public isWaiting = false;
  public accountRecoveryForm: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public onFormSubmit(accountRecoveryForm) {
    let accountRecoveryModel: AccountRecoveryModel = accountRecoveryForm.value;

    this.isWaiting = true;

    if (!accountRecoveryForm.valid) {
      this.isWaiting = false;
      this.sweetalertService.accountRecoveryFormError();
      return;
    }

    this.apiService.accountRecovery(accountRecoveryModel).subscribe((response: any) => {
      this.isWaiting = false;
      this.router.navigate(['']);
    }, error => {
      this.isWaiting = false;
      this.sweetalertService.registerFormError();
    })
  }
}
