import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SweetalertService} from '../../../services/sweetalert.service';
import {ApiService} from '../../../services/api.service';
import {LoginModel} from '../../../models/requests/security/login.model';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isWaiting = false;
  public loginForm: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    if (this.authService.isTokenSet()) {
      this.isWaiting = true;
      this.apiService.getUserByToken(this.authService.getToken()).subscribe((response: any) => {
        this.isWaiting = false;
        if (response.status === 200) {
          if (response.data.hasPermissions.length) {
            this.router.navigate(['admin/dashboard']);
          } else {
            this.router.navigate(['publisher/dashboard']);
          }
        }
      }, error => {
        this.authService.logout();
        this.isWaiting = false;
      });
    }
  }

  public onFormSubmit(loginForm) {
    this.isWaiting = true;
    const loginModel: LoginModel = loginForm.value;

    if (!loginForm.valid) {
      this.isWaiting = false;
      this.sweetalertService.loginFormError();
      return;
    }

    this.apiService.login(loginModel).subscribe((response: any) => {
      this.isWaiting = false;
      this.authService.login(response.user);
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });
  }
}
