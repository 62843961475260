import {AbstractControl, ValidatorFn} from '@angular/forms';

export function IsbnValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value === '' || control.value == null) {
      return null;
    }
    const regex = /^(?:ISBN(?:-13)?:?\ )?(?=[0-9]{13}$|(?=(?:[0-9]+[-\ ]){4})[-\ 0-9]{17}$)97[89][-\ ]?[0-9]{1,5}[-\ ]?[0-9]+[-\ ]?[0-9]+[-\ ]?[0-9]$/;

    if (regex.test(control.value) === false) {
      return {validateIsbn: {value: control.value}};
    }

    return null;
  };
}
