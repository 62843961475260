import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DashboardComponent} from './dashboard/dashboard.component';
import {SidenavComponent} from '../../components/publisher/sidenav/sidenav.component';
import {PublisherComponent} from './publisher.component';
import {OptionsComponent} from './options/options.component';

import {AppRoutingModule} from '../../app-routing.module';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import {ProposalCreateComponent} from './proposal/proposal-create/proposal-create.component';
import {ProposalSummaryComponent} from './proposal/proposal-create/summary/proposal-summary.component';
import {ProposalPublicationComponent} from './proposal/proposal-create/summary/publication/proposal-publication.component';
import {ProposalListComponent} from './proposal/proposal-list/proposal-list.component';
import {SharedModule} from '../../shared.module';
import {MatSortModule} from '@angular/material/sort';
import {ProposalViewComponent} from './proposal/proposal-view/proposal-view.component';
import {ChangePasswordComponent} from './options/change-password/change-password.component';
import {DeactivateAccountComponent} from './options/deactivate-account/deactivate-account.component';
import {AccountInfoComponent} from './options/account-info/account-info.component';
import {ChangeEmailByTokenComponent} from './options/email-change/change-email-by-token.component';

@NgModule({
  declarations: [
    DashboardComponent,
    SidenavComponent,
    PublisherComponent,
    ProposalCreateComponent,
    ProposalPublicationComponent,
    ProposalViewComponent,
    ProposalSummaryComponent,
    ProposalListComponent,
    ChangeEmailByTokenComponent,
    OptionsComponent,
    ChangePasswordComponent,
    DeactivateAccountComponent,
    AccountInfoComponent,
  ],
    imports: [
        CommonModule,
        AppRoutingModule,
        MatCardModule,
        MatGridListModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatListModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatIconModule,
        SharedModule,
        MatSortModule,
    ]
})
export class PublisherModule {
}
