<div class="wrapper">
  <div class="pool-manage" *ngIf="pool">
    <div class="pool-manage__menu">
      <mat-card [class]="activeSection === 1 ? 'card--active': 'card'"
                (click)="setActiveSection(1)"> Podgląd
      </mat-card>
      <mat-card [class]="activeSection === 2 ? 'card--active': 'card'"
                (click)="setActiveSection(2)"> Edycja
      </mat-card>
      <mat-card [class]="activeSection === 3 ? 'card--active': 'card'"
                (click)="setActiveSection(3)"> Akceptacja
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <section *ngIf="activeSection === 1">
      <app-pool-preview [pool]="pool"></app-pool-preview>
    </section>
    <section *ngIf="activeSection === 2">
      <app-pool-upload [pool]="pool"></app-pool-upload>
    </section>
    <section *ngIf="activeSection === 3">
      <app-pool-accept [pool]="pool"></app-pool-accept>
    </section>
  </div>
</div>
<div class="pool-manage__loader" *ngIf="!pool">
  <mat-spinner></mat-spinner>
</div>
