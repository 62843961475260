import {Component, Inject, Input, OnInit} from '@angular/core';
import {AccountUploadModel} from '../../../../models/requests/account/account-upload.model';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {AccountHomeService} from '../account-home.service';
import {USER_PERMISSIONS} from '../../../../enums/user-permissions.enum';
import {ErrorService} from '../../../../services/error.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StrengthPasswordValidator} from '../../../../validators/strengthPasswordValidator';
import {RepeatedPasswordValidator} from '../../../../validators/repetedPasswordValidator';

@Component({
  selector: 'app-account-upload',
  templateUrl: './account-upload.component.html',
  styleUrls: ['./account-upload.component.scss'],
  providers: [RepeatedPasswordValidator, StrengthPasswordValidator]
})
export class AccountUploadComponent implements OnInit {
  @Input() public account: AccountUploadModel;
  public user;
  public isWaiting = false;
  public password: string;
  public repeatPassword: string;
  public USER_PERMISSIONS: typeof USER_PERMISSIONS = USER_PERMISSIONS;

  public formProfile: FormGroup = this.formBuilder.group({
    username: [{value: null, disabled: true}],
    email: [{value: null, disabled: true}],
    password: [null, [Validators.required]],
    repeatPassword: [null, [Validators.required]],
  },{
    validator: [
      StrengthPasswordValidator.isStrength('password'),
      RepeatedPasswordValidator.mustMatch('password', 'repeatPassword'),
    ]
  });


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    private accountHomeService: AccountHomeService,
    public formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.formProfile.reset();
    this.formProfile.patchValue(this.account);
  }

  public togglePermissions(permission) {
    if (this.account.hasPermissions.includes(permission)) {
      this.account.hasPermissions.splice(this.account.hasPermissions.indexOf(permission), 1);
    } else {
      this.account.hasPermissions.push(permission);
    }
  }

  public onFormSubmit(form: FormGroup) {
    if (!form.valid) {
      this.sweetalertService.registerFormError();
      return;
    }

    const requestAccount: AccountUploadModel = {};
    requestAccount.password = form.get('password').value;
    requestAccount.hasPermissions = this.account.hasPermissions;
    this.apiService.updateUser(this.account.id, requestAccount).subscribe((response: any) => {
      this.sweetalertService.success();
      this.accountHomeService.refreshUsersList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
      const passwordErr = error.error.validationErrors.password;
      if (typeof passwordErr !== 'undefined') {
        this.sweetalertService.formError(passwordErr[0]);
      }
    });
  }
}
