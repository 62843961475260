import {FormGroup, Validators} from '@angular/forms';

export class ProposalUtils {

  public static setPublishingFieldsStatus(form: FormGroup) {
    if (form.get('reportedPlr').value === true || form.get('reportedSubContract').value === true) {
      form.get('author').disable();
      form.get('title').disable();
      form.get('isbn').disable();
      form.get('publisher').disable();
      form.get('publishDate').disable();
      form.get('roleInPublication').disable();
    } else {
      form.get('author').enable();
      form.get('title').enable();
      form.get('isbn').enable();
      form.get('publisher').enable();
      form.get('publishDate').enable();
      form.get('roleInPublication').enable();
    }
  }

  public static updateFieldsOnBookFormChange(form: FormGroup) {
    if (form.get('bookForm').value === null || !form.get('bookForm').value.includes('1')) {
      form.get('printRun').disable();
      form.get('printRun').clearValidators();
      form.get('printingCost').disable();
      form.get('printingCost').clearValidators();
    } else {
      form.get('printRun').enable();
      form.get('printRun').setValidators(Validators.required);
      form.get('printingCost').enable();
      form.get('printingCost').setValidators([Validators.required, Validators.min(1)]);
    }
  }
}
