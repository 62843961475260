import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ApiService} from '../../../../services/api.service';
import {ErrorService} from '../../../../services/error.service';
import {AccountDeactivationModel} from '../../../../models/requests/account/account-deactivation.model';

@Component({
  selector: 'app-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.scss']
})
export class DeactivateAccountComponent {

  public startProcess = false;
  public buttonText = 'Dezaktywuj konto na stałe';

  public isWaiting = false;
  public accountDeactivationForm: FormGroup = this.formBuilder.group({
    password: [null, Validators.required],
  });

  constructor(
    public formBuilder: FormBuilder,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private errorService: ErrorService,
    private router: Router,
  ) { }

  public startProcessDeactivation() {
    this.startProcess = true;
    this.buttonText = 'Potwierdzam dezaktywację konta';
  }

  public deactivateAccount(accountDeactivationForm) {
    const accountDeactivationModel: AccountDeactivationModel = accountDeactivationForm.value;

    if (!accountDeactivationForm.valid) {
      this.sweetalertService.registerFormError();
      return;
    }

    this.isWaiting = true;

    this.apiService.accountDeactivation(accountDeactivationModel).subscribe((response: any) => {
      this.isWaiting = false;
      this.startProcess = false;
      this.buttonText = 'Dezaktywuj konto na stałe';
      if (response.status === 200) {
        localStorage.removeItem('token');
        this.router.navigate(['/']);
        this.sweetalertService.accountDeactivateSuccess();
      }
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });
  }
}
