<div class="file-downloader">
  <div class="file-downloader__title">
    Lista pobieranych plików.
  </div>
  <mat-card class="downloading-card" *ngFor="let file of files">
    <mat-card-subtitle> {{ file.name }}</mat-card-subtitle>
    <mat-card-content>
      <mat-progress-bar mode="query"></mat-progress-bar>
    </mat-card-content>
  </mat-card>
  <mat-card class="downloading-card" *ngIf="files.length === 0">
    <mat-card-title> Brak pobieranych plików</mat-card-title>
  </mat-card>
</div>
