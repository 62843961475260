import {AbstractControl, ValidatorFn} from '@angular/forms';

export function PhoneValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value === '' || control.value == null) {
      return null;
    }

    if (control.errors && !control.errors.validatePhone) {
      return null;
    }
    const regNumberOnly = new RegExp('^[- +()0-9]*$');

    if (!regNumberOnly.test(control.value) || control.value.length < 9) {
      return {validatePhone: {value: control.value}};
    }

    return null;
  };
}
