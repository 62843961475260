import {FormGroup} from '@angular/forms';

export class StrengthPasswordValidator {
  static isStrength(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

      if (control.errors && !control.errors.isNotStrength) {
        return null;
      }

      if (control.value.match(matchPattern) === null) {
        control.setErrors({isNotStrength: true});
      } else {
        control.setErrors(null);
      }
      return null;
    };
  }
}
