<div class="account-preview">
  <h2 class="account-preview__username"> {{ account.username }}</h2>
  <div class="info-wrapper">
    <div class="info-wrapper__title">
      Email:
    </div>
    <div class="info-wrapper__content">
      {{ account.email }}
    </div>
  </div>
  <div class="info-wrapper">
    <div class="info-wrapper__title">
      Utworzono:
    </div>
    <div class="info-wrapper__content">
      {{ dateService.prepareDate(account.createdAt) }}
    </div>
  </div>
  <div class="info-wrapper">
    <div class="info-wrapper__title">
      Ostatnia aktualizacja:
    </div>
    <div class="info-wrapper__content">
      {{ dateService.prepareDate(account.updatedAt) }}
    </div>
  </div>

  <mat-divider></mat-divider>
  <h3> Dostępy użytkownika</h3>
  <div class="row">
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.USER) ? 'card card__success' : 'card card__danger'">
      Użytkownicy
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.FILE) ? 'card card__success' : 'card card__danger'">
      Pliki
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.PROPOSAL) ? 'card card__success' : 'card card__danger'">
      Wnioski
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.SCHOLARSHIP) ? 'card card__success' : 'card card__danger'">
      Stypendia
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.AWARD) ? 'card card__success' : 'card card__danger'">
      Nagrody
    </mat-card>
  </div>
  <div class="row">
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.LOG) ? 'card card__success' : 'card card__danger'">
      Logi
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.POOL) ? 'card card__success' : 'card card__danger'">
      Pule
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.PROPOSAL_SETTINGS) ? 'card card__success' : 'card card__danger'">
      Ustawienia wniosków
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.SCHOLARSHIP_SETTINGS) ? 'card card__success' : 'card card__danger'">
      Ustawienia stypendiów
    </mat-card>
    <mat-card
      [class]="account.hasPermissions.includes(USER_PERMISSIONS.AWARD_SETTINGS) ? 'card card__success' : 'card card__danger'">
      Ustawienia nagród
    </mat-card>
  </div>
</div>
