<div class="scholarship-upload">
  <mat-form-field class="scholarship-name">
    <mat-label> Nazwa nagrody</mat-label>
    <input [(ngModel)]="scholarship.name" aria-label="scholarship-name" matInput placeholder="np. Karol Nowak, konkurs wojewódzki"
           type="text">
  </mat-form-field>
  <div class="files-card-wrapper">
    <mat-card *ngIf="!fileToUpload" class="file-card">
      <button class="file-card__add" color="primary" mat-mini-fab>
        <mat-icon class="file-card__icon">
          <div class="form-group">
            <label for="file"> add</label>
            <input (change)="uploadFile($event.target.files)" class="hide-input" id="file"
                   type="file">
          </div>
        </mat-icon>
      </button>
    </mat-card>
    <mat-card *ngIf="fileToUpload" class="file-card">
      <mat-spinner [diameter]="40" class="file-card__mat-spinner"></mat-spinner>
    </mat-card>
    <mat-card *ngFor="let file of scholarship.files" class="file-card">
      <div class="file-card__content">
        <div class="title">
          {{ file.name }}
        </div>
        <div class="sub-title">
          Utworzono {{ file.created_at }}
        </div>
      </div>
      <button (click)="deleteFile(file.id)" class="admin-sidenav-component__button" color="warn" mat-mini-fab>
        <mat-icon> delete</mat-icon>
      </button>
    </mat-card>
  </div>
  <button (click)="onFormSubmit()" [disabled]="fileToUpload !== null" color="primary" mat-raised-button> Wyślij</button>
</div>
