import {Component, OnInit} from '@angular/core';
import {ScholarshipSettingsModel} from "../../../../models/requests/scholarship/scholarship-settings.model";
import {ApiService} from "../../../../services/api.service";
import {SweetalertService} from "../../../../services/sweetalert.service";
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-scholarship-settings',
  templateUrl: './scholarship-settings.component.html',
  styleUrls: ['./scholarship-settings.component.scss']
})
export class ScholarshipSettingsComponent implements OnInit {
  public scholarshipSettings: ScholarshipSettingsModel;
  public isLoading: boolean;

  constructor(
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getScholarshipSettings().subscribe((response: any) => {
        this.isLoading = false;
        this.scholarshipSettings = response.data[0];
      },
      error => {
        this.errorService.handleError(error);
      })
  }

  public onFormSubmit() {
    this.apiService.updateScholarshipSettings(this.scholarshipSettings).subscribe((response: any) => {
        this.sweetalertService.success();
      },
      error => {
        this.errorService.handleError(error);
      })
  }
}
