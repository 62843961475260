import {Component, HostListener} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {GuardService} from '../../../services/guard.service';
import {USER_PERMISSIONS} from '../../../enums/user-permissions.enum';

@Component({
  selector: 'app-admin-sidenav',
  templateUrl: './admin-sidenav.component.html',
  styleUrls: ['./admin-sidenav.component.scss']
})
export class AdminSidenavComponent {
  public USER_PERMISSIONS: typeof USER_PERMISSIONS = USER_PERMISSIONS;

  public width;

  constructor(
    private authService: AuthService,
    public guard: GuardService,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
     this.width = event.target.innerWidth;
  }

  public logout() {
    this.authService.logout();
  }
}
