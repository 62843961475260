import { Component, OnInit } from '@angular/core';
import {SweetalertService} from '../../../services/sweetalert.service';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-account-activate',
  templateUrl: './account-activate.component.html',
  styleUrls: ['./account-activate.component.scss']
})
export class AccountActivateComponent implements OnInit {
  public isWaiting = false;
  activationKey: string;

  constructor(
    private route: ActivatedRoute,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.activationKey = params.activationKey;
      this.isWaiting = true;

      this.apiService.accountActivation(this.activationKey).subscribe((response: any) => {
        this.isWaiting = false;
        this.router.navigate(['']);
        this.sweetalertService.accountActivateSuccess();
      }, error => {
        this.isWaiting = false;
        this.sweetalertService.accountActivateError();
      });
    });
  }
}
