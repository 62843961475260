import {AbstractControl, ValidatorFn} from '@angular/forms';

export function PeselValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value === '' || control.value == null) {
      return null;
    }

    if (control.errors && !control.errors.validatePesel) {
      return null;
    }

    const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    const pesel = control.value;
    const regNumberOnly = new RegExp('^[0-9]*$');
    let sum = 0;

    for (let i = 0; i < weight.length; i++) {
      // tslint:disable-next-line:radix
      sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
    }

    // tslint:disable-next-line:radix
    if ((10 - sum % 10) % 10 !== parseInt(pesel.substring(10, 11)) || !regNumberOnly.test(pesel) || pesel.length > 11) {
      return {validatePesel: {value: control.value}};
    }

    return null;
  };
}
