<div class="scholarship-home">
  <div class="scholarship-home__navbar">
    <mat-card (click)="filterScholarships()"
              [class]="router.url === '/admin/scholarship' ? 'card--active': 'card'"> Wszystkie
    </mat-card>
    <mat-card (click)="filterScholarships(SCHOLARSHIP_STATUS.NEW)"
              [class]="router.url === '/admin/scholarship?status=' + SCHOLARSHIP_STATUS.NEW ? 'card--active': 'card'">
      Nowe
    </mat-card>
    <mat-card (click)="filterScholarships(SCHOLARSHIP_STATUS.ACCEPTED)"
              [class]="router.url === '/admin/scholarship?status=' + SCHOLARSHIP_STATUS.ACCEPTED ? 'card--active': 'card'">
      Zaakceptowane
    </mat-card>
    <mat-card (click)="filterScholarships(SCHOLARSHIP_STATUS.REJECTED)"
              [class]="router.url === '/admin/scholarship?status=' + SCHOLARSHIP_STATUS.REJECTED ? 'card--active': 'card'">
      Odrzucone
    </mat-card>
    <mat-card (click)="filterScholarships(SCHOLARSHIP_STATUS.CLOSED)"
              [class]="router.url === '/admin/scholarship?status=' + SCHOLARSHIP_STATUS.CLOSED ? 'card--active': 'card'">
      Zamknięte
    </mat-card>
    <mat-card (click)="this.router.navigate(['admin/scholarship/settings'])"
              [class]="router.url === '/admin/scholarship/settings' ? 'card--active': 'card'"> Ustawienia
    </mat-card>
  </div>
  <div class="scholarship-home__content">
    <router-outlet></router-outlet>
  </div>
</div>
