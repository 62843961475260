import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {ErrorService} from '../../../../services/error.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.scss']
})
export class ProposalListComponent implements OnInit {

  public displayedColumns: string[] = ['id', 'pool', 'type', 'publicationName', 'isbn', 'status', 'options'];
  public proposals;
  public isLoading = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public router: Router,
    private apiService: ApiService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getLoggedPublisherProposals().subscribe((response: any) => {
      this.proposals = new MatTableDataSource(response.data);
      this.proposals.paginator = this.paginator;
      this.proposals.sort = this.sort;
      this.isLoading = false;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  preview(id) {
    this.isLoading = true;

    this.apiService.getPreviewProposal(id).subscribe(data => {
        this.downloadFile(data);
        this.isLoading = false;
      }, error => {
      console.log('Error downloading the file.');
      this.errorService.handleError(error);
        }, () => {
      // tslint:disable-next-line:no-console
      console.info('OK');
      this.isLoading = false;
    });
  }

  downloadFile(data: Response) {
    const url = window.URL.createObjectURL(data);
    window.open(url, 'Download');
  }
}
