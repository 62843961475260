import {Injectable} from "@angular/core";
import {ApiService} from "../../../../services/api.service";
import {Subject} from "rxjs";
import {ErrorService} from '../../../../services/error.service';

@Injectable()
export class AccountManageService {
  private observer = new Subject();
  public account = this.observer.asObservable();

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService) {
  }

  public refreshUser(id) {
    this.apiService.getUserById(id).subscribe((response: any) => {
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    })
  }
}
