import {Component, Inject, Input, OnInit} from '@angular/core';
import {PoolModel} from '../../../../models/requests/pool/pool.model';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PoolListService} from '../pool-list/pool-list.service';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-pool-upload',
  templateUrl: './pool-upload.component.html',
  styleUrls: ['./pool-upload.component.scss']
})
export class PoolUploadComponent implements OnInit {
  @Input() public pool: PoolModel = {
    name: '',
    poolConditions: [{name: ''}],
    poolPublicationTypes: [{name: '', maximumAmountAwards: 0}],
    maximumAmountAwards: 0,
    startDate: '',
    endDate: '',
  };
  public fileToUpload: File = null;
  public poolMaximumAmountAwards = 0;
  private isNew;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    private dialogRef: MatDialog,
    private poolAgreementService: PoolListService,
  ) {
  }

  ngOnInit(): void {
    (this.data) ? this.isNew = false : this.isNew = true;
    this.managePoolConditions();
    this.managePoolPublicationTypes();
  }

  public managePoolConditions() {
    const indexes = [];
    for (let x = 0; x < this.pool.poolConditions.length; x++) {
      if (this.pool.poolConditions[x].name === '') {
        indexes.push(x);
      }
    }

    indexes.reverse();
    indexes.forEach(index => {
      this.pool.poolConditions.splice(index, 1);
    });

    this.pool.poolConditions.push({name: ''});
  }

  public managePoolPublicationTypes() {
    const indexes = [];
    let sumMaximumAmountAwards = 0;
    for (let x = 0; x < this.pool.poolPublicationTypes.length; x++) {
      if (this.pool.poolPublicationTypes[x].name === '') {
        indexes.push(x);
      }
      const maximumAmountAwards = Number(this.pool.poolPublicationTypes[x].maximumAmountAwards);
      if (maximumAmountAwards) {
        sumMaximumAmountAwards += maximumAmountAwards;
      }
    }
    this.poolMaximumAmountAwards = sumMaximumAmountAwards;

    indexes.reverse();
    indexes.forEach(index => {
      this.pool.poolPublicationTypes.splice(index, 1);
    });

    this.pool.poolPublicationTypes.push({name: ''});
  }

  public onFormSubmit() {
    let requestPool: PoolModel;

    requestPool = this.pool;
    requestPool.startDate = new Date(this.pool.startDate).toISOString();
    requestPool.endDate = new Date(this.pool.endDate).toISOString();
    requestPool.poolConditions.pop();
    requestPool.poolPublicationTypes.pop();

    requestPool.maximumAmountAwards = this.poolMaximumAmountAwards;

    if (this.isNew) {
      this.apiService.postPool(requestPool).subscribe((response: any) => {
        this.sweetalertService.success();
        this.poolAgreementService.refreshPoolList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
        this.managePoolConditions();
        this.managePoolPublicationTypes();
      });
    } else {
      this.apiService.updatePool(this.pool.id, requestPool).subscribe((response: any) => {
        this.sweetalertService.success();
        this.poolAgreementService.refreshPoolList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
        this.managePoolConditions();
        this.managePoolPublicationTypes();
      });
    }
  }
}
