import {Component, OnInit} from '@angular/core';
import {GuardService} from '../../../services/guard.service';
import {USER_PERMISSIONS} from '../../../enums/user-permissions.enum';
import {ApiService} from '../../../services/api.service';
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  public USER_PERMISSIONS: typeof USER_PERMISSIONS = USER_PERMISSIONS;

  public isWaiting = false;
  public proposals = {
    new: 0,
    accepted: 0,
    rejected: 0,
    all: 0
  };
  public scholarships = {
    new: 0,
    accepted: 0,
    rejected: 0,
    all: 0
  };
  public awards = {
    new: 0,
    accepted: 0,
    rejected: 0,
    all: 0
  };

  constructor(
    public guard: GuardService,
    private apiService: ApiService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.isWaiting = true;

    this.apiService.getProposalStatistics().subscribe((response: any) => {
      this.proposals.new = response.data.new;
      this.proposals.accepted = response.data.accepted;
      this.proposals.rejected = response.data.rejected;
      this.proposals.all = response.data.all;
      this.isWaiting = false;
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });

    this.apiService.getScholarshipStatistics().subscribe((response: any) => {
      this.scholarships.new = response.data.new;
      this.scholarships.accepted = response.data.accepted;
      this.scholarships.rejected = response.data.rejected;
      this.scholarships.all = response.data.all;
      this.isWaiting = false;
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });

    this.apiService.getAwardStatistics().subscribe((response: any) => {
      this.awards.new = response.data.new;
      this.awards.accepted = response.data.accepted;
      this.awards.rejected = response.data.rejected;
      this.awards.all = response.data.all;
      this.isWaiting = false;
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });
  }

}
