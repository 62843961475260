<div class="award-settlements" *ngIf="award.status == AWARD_STATUS.ACCEPTED">
  <h2> Pliki potrzebne do rozliczenia</h2>
  <mat-form-field class="settlement-comment">
    <mat-label> Komentarz</mat-label>
    <textarea [(ngModel)]="award.awardSettlement.comment" matInput placeholder="Maksymalnie 5000 znaków"> </textarea>
  </mat-form-field>
  <div class="row">
    <mat-checkbox [(ngModel)]="award.awardSettlement.isCorrect" class="settlement-checkbox">
      Rozliczone poprawnie
    </mat-checkbox>
  </div>
  <div class="files-card-wrapper">
    <mat-card *ngIf="!fileToUpload" class="file-card">
      <button class="file-card__add" color="primary" mat-mini-fab>
        <mat-icon class="file-card__icon">
          <div class="form-group">
            <label for="file"> add</label>
            <input (change)="uploadFile($event.target.files)" class="hide-input" id="file"
                   type="file">
          </div>
        </mat-icon>
      </button>
    </mat-card>
    <mat-card *ngIf="fileToUpload" class="file-card">
      <mat-spinner [diameter]="40" class="file-card__mat-spinner"></mat-spinner>
    </mat-card>
    <mat-card *ngFor="let file of files" class="file-card">
      <div class="file-card__content">
        <div class="title">
          {{ file.name }}
        </div>
        <div class="sub-title">
          Utworzono {{ file.created_at }}
        </div>
      </div>
      <button (click)="deleteFile(file.id)" class="admin-sidenav-component__button" color="warn" mat-mini-fab>
        <mat-icon> delete</mat-icon>
      </button>
    </mat-card>
  </div>
  <button (click)="onFormSubmit()" [disabled]="fileToUpload !== null" color="primary" mat-raised-button> Wyślij</button>
</div>
<div class="award-settlements" *ngIf="award.status !== AWARD_STATUS.ACCEPTED">
  <h2> Można rozliczyć jedynie zaakceptowane nagrody</h2>
</div>
