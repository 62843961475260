<div class="account-recovery">
  <img src="assets/images/logo.jpg" alt="logo" class="account-recovery__logo">
  <mat-card class="account-recovery-card">
    <mat-card-title class="account-recovery-card__title"> Odzyskiwanie hasła</mat-card-title>
    <mat-card-subtitle> Wpisz swoje nowe hasło i zatwierdź je ponownym wpisaniem</mat-card-subtitle>
    <mat-card-content class="account-recovery-card__content">
      <form [formGroup]="recoveryPasswordForm" (ngSubmit)="onFormSubmit(recoveryPasswordForm)">
        <mat-form-field class="account-recovery-card__input">
          <mat-label> Hasło</mat-label>
          <input matInput type="password" placeholder="Hasło" aria-label="password" formControlName="password" minlength="8">
          <mat-hint *ngIf="recoveryPasswordForm?.controls.password?.touched && recoveryPasswordForm?.controls.password?.errors" [class.error]="true">
            Hasło powinno mieć conajmniej 8 znaków w tym minimum jedną wielką literę, jedną cyfrę i jeden znak specjalny
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="account-recovery-card__input">
          <mat-label> Powtórz hasło</mat-label>
          <input matInput type="password" placeholder="Powtórz hasło" aria-label="repeatPassword" formControlName="repeatPassword">
          <mat-hint *ngIf="recoveryPasswordForm?.controls.repeatPassword?.touched && recoveryPasswordForm?.controls.repeatPassword?.errors" [class.error]="true">
            Hasła różnią się od siebie
          </mat-hint>
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary"> Wyślij</button>
      </form>
    </mat-card-content>
    <mat-card-footer class="account-recovery-card__footer">
      Zaloguj się <a routerLink="/login" routerLinkActive="active">tutaj</a>.
    </mat-card-footer>
  </mat-card>
</div>
<app-loader [isOpen]="isWaiting"></app-loader>
