import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'poolStatusToText'})
export class PoolStatusToText implements PipeTransform {

  transform(value: number) {
    switch (value) {
      case 0:
        return 'Nowa';
      case 1:
        return 'Zaakceptowana';
      case 2:
        return 'Odrzucona';
      case 3:
        return 'Zakończona';
      default:
        return '';
    }
  }
}
