import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-admin-publication-preview',
  templateUrl: './proposal-publication.component.html',
  styleUrls: ['./proposal-publication.component.scss']
})
export class ProposalPublicationComponent {
  @Input() public publicationNumber: number;
  @Input() public publication: any;

}
