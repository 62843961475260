<div *ngIf="award" class="award-manage">
  <div class="award-manage__menu">
    <mat-card (click)="setActiveSection(1)"
              [class]="activeSection === 1 ? 'card--active': 'card'"> Podgląd
    </mat-card>
    <mat-card (click)="setActiveSection(2)"
              [class]="activeSection === 2 ? 'card--active': 'card'"> Edycja
    </mat-card>
    <mat-card (click)="setActiveSection(3)"
              [class]="activeSection === 3 ? 'card--active': 'card'"> Akceptacja
    </mat-card>
    <mat-card (click)="setActiveSection(4)"
              [class]="activeSection === 4 ? 'card--active': 'card'"> Rozliczenie
    </mat-card>
    <mat-card (click)="deleteAward(award.id)" class="card--danger"> Usuń</mat-card>
  </div>
  <mat-divider></mat-divider>
  <section *ngIf="activeSection === 1">
    <app-award-preview [award]="award"></app-award-preview>
  </section>
  <section *ngIf="activeSection === 2">
    <app-award-upload [award]="award"></app-award-upload>
  </section>
  <section *ngIf="activeSection === 3">
    <app-award-accept [award]="award"></app-award-accept>
  </section>
  <section *ngIf="activeSection === 4">
    <app-award-settlements [award]="award"></app-award-settlements>
  </section>
</div>
<div *ngIf="!award" class="award-manage__loader">
  <mat-spinner></mat-spinner>
</div>
