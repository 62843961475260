import {Component, Input} from '@angular/core';
import {FileDownloaderService} from '../../../../components/admin/file-downloader/file-downloader.service';
import {DateService} from '../../../../services/date.service';

@Component({
  selector: 'app-proposal-information',
  templateUrl: './proposal-information.component.html',
  styleUrls: ['./proposal-information.component.scss']
})
export class ProposalInformationComponent {
  @Input() public proposal;

  constructor(
    public fileDownloader: FileDownloaderService,
    public dateService: DateService,
  ) {
  }

  public downloadFile(file) {
    this.fileDownloader.addNewFileToDownload(file);
  }
}
