import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ProposalManageService} from '../proposal-manage/proposal-manage.service';
import {ProposalListService} from '../proposal-list/proposal-list.service';
import {PROPOSAL_STATUS} from '../../../../enums/proposal-status.enum';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-proposal-settlements',
  templateUrl: './proposal-settlements.component.html',
  styleUrls: ['./proposal-settlements.component.scss']
})
export class ProposalSettlementsComponent implements OnInit {
  public fileToUpload: File = null;
  public uploadedFiles = [];
  public billingDate = new Date();

  public PROPOSAL_STATUS: typeof PROPOSAL_STATUS = PROPOSAL_STATUS;
  @Input() public proposal;
  private isNew;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    private proposalManageService: ProposalManageService,
    private dialogRef: MatDialog,
    private proposalAgreementService: ProposalListService,
  ) {
  }

  ngOnInit(): void {
    if (this.proposal.proposalSettlement?.id) {
      this.isNew = false;
      this.uploadedFiles = this.proposal.proposalSettlement.files;
    } else {
      this.isNew = true;
      this.proposal.proposalSettlement = {
        comment: '',
        isCorrect: false,
        files: [],
        billingDate: null,
        proposal: this.proposal.id
      };
    }
  }

  public deleteFile(id: number) {
    let filePosition;

    for (let x = 0; x < this.uploadedFiles.length; x++) {
      if (this.uploadedFiles[x].id === id) {
        filePosition = x;
        break;
      }
    }

    this.uploadedFiles.splice(filePosition, 1);
  }

  public uploadFile(files: FileList) {
    this.fileToUpload = files.item(0);

    this.apiService.postFile(this.fileToUpload).subscribe((response: any) => {
      this.uploadedFiles.push(response.data);
      this.fileToUpload = null;
    }, error => {
      this.errorService.handleError(error);
      this.fileToUpload = null;
    });
  }

  public formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  public onFormSubmit() {
    this.proposal.proposalSettlement.proposal = this.proposal.id;
    this.proposal.proposalSettlement.files = [];
    this.proposal.proposalSettlement.billingDate = this.formatDate(this.billingDate);
    // tslint:disable-next-line:prefer-for-of
    for (let x = 0; x < this.uploadedFiles.length; x++) {
      this.proposal.proposalSettlement.files.push(this.uploadedFiles[x].id);
    }

    if (this.isNew) {
      this.apiService.postProposalSettlement(this.proposal.proposalSettlement).subscribe((response: any) => {
        this.sweetalertService.success();
        this.proposalAgreementService.refreshProposalList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
      });
    } else {
      this.apiService.updateProposalSettlement(
        this.proposal.proposalSettlement.id,
        this.proposal.proposalSettlement
      ).subscribe((response: any) => {
        this.sweetalertService.success();
        this.proposalAgreementService.refreshProposalList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
      });
    }
  }
}
