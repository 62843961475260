<div class="login">
  <img src="assets/images/logo.jpg" alt="logo" class="login__logo">
  <mat-card class="login-card">
    <mat-card-title class="login-card__title"> Zmień hasło aby kontynuować</mat-card-title>
    <mat-card-subtitle>Twoje hasło wygasło. Wpisz swoje nowe hasło i zatwierdź je ponownym wpisaniem</mat-card-subtitle>

    <mat-card-content class="login-card__content">
      <form [formGroup]="changePasswordExpiredForm" (ngSubmit)="onFormSubmit(changePasswordExpiredForm)">
        <mat-form-field class="login-card__input">
        <mat-label>Aktualne hasło</mat-label>
          <input matInput type="password" placeholder="Hasło" aria-label="password" formControlName="oldPassword" minlength="8">
        </mat-form-field>
        <mat-form-field class="login-card__input">
        <mat-label>Nowe hasło</mat-label>
          <input matInput type="password" placeholder="Nowe hasło" aria-label="password" formControlName="newPassword" minlength="8">
          <mat-hint *ngIf="changePasswordExpiredForm?.controls.newPassword?.touched && changePasswordExpiredForm?.controls.newPassword?.errors" [class.error]="true">
            Hasło powinno mieć conajmniej 8 znaków w tym minimum jedną wielką literę, jedną cyfrę i jeden znak specjalny
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="login-card__input">
        <mat-label> Powtórz hasło</mat-label>
          <input matInput type="password" placeholder="Powtórz hasło" aria-label="repeatPassword" formControlName="repeatPassword">
          <mat-hint *ngIf="changePasswordExpiredForm?.controls.repeatPassword?.touched && changePasswordExpiredForm?.controls.repeatPassword?.errors" [class.error]="true">
            Hasła różnią się od siebie
          </mat-hint>
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary"> Zmień hasło</button>
      </form>
    </mat-card-content>

  </mat-card>
</div>
<app-loader [isOpen]="isWaiting"></app-loader>
