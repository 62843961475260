import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PROPOSAL_STATUS} from '../../../enums/proposal-status.enum';

@Component({
  selector: 'app-proposal-home',
  templateUrl: './proposal-home.component.html',
  styleUrls: ['./proposal-home.component.scss']
})
export class ProposalHomeComponent implements OnInit {
  public PROPOSAL_STATUS: typeof PROPOSAL_STATUS = PROPOSAL_STATUS;
  public pool;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.url.startsWith('/admin/co-financing/proposal')) {
        this.pool = params.pool;
      }
    });
  }

  ngOnInit(): void {
  }

  public filterProposals(status = null, pool = null) {
    this.router.navigate(['admin/co-financing/proposal'], {queryParams: {status, pool}});
  }

  public goToSettings(pool = null) {
    this.router.navigate(['admin/co-financing/proposal/settings'], {queryParams: {pool}});
  }
}
