<mat-sidenav-container class="admin">
  <mat-sidenav #sidenav [mode]="isMobileResolution ? 'over' : 'side'" [(opened)]="isMenuOpen" (click)="closeMenu(sidenav)">
    <app-admin-sidenav></app-admin-sidenav>
  </mat-sidenav>
  <mat-sidenav-content class="admin__content">
    <button mat-button class="menu-button" *ngIf="isMobileResolution" (click)="sidenav.toggle()">
      <mat-icon>{{ isMenuOpen ? 'menu_open' : 'menu' }}</mat-icon>
    </button>
    <router-outlet></router-outlet>
    <button
      (click)="downloadSidenav.toggle()" [matBadgeHidden]="filesQuantity === 0" [matBadge]="filesQuantity" class="download-button"
      color="primary" mat-fab matBadgeColor="warn">
      <mat-icon> get_app</mat-icon>
    </button>
  </mat-sidenav-content>
  <mat-sidenav #downloadSidenav mode="over" position="end">
    <app-file-downloader></app-file-downloader>
  </mat-sidenav>
</mat-sidenav-container>
