import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SCHOLARSHIP_STATUS} from "../../../enums/scholarship-status.enum";

@Component({
  selector: 'app-scholarship-home',
  templateUrl: './scholarship-home.component.html',
  styleUrls: ['./scholarship-home.component.scss']
})
export class ScholarshipHomeComponent implements OnInit {
  public SCHOLARSHIP_STATUS: typeof SCHOLARSHIP_STATUS = SCHOLARSHIP_STATUS;

  constructor(
    public router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  public filterScholarships(status = null) {
    this.router.navigate(['admin/scholarship'], {queryParams: {status: status}});
  }
}
