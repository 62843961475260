import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../../../services/error.service';

@Injectable()
export class PoolListService {
  public status;
  public isLoading;
  private observer: any = new Subject();
  public pools = this.observer.asObservable();

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.url.startsWith('/admin/co-financing/pool')) {
        this.status = params.status;
        this.refreshPoolList(params.status);
      }
    });
  }

  public refreshPoolList(poolStatus = this.status) {
    this.isLoading = true;
    this.apiService.getPools({poolStatus}).subscribe((response: any) => {
      this.isLoading = false;
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    });
  }
}
