import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SweetalertService} from '../../../services/sweetalert.service';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {ErrorService} from '../../../services/error.service';
import {StrengthPasswordValidator} from '../../../validators/strengthPasswordValidator';
import {RepeatedPasswordValidator} from '../../../validators/repetedPasswordValidator';
import {ChangePasswordModel} from '../../../models/requests/account/change-password.model';

@Component({
  selector: 'app-change-password-expired',
  templateUrl: './change-password-expired.component.html',
  styleUrls: ['./change-password-expired.component.scss']
})
export class ChangePasswordExpiredComponent implements OnInit {
  public isWaiting = false;
  public changePasswordExpiredForm: FormGroup = this.formBuilder.group({
    oldPassword: [null, Validators.required],
    newPassword: [null, [Validators.required]],
    repeatPassword: [null, [Validators.required]],
  }, {
    validator: [
      StrengthPasswordValidator.isStrength('newPassword'),
      RepeatedPasswordValidator.mustMatch('newPassword', 'repeatPassword'),
    ]
  });

  constructor(
    private formBuilder: FormBuilder,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
  }

  public onFormSubmit(loginForm) {
    this.isWaiting = true;
    const changePasswordModel: ChangePasswordModel = loginForm.value;

    if (!loginForm.valid) {
      this.isWaiting = false;
      this.sweetalertService.registerFormError();
      return;
    }

    this.apiService.changePassword(changePasswordModel).subscribe((response: any) => {
      this.isWaiting = false;
      this.authService.setPasswordChanged();
      this.router.navigateByUrl('/');
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
    });
  }
}
