import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Subject} from 'rxjs';
import {ErrorService} from '../../../../services/error.service';

@Injectable()
export class PoolManageService {
  private observer = new Subject();
  public pool = this.observer.asObservable();

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService
  ) {
  }

  public updatePool(id) {
    this.apiService.getPool(id).subscribe((response: any) => {
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    });
  }
}
