import {Component, Input} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-proposal-preview',
  templateUrl: './proposal-preview.component.html',
  styleUrls: ['./proposal-preview.component.scss']
})
export class ProposalPreviewComponent {
  @Input() public proposal;
  public isPreviewProposalLoading = false;

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService
  ) {
  }

  getAuthorMembership(authorId: number) {
    const author = this.proposal.authors[authorId];
    if (author.memberships.length > 0) {
      return author.memberships[0];
    }
    return {
      is_member_association: false,
      is_contract_concluded: false,
    };
  }

  getPublisherMembership(publisherId: number) {
    const publisher = this.proposal.publishers[publisherId];
    if (publisher.memberships.length > 0) {
      return publisher.memberships[0];
    }
    return {
      is_member_association: false,
      is_contract_concluded: false,
    };
  }

  getPreviewFile() {
    this.isPreviewProposalLoading = true;
    this.apiService.getPreviewProposal(this.proposal.id).subscribe(data => {
      this.downloadFile(data);
    }, error => {
      this.isPreviewProposalLoading = false;
      console.log('Error downloading the file.');
      this.errorService.handleError(error);
    }, () => {
      this.isPreviewProposalLoading = false;
      // tslint:disable-next-line:no-console
      console.info('OK');
    });
  }

  downloadFile(data: Response) {
    const url = window.URL.createObjectURL(data);
    window.open(url, 'Download');
  }
}
