import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProposalManageComponent} from '../proposal-manage/proposal-manage.component';
import {ProposalUploadComponent} from '../proposal-upload/proposal-upload.component';
import {ProposalListService} from './proposal-list.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {NavigationEnd, Router} from '@angular/router';
import {ProposalsFilterModel} from '../../../../models/requests/proposal/proposals-filter.model';

@Component({
  selector: 'app-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.scss']
})
export class ProposalListComponent implements OnInit {
  public displayedColumns: string[] = [
    'id',
    'publishCategory',
    'poolName',
    'amountFunding',
    'user',
    'publishTitle',
    'isbn',
    'descriptionPublication',
    'justificationPublishing',
    'descriptionCreativeActivityApplicant',
    'status',
    'createdAt',
    'options'
  ];
  public proposals;
  public params: ProposalsFilterModel = {};

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    public proposalListService: ProposalListService,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.proposalListService.refreshProposalList();
    this.proposalListService.proposals.subscribe(data => {
      this.proposals = new MatTableDataSource(data);
      this.proposals.paginator = this.paginator;
      this.proposals.sort = this.sort;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setDefaultParams();
      }
    });
  }

  public search() {
    this.proposalListService.refreshProposalList(this.params);
  }

  public clearSearch() {
    this.setDefaultParams();
    this.search();
  }

  private setDefaultParams() {
    this.params = {
      user: null,
      userEmail: null,
      publisherName: null,
      publisherNip: null,
    };
  }

  public openDialog(id: number) {
    const dialogRef = this.dialog.open(ProposalManageComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1240px',
      maxWidth: '1240px',
      minWidth: '540px',
      panelClass: 'block-panel',
      data: {
        proposalId: id
      },
    });
  }

  public createNewProposalAgreement() {
    const dialogRef = this.dialog.open(ProposalUploadComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
