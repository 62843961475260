<div class="co-financing">
  <div class="co-financing__navbar">
    <mat-card [class]="'card'" routerLink="/admin/co-financing/pool" routerLinkActive="card--active">
      Pule
    </mat-card>
    <mat-card [class]="'card'" routerLink="/admin/co-financing/proposal" routerLinkActive="card--active">
      Wnioski
    </mat-card>
  </div>
  <div class="co-financing__content">
    <router-outlet></router-outlet>
  </div>
</div>
