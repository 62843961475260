<div *ngIf="!isLoading" class="scholarship-accept">
  <div *ngIf="scholarship.status == SCHOLARSHIP_STATUS.NEW">
    <h2> Akceptuj/odrzuć</h2>
    <mat-form-field class="accept-comment">
      <mat-label> Komentarz</mat-label>
      <textarea [(ngModel)]="scholarshipStatus.message" matInput placeholder="Maksymalnie 5000 znaków"> </textarea>
    </mat-form-field>
    <div class="accept-buttons">
      <button (click)="accept()" [disabled]="disabled" class="accept-buttons__button" color="primary" mat-raised-button
              type="button"> Akceptuj
      </button>
      <button (click)="decline()" [disabled]="disabled" class="accept-buttons__button" color="warn" mat-raised-button>
        Odrzuć
      </button>
    </div>
  </div>
  <h2> Akcje innych użytkowników</h2>
  <div class="accept-card__wrapper">
    <ng-container *ngFor="let action of this.scholarship.scholarshipStatuses">
      <mat-card class="accept-card" [class.accept-card--success]="action.isAccepted" [class.accept-card--warn]="!action.isAccepted" *ngIf="action.isActive">
        <mat-card-title *ngIf="action.isAccepted"> Zaakceptowane</mat-card-title>
        <mat-card-title *ngIf="!action.isAccepted"> Odrzucone</mat-card-title>
        <mat-card-subtitle> Przez {{ action.user.username }} dnia {{ dateService.prepareFullDate(action.createdAt) }}</mat-card-subtitle>
        <mat-card-content>
          {{ action.message }}
        </mat-card-content>
      </mat-card>
    </ng-container>
    <h3> Akcje nieaktywne</h3>
    <ng-container *ngFor="let action of this.scholarship.scholarshipStatuses">
      <mat-card class="accept-card" [class.accept-card--success]="action.isAccepted" [class.accept-card--warn]="!action.isAccepted" *ngIf="!action.isActive">
        <mat-card-title *ngIf="action.isAccepted"> Zaakceptowane</mat-card-title>
        <mat-card-title *ngIf="!action.isAccepted"> Odrzucone</mat-card-title>
        <mat-card-subtitle> Przez {{ action.user.username }} dnia {{ dateService.prepareFullDate(action.createdAt) }}</mat-card-subtitle>
        <mat-card-content>
          {{ action.message }}
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>
