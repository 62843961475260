<div class="edit-email" [hidden]="isLoading">
  <div class="mb mat-elevation-z8">
    <h1>Zmiana adresów e-mail we wniosku {{proposalId}}</h1>
    <br>
    <form [formGroup]="emailForm" (ngSubmit)="onSubmit(emailForm)">
      <div class="form-group__wrapper">
        <div class="row" *ngFor="let email of emailForm.get('emails')['controls']; let i=index" [formGroup]="email">

          <ng-container *ngIf="proposalEmailsModel[i]['isAuthor'] == true">
            <h2 class="col-sm-12">Autor {{proposalEmailsModel[i]['name']}}</h2> <br>
          </ng-container>

          <ng-container *ngIf="!proposalEmailsModel[i]['isAuthor']">
            <h2>Wydawca {{proposalEmailsModel[i]['name']}}</h2> <br>
          </ng-container>

          <div class="form-group__content">
            <div class="row">
              <mat-form-field>
                <mat-label> Aktualny e-mail</mat-label>
                <input type="email"  [attr.disabled]="'disabled'" matInput formControlName="email" oninput="this.value = this.value.toUpperCase()">
              </mat-form-field>

              <mat-form-field>
                <mat-label> Nowy adres e-mail</mat-label>
                <input type="email" matInput formControlName="newEmail" oninput="this.value = this.value.toUpperCase()">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <button mat-raised-button color="primary" type="submit"> Zapisz zmiany</button>

    </form>
  </div>
</div>
<app-loader [isOpen]="isLoading"></app-loader>
