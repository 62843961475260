import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ScholarshipManageService} from '../scholarship-manage/scholarship-manage.service';
import {ScholarshipListService} from '../scholarship-list/scholarship-list.service';
import {SCHOLARSHIP_STATUS} from '../../../../enums/scholarship-status.enum';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-scholarship-settlements',
  templateUrl: './scholarship-settlements.component.html',
  styleUrls: ['./scholarship-settlements.component.scss']
})
export class ScholarshipSettlementsComponent implements OnInit {
  public fileToUpload: File = null;
  public files = [];
  public SCHOLARSHIP_STATUS: typeof SCHOLARSHIP_STATUS = SCHOLARSHIP_STATUS;
  @Input() public scholarship;
  private isNew;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    private scholarshipManageService: ScholarshipManageService,
    private dialogRef: MatDialog,
    private scholarshipAgreementService: ScholarshipListService,
  ) {
  }

  ngOnInit(): void {
    if (this.scholarship.scholarshipSettlement) {
      this.isNew = false;
      this.files = this.scholarship.scholarshipSettlement.files;
    } else {
      this.isNew = true;
      this.scholarship.scholarshipSettlement = {
        comment: '',
        isCorrect: false,
        files: [],
        scholarship: this.scholarship.id
      };
    }
  }

  public deleteFile(id: number) {
    let filePosition;

    for (let x = 0; x < this.files.length; x++) {
      if (this.files[x].id === id) {
        filePosition = x;
        break;
      }
    }

    this.files.splice(filePosition, 1);
  }

  public uploadFile(files: FileList) {
    this.fileToUpload = files.item(0);

    this.apiService.postFile(this.fileToUpload).subscribe((response: any) => {
      this.files.push(response.data);
      this.fileToUpload = null;
    }, error => {
      this.errorService.handleError(error);
      this.fileToUpload = null;
    });
  }

  public onFormSubmit() {
    this.scholarship.scholarshipSettlement.scholarship = this.scholarship.id;
    this.scholarship.scholarshipSettlement.files = [];
    for (let x = 0; x < this.files.length; x++) {
      this.scholarship.scholarshipSettlement.files.push(this.files[x].id);
    }

    if (this.isNew) {
      this.apiService.postScholarshipSettlement(this.scholarship.scholarshipSettlement).subscribe((response: any) => {
        this.sweetalertService.success();
        this.scholarshipAgreementService.refreshScholarshipList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
      });
    } else {
      this.apiService
        .updateScholarshipSettlement(this.scholarship.scholarshipSettlement.id, this.scholarship.scholarshipSettlement)
        .subscribe((response: any) => {
          this.sweetalertService.success();
          this.scholarshipAgreementService.refreshScholarshipList();
          this.dialogRef.closeAll();
          }, error => {
          this.errorService.handleError(error);
        });
    }
  }
}
