<div class="pool-accept" *ngIf="!isLoading">
  <ng-container *ngIf="pool.status == POOL_STATUS.NEW">
    <h2> Akceptuj/odrzuć</h2>
    <mat-form-field class="accept-comment">
      <mat-label> Komentarz</mat-label>
      <textarea matInput placeholder="Maksymalnie 5000 znaków" [(ngModel)]="poolStatus.message" aria-label="comment"> </textarea>
    </mat-form-field>
    <div class="accept-buttons">
      <button type="button" mat-raised-button color="primary" class="accept-buttons__button" (click)="accept()" [disabled]="disabled"> Akceptuj</button>
      <button mat-raised-button color="warn" class="accept-buttons__button" (click)="decline()" [disabled]="disabled"> Odrzuć</button>
    </div>
  </ng-container>
  <ng-container *ngIf="this.pool.poolStatuses.length > 0">
    <h2> Akcje innych użytkowników</h2>
    <div class="accept-card__wrapper">
      <h3> Akcje aktywne</h3>
      <ng-container *ngFor="let action of this.pool.poolStatuses">
        <mat-card class="accept-card" [class.accept-card--success]="action.isAccepted" [class.accept-card--warn]="!action.isAccepted" *ngIf="action.isActive">
          <mat-card-title *ngIf="action.isAccepted"> Zaakceptowane</mat-card-title>
          <mat-card-title *ngIf="!action.isAccepted"> Odrzucone</mat-card-title>
          <mat-card-subtitle> Przez {{ action.user.username }} dnia {{ dateService.prepareFullDate(action.createdAt) }}</mat-card-subtitle>
          <mat-card-content>
            {{ action.message }}
          </mat-card-content>
        </mat-card>
      </ng-container>
      <h3> Akcje nieaktywne</h3>
      <ng-container *ngFor="let action of this.pool.poolStatuses">
        <mat-card class="accept-card" [class.accept-card--success]="action.isAccepted" [class.accept-card--warn]="!action.isAccepted" *ngIf="!action.isActive">
          <mat-card-title *ngIf="action.isAccepted"> Zaakceptowane</mat-card-title>
          <mat-card-title *ngIf="!action.isAccepted"> Odrzucone</mat-card-title>
          <mat-card-subtitle> Przez {{ action.user.username }} dnia {{ dateService.prepareFullDate(action.createdAt) }}</mat-card-subtitle>
          <mat-card-content>
            {{ action.message }}
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </ng-container>
</div>
