<div class="login">
  <img src="assets/images/logo.jpg" alt="logo" class="login__logo">
  <mat-card class="login-card">
    <mat-card-title class="login-card__title"> Zaloguj się!</mat-card-title>
    <mat-card-content class="login-card__content">
      <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit(loginForm)">
        <mat-form-field class="login-card__input">
          <mat-label> Email</mat-label>
          <input matInput placeholder="Np. pat@example.com" aria-label="email" formControlName="email">
        </mat-form-field>
        <mat-form-field class="login-card__input">
          <mat-label> Password</mat-label>
          <input matInput type="password" placeholder="Twoje hasło" aria-label="password" formControlName="password">
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary"> Zaloguj</button>
      </form>
    </mat-card-content>
    <mat-card-footer class="login-card__footer">
      Nie masz jeszcze konta? Zarejestruj się <a routerLink="/register" routerLinkActive="active">tutaj</a>.<br>
      Nie pamiętasz hasła? Zresetujesz je <a routerLink="/account-recovery" routerLinkActive="active">tutaj</a>.<br><br>
      W wypadku problemów technicznych oraz w kwestiach merytorycznych prosimy pisać pod adres
      <a href="mailto:dlaslowa@copyrightpolska.pl">dlaslowa@copyrightpolska.pl</a>.
    </mat-card-footer>
  </mat-card>
</div>
<app-loader [isOpen]="isWaiting"></app-loader>
