import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {PoolSettingsModel} from '../../../../models/requests/pool/pool-settings.model';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-pool-settings',
  templateUrl: './pool-settings.component.html',
  styleUrls: ['./pool-settings.component.scss']
})
export class PoolSettingsComponent implements OnInit {
  public poolSettings: PoolSettingsModel;
  public isLoading: boolean;

  constructor(
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getPoolSettings().subscribe((response: any) => {
        this.isLoading = false;
        this.poolSettings = response.data[0];
      },
      error => {
        this.errorService.handleError(error);
      });
  }

  public onFormSubmit() {
    this.apiService.updatePoolSettings(this.poolSettings).subscribe((response: any) => {
        this.sweetalertService.success();
      },
      error => {
        this.errorService.handleError(error);
      });
  }
}
