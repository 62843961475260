<div class="admin-dashboard">
  <div class="admin-dashboard__column" *ngIf="!isWaiting">
    <mat-card class="manage-card">
      <mat-card-title class="manage-card__title">
        Dofinansowania
      </mat-card-title>
      <mat-card-content class="manage-card-row">
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ proposals.new }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Nowe
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ proposals.accepted }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Zaakceptowane
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ proposals.rejected }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Odrzucone
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ proposals.all }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Wszystkie wnioski
          </mat-card-title>
        </mat-card>
      </mat-card-content>
    </mat-card>
    <mat-card class="manage-card">
      <mat-card-title class="manage-card__title">
        Stypendia
      </mat-card-title>
      <mat-card-content class="manage-card-row">
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ scholarships.new }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Nowe
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ scholarships.accepted }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Zaakceptowane
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ scholarships.rejected }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Odrzucone
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ scholarships.all }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Wszystkie wnioski
          </mat-card-title>
        </mat-card>
      </mat-card-content>
    </mat-card>
    <mat-card class="manage-card">
      <mat-card-title class="manage-card__title">
        Nagrody
      </mat-card-title>
      <mat-card-content class="manage-card-row">
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ awards.new }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Nowe
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ awards.accepted }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Zaakceptowane
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ awards.rejected }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Odrzucone
          </mat-card-title>
        </mat-card>
        <mat-card class="statistic-card">
          <mat-card-content class="statistic-content">
            {{ awards.all }}
          </mat-card-content>
          <mat-card-title class="statistic-title">
            Wszystkie wnioski
          </mat-card-title>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="guard.hasPermission(USER_PERMISSIONS.LOG)" class="admin-dashboard__column">
    <h2> Ostatnie logi</h2>
    <app-logs [options]="{simplified: true, type: 'last'}"></app-logs>
  </div>
</div>
