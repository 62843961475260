import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class FileDownloaderService {
  private files = [];
  private observer: any = new Subject();
  public filesList = this.observer.asObservable();

  public addNewFileToDownload(file) {
    this.files.push(file);
    this.observer.next(this.files);
  }

  public isDownloading(file) {
    for (let x = 0; x < this.files.length; x++) {
      if (this.files[x].id === file.id) {
        return true;
      }
    }

    return false;
  }

  public removeFile(file) {
    let filePosition;

    for (let x = 0; x < this.files.length; x++) {
      if (this.files[x].id === file.id) {
        filePosition = x;
        break;
      }
    }

    this.files.splice(filePosition, 1);

    this.observer.next(this.files);
  }
}
