<div class="admin-sidenav-component">
  <img src="assets/images/logo.jpg" class="admin-sidenav-component__logo" alt="logo">
  <mat-divider></mat-divider>
  <mat-list>
    <mat-divider></mat-divider>
    <mat-list-item>
      <button mat-button color="primary" class="admin-sidenav-component__button" routerLink="/admin/dashboard">
        Strona główna
      </button>
    </mat-list-item>
    <mat-list-item *ngIf="guard.hasPermission(USER_PERMISSIONS.USER)">
      <button mat-button color="primary" class="admin-sidenav-component__button" routerLink="/admin/account-manage" routerLinkActive="mat-flat-button">
        Zarządzanie kontami
      </button>
    </mat-list-item>
    <mat-list-item *ngIf="guard.hasPermission(USER_PERMISSIONS.LOG)">
      <button mat-button color="primary" class="admin-sidenav-component__button" routerLink="/admin/logs" routerLinkActive="mat-flat-button">
        Logi
      </button>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item *ngIf="guard.hasPermission(USER_PERMISSIONS.PROPOSAL)">
      <button mat-button color="primary" class="admin-sidenav-component__button" routerLink="/admin/co-financing" routerLinkActive="mat-flat-button">
        Dofinansowania
      </button>
    </mat-list-item>
    <mat-list-item *ngIf="guard.hasPermission(USER_PERMISSIONS.SCHOLARSHIP)">
      <button mat-button color="primary" class="admin-sidenav-component__button" routerLink="/admin/scholarship" routerLinkActive="mat-flat-button">
        Stypendia
      </button>
    </mat-list-item>
    <mat-list-item *ngIf="guard.hasPermission(USER_PERMISSIONS.AWARD)">
      <button mat-button color="primary" class="admin-sidenav-component__button" routerLink="/admin/award" routerLinkActive="mat-flat-button">
        Nagrody
      </button>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <button mat-button color="warn" class="admin-sidenav-component__button" (click)="logout()">
        Wyloguj
      </button>
    </mat-list-item>
  </mat-list>
</div>
