import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {SweetalertService} from "../../../../services/sweetalert.service";
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {PoolManageService} from "./pool-manage.service";
import {PoolListService} from "../pool-list/pool-list.service";

@Component({
  selector: 'app-pool-manage',
  templateUrl: './pool-manage.component.html',
  styleUrls: ['./pool-manage.component.scss']
})
export class PoolManageComponent implements OnInit {
  public activeSection = 1;
  public pool;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private dialogRef: MatDialog,
    private poolManageService: PoolManageService,
    private poolAgreementService: PoolListService,
  ) {
  }

  ngOnInit(): void {
    this.poolManageService.updatePool(this.data.poolId);
    this.poolManageService.pool.subscribe(data => {
      this.pool = data;
    });
  }

  public setActiveSection(section: number) {
    this.activeSection = section;
  }
}
