import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/shared/login/login.component';
import {RegisterComponent} from './pages/shared/register/register.component';
import {DashboardComponent} from './pages/publisher/dashboard/dashboard.component';
import {PublisherComponent} from './pages/publisher/publisher.component';
import {EditEmailComponent} from './pages/publisher/proposal/proposal-create/edit-email/edit-email.component';
import {OptionsComponent} from './pages/publisher/options/options.component';
import {AccountRecoveryComponent} from './pages/shared/account-recovery/account-recovery.component';
import {AdminComponent} from './pages/admin/admin.component';
import {ProposalHomeComponent} from './pages/admin/proposal-home/proposal-home.component';
import {ScholarshipHomeComponent} from './pages/admin/scholarship-home/scholarship-home.component';
import {AwardHomeComponent} from './pages/admin/award-home/award-home.component';
import {ProposalListComponent as AdminProposalListComponent} from './pages/admin/proposal-home/proposal-list/proposal-list.component';
import {ProposalSettingsComponent} from './pages/admin/proposal-home/proposal-settings/proposal-settings.component';
import {AdminDashboardComponent} from './pages/admin/admin-dashboard/admin-dashboard.component';
import {AwardListComponent} from './pages/admin/award-home/award-list/award-list.component';
import {AwardSettingsComponent} from './pages/admin/award-home/award-settings/award-settings.component';
import {ScholarshipListComponent} from './pages/admin/scholarship-home/scholarship-list/scholarship-list.component';
import {ScholarshipSettingsComponent} from './pages/admin/scholarship-home/scholarship-settings/scholarship-settings.component';
import {AccountHomeComponent} from './pages/admin/account-home/account-home.component';
import {GuardService} from './services/guard.service';
import {LogsComponent} from './pages/admin/logs/logs.component';
import {PoolHomeComponent} from './pages/admin/pool-home/pool-home.component';
import {PoolSettingsComponent} from './pages/admin/pool-home/pool-settings/pool-settings.component';
import {PoolListComponent} from './pages/admin/pool-home/pool-list/pool-list.component';
import {ChangePasswordByRecoveryComponent} from './pages/shared/change-password-by-recovery/change-password-by-recovery.component';
import {EditEmailConfirmComponent} from './pages/publisher/proposal/proposal-create/edit-email-confirm/edit-email-confirm.component';
import {RulesComponent} from './pages/shared/rules/rules.component';
import {RulesUsudeComponent} from './pages/shared/rules-usude/rules-usude.component';
import {ChangePasswordExpiredComponent} from './pages/shared/change-password-expired/change-password-expired.component';
import {ProposalCreateComponent} from './pages/publisher/proposal/proposal-create/proposal-create.component';
import {ProposalListComponent as PublisherProposalListComponent} from './pages/publisher/proposal/proposal-list/proposal-list.component';
import {CoFinancingComponent} from './pages/admin/co-financing/co-financing.component';
import {ProposalViewComponent} from './pages/publisher/proposal/proposal-view/proposal-view.component';
import {AccountActivateComponent} from './pages/shared/account-activate/account-activate.component';
import {ChangeEmailByTokenComponent} from './pages/publisher/options/email-change/change-email-by-token.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [GuardService],
    data: {role: 'unauthorized'},
  },
  {
    path: 'change-password-expired',
    component: ChangePasswordExpiredComponent,
    canActivate: [GuardService],
  },
  {
    path: 'rules',
    component: RulesComponent,
    canActivate: [GuardService],
    data: {role: 'unauthorized'},
  },
  {
    path: 'rules-usude',
    component: RulesUsudeComponent,
    canActivate: [GuardService],
    data: {role: 'unauthorized'},
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [GuardService],
    data: {role: 'unauthorized'},
  },
  {
    path: 'account-activate/:activationKey',
    component: AccountActivateComponent,
    canActivate: [GuardService],
    data: {role: 'unauthorized'},
  },
  {
    path: 'account-recovery',
    component: AccountRecoveryComponent,
    canActivate: [GuardService],
    data: {role: 'unauthorized'},
  },
  {
    path: 'recovery-password/:hash',
    component: ChangePasswordByRecoveryComponent,
    canActivate: [GuardService],
    data: {role: 'unauthorized'},
  },
  {
    path: 'publisher',
    component: PublisherComponent,
    canActivate: [GuardService],
    data: {role: 'user'},
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'proposal/create',
        component: ProposalCreateComponent,
      },
      {
        path: 'proposal/edit-email/:id',
        component: EditEmailComponent,
      },
      {
        path: 'proposal/view/:id',
        component: ProposalViewComponent,
      },
      {
        path: 'proposal/edit-email/confirm/:hash',
        component: EditEmailConfirmComponent,
      },
      {
        path: 'proposals',
        component: PublisherProposalListComponent,
      },
      {
        path: 'options',
        component: OptionsComponent,
      },
      {
        path: 'options/email-change/:hash',
        component: ChangeEmailByTokenComponent,
      }
    ]},
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [GuardService],
    data: {role: 'admin'},
    children: [
      {
        path: 'dashboard',
        component: AdminDashboardComponent
      },
      {
        path: 'account-manage',
        component: AccountHomeComponent
      },
      {
        path: 'logs',
        component: LogsComponent
      },
      {
        path: 'co-financing',
        component: CoFinancingComponent,
        children: [
          {
            path: 'pool',
            component: PoolHomeComponent,
            children: [
              {
                path: '',
                component: PoolListComponent,
              },
              {
                path: 'settings',
                component: PoolSettingsComponent,
              },
            ]
          },
          {
            path: 'proposal',
            component: ProposalHomeComponent,
            children: [
              {
                path: '',
                component: AdminProposalListComponent
              },
              {
                path: 'settings',
                component: ProposalSettingsComponent
              }
            ]
          }
        ]
      },
      {
        path: 'scholarship',
        component: ScholarshipHomeComponent,
        children: [
          {
            path: '',
            component: ScholarshipListComponent
          },
          {
            path: 'settings',
            component: ScholarshipSettingsComponent
          }
        ]
      },
      {
        path: 'award',
        component: AwardHomeComponent,
        children: [
          {
            path: '',
            component: AwardListComponent
          },
          {
            path: 'settings',
            component: AwardSettingsComponent
          }
        ]
      },
    ]
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
