import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

export class UniquePublisherEmailValidator implements AsyncValidator {

  constructor(private apiService: ApiService) {}

  validate = (control: AbstractControl): Observable<ValidationErrors|null> =>  {
    return this.apiService.postPublisherEmailAvailability(control.value).pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      map(() => {
        return null;
      }),
      catchError((err) => {
        if (err.error.validationErrors.email) {
          return of({ nonUniqueEmail: true });
        }
        return of({ noConnection: true });
      })
    );
  }
}
