import {HostListener, Injectable} from '@angular/core';

@Injectable()
export class RwdService {

  public isSmall(width) {
    return width < 640;
  }

  public isMedium(width) {
    return !(width < 640 && width >= 768);
  }

  public isLarge(width) {
    return !(width < 768 && width >= 1024);
  }

  public isExtraLarge(width) {
    return !(width < 1024 && width >= 1280);
  }
}
