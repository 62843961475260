import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AccountHomeService} from './account-home.service';
import {AccountManageComponent} from './account-manage/account-manage.component';
import {MatDialog} from '@angular/material/dialog';
import { AccountCreateComponent } from './account-create/account-create.component';

@Component({
  selector: 'app-account-home',
  templateUrl: './account-home.component.html',
  styleUrls: ['./account-home.component.scss']
})
export class AccountHomeComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'username', 'email', 'isDeactivated', 'options'];
  public users: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    public accountHomeService: AccountHomeService,
  ) {
  }

  ngOnInit(): void {
    this.accountHomeService.refreshUsersList();
    this.accountHomeService.users.subscribe(data => {
      this.users = new MatTableDataSource(data);
      this.users.paginator = this.paginator;
      this.users.sort = this.sort;
    });
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();

    if (this.users.paginator) {
      this.users.paginator.firstPage();
    }
  }

  public openDialog(id: number) {
    const dialogRef = this.dialog.open(AccountManageComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '550px',
      panelClass: 'block-panel',
      data: {
        accountId: id
      },
    });
  }

  public openCreateUserDialog() {
    const dialogRef = this.dialog.open(AccountCreateComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '550px',
      panelClass: 'block-panel',
      data: {
        accountId: 1
      },
    });
  }
}
