import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ScholarshipManageComponent} from '../scholarship-manage/scholarship-manage.component';
import {ScholarshipUploadComponent} from '../scholarship-upload/scholarship-upload.component';
import {ScholarshipListService} from './scholarship-list.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-scholarship-list',
  templateUrl: './scholarship-list.component.html',
  styleUrls: ['./scholarship-list.component.scss']
})
export class ScholarshipListComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'name', 'user', 'status', 'createdAt', 'options'];
  public scholarships;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    public scholarshipAgreementService: ScholarshipListService,
  ) {
  }

  ngOnInit(): void {
    this.scholarshipAgreementService.refreshScholarshipList();
    this.scholarshipAgreementService.scholarships.subscribe(data => {
      this.scholarships = new MatTableDataSource(data);
      this.scholarships.paginator = this.paginator;
      this.scholarships.sort = this.sort;
    });
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.scholarships.filter = filterValue.trim().toLowerCase();

    if (this.scholarships.paginator) {
      this.scholarships.paginator.firstPage();
    }
  }

  public openDialog(id: number) {
    const dialogRef = this.dialog.open(ScholarshipManageComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '650px',
      panelClass: 'block-panel',
      data: {
        scholarshipId: id
      },
    });
  }

  public createNewScholarshipAgreement() {
    const dialogRef = this.dialog.open(ScholarshipUploadComponent, {
      height: '800px',
      maxHeight: '80%',
      width: '1200px',
      maxWidth: '90vh'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
