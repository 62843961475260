import {AbstractControl, ValidatorFn} from '@angular/forms';

export function NipValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value === '' || control.value == null) {
      return null;
    }
    const regNumberOnly = new RegExp('^[-0-9]*$');

    if (control.errors && !control.errors.validateNip) {
      return null;
    }
    let nip = control.value;
    if (nip.length !== 10) {
      return {validateNip: {value: control.value}};
    }
    nip = nip.replace(/[\ \-]/gi, '');
    const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    // tslint:disable-next-line:radix
    const controlNumber = parseInt(nip.substring(9, 10));
    const weightCount = weight.length;

    for (let i = 0; i < weightCount; i++) {
      // tslint:disable-next-line:radix
      sum += (parseInt(nip.substr(i, 1)) * weight[i]);
    }

    if (sum % 11 !== controlNumber || !regNumberOnly.test(control.value)) {
      return {validateNip: {value: control.value}};
    }

    return null;
  };
}
