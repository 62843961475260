import {Component, Input, OnInit} from '@angular/core';
import {FileDownloaderService} from "../../../../components/admin/file-downloader/file-downloader.service";
import {DateService} from "../../../../services/date.service";

@Component({
  selector: 'app-scholarship-preview',
  templateUrl: './scholarship-preview.component.html',
  styleUrls: ['./scholarship-preview.component.scss']
})
export class ScholarshipPreviewComponent implements OnInit {
  @Input() public scholarship

  constructor(
    public fileDownloader: FileDownloaderService,
    public dateService: DateService,
  ) {
  }

  ngOnInit(): void {
  }

  public downloadFile(file) {
    this.fileDownloader.addNewFileToDownload(file);
  }
}
