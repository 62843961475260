import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdminComponent} from './admin.component';
import {AdminSidenavComponent} from '../../components/admin/admin-sidenav/admin-sidenav.component';
import {ScholarshipHomeComponent} from './scholarship-home/scholarship-home.component';
import {ScholarshipListComponent} from './scholarship-home/scholarship-list/scholarship-list.component';
import {ScholarshipSettingsComponent} from './scholarship-home/scholarship-settings/scholarship-settings.component';
import {ProposalHomeComponent} from './proposal-home/proposal-home.component';
import {ProposalSettingsComponent} from './proposal-home/proposal-settings/proposal-settings.component';
import {ProposalListComponent} from './proposal-home/proposal-list/proposal-list.component';
import {AwardHomeComponent} from './award-home/award-home.component';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {AwardListComponent} from './award-home/award-list/award-list.component';
import {AwardSettingsComponent} from './award-home/award-settings/award-settings.component';
import {AwardManageComponent} from './award-home/award-manage/award-manage.component';
import {ProposalManageComponent} from './proposal-home/proposal-manage/proposal-manage.component';
import {ProposalListService} from './proposal-home/proposal-list/proposal-list.service';
import {AwardUploadComponent} from './award-home/award-upload/award-upload.component';
import {AwardPreviewComponent} from './award-home/award-preview/award-preview.component';
import {AccountHomeComponent} from './account-home/account-home.component';
import {AccountManageComponent} from './account-home/account-manage/account-manage.component';
import {AccountPreviewComponent} from './account-home/account-preview/account-preview.component';
import {AccountUploadComponent} from './account-home/account-upload/account-upload.component';
import {ScholarshipManageComponent} from './scholarship-home/scholarship-manage/scholarship-manage.component';
import {ScholarshipUploadComponent} from './scholarship-home/scholarship-upload/scholarship-upload.component';
import {ScholarshipPreviewComponent} from './scholarship-home/scholarship-preview/scholarship-preview.component';
import {FileDownloaderComponent} from '../../components/admin/file-downloader/file-downloader.component';
import {LogsComponent} from './logs/logs.component';
import {AwardSettlementsComponent} from './award-home/award-settlements/award-settlements.component';
import {AwardAcceptComponent} from './award-home/award-accept/award-accept.component';
import {ScholarshipSettlementsComponent} from './scholarship-home/scholarship-settlements/scholarship-settlements.component';
import {ScholarshipAcceptComponent} from './scholarship-home/scholarship-accept/scholarship-accept.component';
import {ProposalUploadComponent} from './proposal-home/proposal-upload/proposal-upload.component';
import {PoolHomeComponent} from './pool-home/pool-home.component';
import {PoolSettingsComponent} from './pool-home/pool-settings/pool-settings.component';
import {PoolListComponent} from './pool-home/pool-list/pool-list.component';
import {PoolManageComponent} from './pool-home/pool-manage/pool-manage.component';
import {PoolUploadComponent} from './pool-home/pool-upload/pool-upload.component';
import {PoolPreviewComponent} from './pool-home/pool-preview/pool-preview.component';
import {PoolAcceptComponent} from './pool-home/pool-accept/pool-accept.component';
import {ProposalSettlementsComponent} from './proposal-home/proposal-settlements/proposal-settlements.component';
import {ProposalAcceptComponent} from './proposal-home/proposal-accept/proposal-accept.component';
import {ProposalInformationComponent} from './proposal-home/proposal-information/proposal-information.component';
import {ProposalPreviewComponent} from './proposal-home/proposal-preview/proposal-preview.component';

import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatBadgeModule} from '@angular/material/badge';
import {AppRoutingModule} from '../../app-routing.module';

import {ScholarshipListService} from './scholarship-home/scholarship-list/scholarship-list.service';
import {ScholarshipManageService} from './scholarship-home/scholarship-manage/scholarship-manage.service';
import {FileDownloaderService} from '../../components/admin/file-downloader/file-downloader.service';
import {AwardManageService} from './award-home/award-manage/award-manage.service';
import {AwardListService} from './award-home/award-list/award-list.service';
import {AccountHomeService} from './account-home/account-home.service';
import {AccountManageService} from './account-home/account-manage/account-manage.service';
import {PoolManageService} from './pool-home/pool-manage/pool-manage.service';
import {PoolListService} from './pool-home/pool-list/pool-list.service';
import {ProposalManageService} from './proposal-home/proposal-manage/proposal-manage.service';
import {EditEmailComponent} from '../publisher/proposal/proposal-create/edit-email/edit-email.component';
import {EditEmailConfirmComponent} from '../publisher/proposal/proposal-create/edit-email-confirm/edit-email-confirm.component';
import { AccountCreateComponent } from './account-home/account-create/account-create.component';
import {SharedModule} from '../../shared.module';
import {CoFinancingComponent} from './co-financing/co-financing.component';
import {ProposalPublicationComponent} from './proposal-home/proposal-preview/publication/proposal-publication.component';

@NgModule({
  declarations: [
    AdminComponent,
    AdminSidenavComponent,
    ScholarshipHomeComponent,
    ScholarshipListComponent,
    ScholarshipSettingsComponent,
    EditEmailComponent,
    EditEmailConfirmComponent,
    ProposalHomeComponent,
    ProposalListComponent,
    ProposalSettingsComponent,
    AwardHomeComponent,
    AdminDashboardComponent,
    AwardListComponent,
    AwardSettingsComponent,
    AwardManageComponent,
    ProposalManageComponent,
    ProposalUploadComponent,
    AwardUploadComponent,
    AwardPreviewComponent,
    AccountCreateComponent,
    AccountHomeComponent,
    AccountManageComponent,
    AccountPreviewComponent,
    AccountUploadComponent,
    ScholarshipManageComponent,
    ScholarshipUploadComponent,
    ScholarshipPreviewComponent,
    FileDownloaderComponent,
    LogsComponent,
    AwardSettlementsComponent,
    AwardAcceptComponent,
    ScholarshipSettlementsComponent,
    ScholarshipAcceptComponent,
    CoFinancingComponent,
    PoolHomeComponent,
    PoolSettingsComponent,
    PoolListComponent,
    PoolUploadComponent,
    PoolManageComponent,
    PoolPreviewComponent,
    PoolAcceptComponent,
    ProposalSettlementsComponent,
    ProposalAcceptComponent,
    ProposalInformationComponent,
    ProposalPublicationComponent,
    ProposalPreviewComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MatCardModule,
    MatGridListModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatListModule,
    MatStepperModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatDialogModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatBadgeModule,
    SharedModule,
  ],
  providers: [
    ScholarshipListService,
    ScholarshipManageService,
    FileDownloaderService,
    AwardManageService,
    AwardListService,
    ProposalListService,
    AccountHomeService,
    AccountManageService,
    PoolManageService,
    PoolListService,
    ProposalManageService,
  ]
})
export class AdminModule {
}
