import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';

@Injectable()
export class GuardService {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, activatedRoute: ActivatedRoute): boolean {
    if (route.data.role == 'unauthorized' && !this.authService.isTokenSet()) {
      return true;
    }

    if (route.routeConfig.path === 'change-password-expired') {
      return true;
    }
    if (!this.authService.getExpiredPassword()) {
      if (route.data.role === 'admin' && this.isAdmin()) {
        return true;
      }

      if (route.data.role === 'user' && !this.isAdmin()) {
        return true;
      }
      return this.redirectToRoot();
    } else {
      return this.redirectToChangePassword();
    }
  }

  public hasPermission(permission) {
    if (!this.authService.isTokenSet()) {
      return false;
    }

    return this.authService.getDecryptedToken().hasPermissions.includes(permission);
  }

  private isAdmin() {
    if (!this.authService.isTokenSet()) {
      return false;
    }

    return this.authService.getDecryptedToken().hasPermissions.length !== 0;
  }

  private redirectToRoot() {
    this.router.navigateByUrl('');

    return false;
  }

  private redirectToChangePassword() {
    this.router.navigateByUrl('change-password-expired');

    return false;
  }
}

