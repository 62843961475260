import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountRecoveryModel} from '../../../models/requests/account/account-recovery.model';
import {RecoveryPasswordModel} from '../../../models/requests/account/recovery-password.model';
import {ApiService} from '../../../services/api.service';
import {SweetalertService} from '../../../services/sweetalert.service';
import {ErrorService} from '../../../services/error.service';
import {RepeatedPasswordValidator} from '../../../validators/repetedPasswordValidator';
import {StrengthPasswordValidator} from '../../../validators/strengthPasswordValidator';

@Component({
  selector: 'app-change-password-by-recovery',
  templateUrl: './change-password-by-recovery.component.html',
  styleUrls: ['./change-password-by-recovery.component.scss'],
  providers: [RepeatedPasswordValidator, StrengthPasswordValidator]
})
export class ChangePasswordByRecoveryComponent implements OnInit {

  private routeSub: Subscription;
  public isWaiting = false;
  public hash;

  public recoveryPasswordForm: FormGroup = this.formBuilder.group({
    password: [null, [Validators.required],],
    repeatPassword: [null, Validators.required]
  }, {
    validator: [
      StrengthPasswordValidator.isStrength('password'),
      RepeatedPasswordValidator.mustMatch('password', 'repeatPassword'),
    ]
  });

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.hash = params.hash;
    });
  }

  public onFormSubmit(recoveryPasswordForm) {
    const recoveryPasswordModel: RecoveryPasswordModel = recoveryPasswordForm.value;
    recoveryPasswordModel.hash = this.hash;

    this.isWaiting = true;

    if (!recoveryPasswordForm.valid) {
      this.isWaiting = false;
      this.sweetalertService.registerFormError();
      return;
    }

    this.apiService.changePasswordByRecovery(recoveryPasswordModel).subscribe((response: any) => {
      this.isWaiting = false;
      this.sweetalertService.recoveryPasswordSuccess();
      this.router.navigate(['']);
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
      const passwordErr = error.error.validationErrors.password;
      if (typeof passwordErr !== 'undefined') {
        this.sweetalertService.formError(passwordErr[0]);
      }
    });
  }

}
