import {Injectable} from "@angular/core";
import {ApiService} from "../../../../services/api.service";
import {SweetalertService} from "../../../../services/sweetalert.service";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorService} from '../../../../services/error.service';

@Injectable()
export class AwardListService {
  public status;
  public isLoading;
  private observer: any = new Subject();
  public awards = this.observer.asObservable();

  constructor(
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private router: Router,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.url.startsWith('/admin/award')) {
        this.status = params['status'];
        this.refreshAwardList(params['status']);
      }
    });
  }

  public refreshAwardList(awardStatus = this.status) {
    this.isLoading = true;
    this.apiService.getAwards({awardStatus: awardStatus}).subscribe((response: any) => {
      this.isLoading = false;
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    })
  }
}
