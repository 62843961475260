import {Component, Inject, Input, OnInit} from '@angular/core';
import {ScholarshipStatusModel} from '../../../../models/requests/scholarship/scholarship-status.model';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {DateService} from '../../../../services/date.service';
import {ErrorService} from '../../../../services/error.service';
import {ScholarshipListService} from "../scholarship-list/scholarship-list.service";
import {SCHOLARSHIP_STATUS} from "../../../../enums/scholarship-status.enum";

@Component({
  selector: 'app-scholarship-accept',
  templateUrl: './scholarship-accept.component.html',
  styleUrls: ['./scholarship-accept.component.scss']
})
export class ScholarshipAcceptComponent implements OnInit {

  public isLoading = false;
  public SCHOLARSHIP_STATUS: typeof SCHOLARSHIP_STATUS = SCHOLARSHIP_STATUS;
  public disabled;
  public scholarshipStatus: ScholarshipStatusModel = {
    scholarship: null,
    message: ''
  };
  @Input() public scholarship;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    public dateService: DateService,
    public scholarshipListService: ScholarshipListService,
  ) { }

  ngOnInit(): void {
  }

  public accept() {
    const requestScholarshipStatus: ScholarshipStatusModel = {
      scholarship: this.data.scholarshipId,
      message: this.scholarshipStatus.message
    };

    this.disabled = true;

    if (this.scholarshipStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.acceptScholarship(requestScholarshipStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.scholarshipStatus.message = null;
      this.scholarshipListService.refreshScholarshipList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public decline() {
    const requestScholarshipStatus: ScholarshipStatusModel = {
      scholarship: this.data.scholarshipId,
      message: this.scholarshipStatus.message
    };

    this.disabled = true;

    if (this.scholarshipStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.declineScholarship(requestScholarshipStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.scholarshipStatus.message = null;
      this.scholarshipListService.refreshScholarshipList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

}
