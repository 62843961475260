import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {
  constructor(private router: Router) {
  }

  public login(user) {
    localStorage.setItem('token', user.token);
    localStorage.setItem('email', user.email);
    localStorage.setItem('expiredPassword', user.passwordExpired);

    if (user.hasPermissions.length !== 0) {
      this.router.navigate(['/admin/dashboard']);
    } else {
      this.router.navigate(['/publisher/dashboard']);
    }
  }

  public logout() {
    localStorage.removeItem('token');
    this.router.navigate(['']);
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  public isTokenSet() {
    return localStorage.getItem('token') !== null;
  }

  public getExpiredPassword() {
    return localStorage.getItem('expiredPassword') === 'true';
  }

  public setPasswordChanged() {
    localStorage.setItem('expiredPassword', String(false));
  }

  public getDecryptedToken() {
    if (!this.isTokenSet()) {
      return false;
    }

    return JSON.parse(atob(this.getToken().split('.')[1]));
  }
}
