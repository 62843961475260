<div class="deactivate">
  <mat-card-title>
    Dezaktywuj konto
  </mat-card-title>
  <span *ngIf="!startProcess">
  Uwaga! Ten proces jest nieodwracalny. Czy na pewno chcesz to zrobić?
</span>
  <button mat-raised-button color="warn" *ngIf="!startProcess" (click)="startProcessDeactivation()"> {{ buttonText }}</button>
  <form [formGroup]="accountDeactivationForm" (ngSubmit)="deactivateAccount(accountDeactivationForm)" *ngIf="startProcess">
    <mat-form-field class="login-card__input">
      <mat-label> Hasło</mat-label>
      <input matInput type="password" placeholder="Hasło" aria-label="password" formControlName="password">
      <mat-hint *ngIf="accountDeactivationForm?.controls.password?.touched && accountDeactivationForm?.controls.password?.errors" [class.error]="true">
        Potwierdzenie hasłem jest wymagane do deaktywacji konta
      </mat-hint>
    </mat-form-field>
    <button mat-raised-button color="warn" type="submit" [disabled]="isWaiting"> {{ buttonText }}</button>
  </form>
</div>
