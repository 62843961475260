import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-proposal-summary-publication',
  templateUrl: './proposal-publication.component.html',
  styleUrls: ['./proposal-publication.component.scss']
})
export class ProposalPublicationComponent {
  @Input() public publicationNumber: number;
  @Input() public publishing: any;

}
