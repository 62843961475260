<div class="wrapper">
  <div *ngIf="account" class="account-manage">
    <div class="account-manage__menu">
      <mat-card (click)="setActiveSection(1)"
                [class]="activeSection === 1 ? 'card--active': 'card'"> Podgląd
      </mat-card>
      <mat-card (click)="setActiveSection(2)"
                [class]="activeSection === 2 ? 'card--active': 'card'"> Edycja
      </mat-card>
      <mat-card (click)="setActiveSection(3)"
                [class]="activeSection === 3 ? 'card--active': 'card'"> Logi
      </mat-card>
      <mat-card (click)="deleteAccount(account.id)" class="card--danger"> Usuń</mat-card>
    </div>
    <mat-divider></mat-divider>
    <section *ngIf="activeSection === 1">
      <app-account-preview [account]="account"></app-account-preview>
    </section>
    <section *ngIf="activeSection === 2">
      <app-account-upload [account]="account"></app-account-upload>
    </section>
    <section *ngIf="activeSection === 3">
      <app-logs [options]="{simplified: true, type: account.id}"></app-logs>
    </section>
  </div>
</div>
<div *ngIf="!account" class="account-manage__loader">
  <mat-spinner></mat-spinner>
</div>
