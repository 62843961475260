import {Component, Inject, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {DateService} from '../../../../services/date.service';
import {AwardManageService} from '../award-manage/award-manage.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AwardStatusModel} from '../../../../models/requests/award/award-status.model';
import {ErrorService} from '../../../../services/error.service';
import {AwardListService} from '../award-list/award-list.service';
import {AWARD_STATUS} from '../../../../enums/award-status.enum';

@Component({
  selector: 'app-award-accept',
  templateUrl: './award-accept.component.html',
  styleUrls: ['./award-accept.component.scss']
})
export class AwardAcceptComponent implements OnInit {

  public isLoading = false;
  public AWARD_STATUS: typeof AWARD_STATUS = AWARD_STATUS;
  public disabled;
  public awardStatus: AwardStatusModel = {
    award: null,
    message: ''
  };
  @Input() public award;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    public dateService: DateService,
    private errorService: ErrorService,
    public awardManageService: AwardManageService,
    public awardListService: AwardListService,
  ) {
  }

  ngOnInit(): void {
  }

  public accept() {
    const requestAwardStatus: AwardStatusModel = {
      award: this.data.awardId,
      message: this.awardStatus.message
    };

    this.disabled = true;

    if (this.awardStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.acceptAward(requestAwardStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.awardStatus.message = null;
      this.awardListService.refreshAwardList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

  public decline() {
    const requestAwardStatus: AwardStatusModel = {
      award: this.data.awardId,
      message: this.awardStatus.message
    };

    this.disabled = true;

    if (this.awardStatus.message.length < 3) {
      this.sweetalertService.formError('Pole z wiadomością musi zawierać minimum 3 znaki.');
    }

    this.apiService.declineAward(requestAwardStatus).subscribe((response: any) => {
      this.sweetalertService.success();
      this.disabled = false;
      this.awardStatus.message = null;
      this.awardListService.refreshAwardList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }

}
