import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RepeatedPasswordValidator} from '../../../../validators/repetedPasswordValidator';
import {StrengthPasswordValidator} from '../../../../validators/strengthPasswordValidator';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ApiService} from '../../../../services/api.service';
import {ErrorService} from '../../../../services/error.service';
import {ChangePasswordModel} from '../../../../models/requests/account/change-password.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [RepeatedPasswordValidator, StrengthPasswordValidator]
})
export class ChangePasswordComponent {

  public isWaiting = false;
  public changePasswordForm: FormGroup = this.formBuilder.group({
    oldPassword: [null, Validators.required],
    newPassword: [null, [Validators.required]],
    passwordRepeat: [null, Validators.required],
  }, {
    validator: [
      StrengthPasswordValidator.isStrength('newPassword'),
      RepeatedPasswordValidator.mustMatch('newPassword', 'passwordRepeat'),
    ]
  });

  constructor(
    public formBuilder: FormBuilder,
    private sweetalertService: SweetalertService,
    private apiService: ApiService,
    private errorService: ErrorService,
  ) { }

  public changePassword(changePasswordForm) {
    const changePasswordModel: ChangePasswordModel = changePasswordForm.value;

    if (!changePasswordForm.valid) {
      this.isWaiting = false;
      this.sweetalertService.registerFormError();
      return;
    }

    this.isWaiting = true;

    this.apiService.changePassword(changePasswordModel).subscribe((response: any) => {
      this.isWaiting = false;
      if (response.status === 200) {
        this.sweetalertService.changePasswordSuccess();
        changePasswordForm.reset();
        Object.keys(changePasswordForm.controls).forEach(key => {
          changePasswordForm.controls[key].setErrors(null);
        });
      }
    }, error => {
      this.isWaiting = false;
      this.errorService.handleError(error);
      const passwordErr = error.error.validationErrors.newPassword;
      if (typeof passwordErr !== 'undefined') {
        this.sweetalertService.formError(passwordErr[0]);
      }
    });
  }
}
