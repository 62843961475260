<mat-card-title>
  Zmiana hasła
</mat-card-title>
<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword(changePasswordForm)">
  <mat-form-field class="login-card__input">
    <mat-label> Stare hasło</mat-label>
    <input matInput type="password" aria-label="old-password" formControlName="oldPassword">
  </mat-form-field>
  <mat-form-field class="login-card__input">
    <mat-label> Nowe hasło</mat-label>
    <input matInput type="password" aria-label="password" formControlName="newPassword">
    <mat-hint *ngIf="changePasswordForm?.controls.newPassword?.touched && changePasswordForm?.controls.newPassword?.errors" [class.error]="true">
      Hasło powinno mieć conajmniej 8 znaków w tym minimum jedną wielką literę, jedną cyfrę i jeden znak specjalny
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="login-card__input">
    <mat-label> Powtórz nowe hasło</mat-label>
    <input matInput type="password" aria-label="password-repeat" formControlName="passwordRepeat">
    <mat-hint *ngIf="changePasswordForm?.controls.passwordRepeat?.touched && changePasswordForm?.controls.passwordRepeat?.errors" [class.error]="true">
      Hasła różnią się od siebie
    </mat-hint>
  </mat-form-field>
  <button mat-raised-button color="primary" type="submit"> Zapisz nowe hasło</button>
</form>
