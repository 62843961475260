<div class="award-preview">
  <div class="award-preview__name"> {{ award.name }}</div>
  <mat-list role="list">
    <mat-list-item role="listitem"><b> Utworzono:</b> {{ dateService.prepareDate(award.createdAt) }}</mat-list-item>
    <mat-list-item role="listitem"><b> Ostatnia aktualizacja:</b> {{ dateService.prepareDate(award.updatedAt) }}
    </mat-list-item>
  </mat-list>
  <div class="files-card-wrapper">
    <mat-card *ngFor="let file of award.files" class="file-card">
      <div class="file-card__content">
        <div class="title">
          {{ file.name }}
        </div>
        <div class="sub-title">
          Utworzono {{ dateService.prepareDate(file.createdAt) }}
        </div>
      </div>
      <button
        (click)="downloadFile(file)" *ngIf="!fileDownloader.isDownloading(file)"
        color="primary" mat-mini-fab>
        <mat-icon> get_app</mat-icon>
      </button>
      <button
        *ngIf="fileDownloader.isDownloading(file)" class="admin-sidenav-component__button"
        color="primary"
        mat-mini-fab>
        <mat-icon> hourglass_bottom</mat-icon>
      </button>
    </mat-card>
    <mat-card *ngIf="award.files.length === 0">
      <h2> Brak plików</h2>
    </mat-card>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Akceptacje</h2>
  <div class="accept-card__wrapper">
    <div *ngIf="award.awardStatuses.length === 0">
      Nikt jeszcze nie zaakceptował tej nagrody.
    </div>
    <ng-container *ngFor="let action of this.award.awardStatuses">
      <mat-card [class.accept-card--success]="action.isAccepted"
                [class.accept-card--warn]="!action.isAccepted" class="accept-card" *ngIf="action.isActive">
        <mat-card-title *ngIf="action.isAccepted"> Zaakceptowane</mat-card-title>
        <mat-card-title *ngIf="!action.isAccepted"> Odrzucone</mat-card-title>
        <mat-card-subtitle> Przez {{ action.user.username }}
          dnia {{ dateService.prepareFullDate(action.createdAt) }}</mat-card-subtitle>
        <mat-card-content>
          {{ action.message }}
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Rozliczenia</h2>
  <div class="settlement-card__wrapper">
    <div *ngIf="!award.awardSettlement">
      Jeszcze nie rozliczono tej nagrody.
    </div>
    <div *ngIf="award.awardSettlement">
      <mat-list role="list">
        <mat-list-item *ngIf="award.awardSettlement.isCorrect" role="listitem"> Rozliczono poprawnie</mat-list-item>
        <mat-list-item *ngIf="!award.awardSettlement.isCorrect" role="listitem"> Rozliczono niepoprawnie</mat-list-item>
        <mat-list-item role="listitem"><b> Komentarz:</b> {{ award.awardSettlement.comment }} </mat-list-item>
      </mat-list>
      <div class="files-card-wrapper">
        <mat-card *ngFor="let file of award.awardSettlement.files" class="file-card">
          <div class="file-card__content">
            <div class="title">
              {{ file.name }}
            </div>
            <div class="sub-title">
              Utworzono {{ dateService.prepareDate(file.createdAt) }}
            </div>
          </div>
          <button
            (click)="downloadFile(file)" *ngIf="!fileDownloader.isDownloading(file)"
            color="primary" mat-mini-fab>
            <mat-icon> get_app</mat-icon>
          </button>
          <button
            *ngIf="fileDownloader.isDownloading(file)" class="admin-sidenav-component__button"
            color="primary"
            mat-mini-fab>
            <mat-icon> hourglass_bottom</mat-icon>
          </button>
        </mat-card>
        <mat-card *ngIf="award.files.length === 0">
          <h2> Brak plików</h2>
        </mat-card>
      </div>
    </div>
  </div>
</div>
