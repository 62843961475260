import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AwardManageService} from './award-manage.service';
import {AwardListService} from '../award-list/award-list.service';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-award-manage',
  templateUrl: './award-manage.component.html',
  styleUrls: ['./award-manage.component.scss']
})
export class AwardManageComponent implements OnInit {
  public activeSection = 1;
  public award;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private dialogRef: MatDialog,
    private awardManageService: AwardManageService,
    private awardAgreementService: AwardListService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.awardManageService.updateAward(this.data.awardId);
    this.awardManageService.award.subscribe(data => {
      this.award = data;
    });
  }

  public setActiveSection(section: number) {
    this.activeSection = section;
  }

  public deleteAward(id: number) {
    this.apiService.deleteAward(id).subscribe((response: any) => {
      this.awardAgreementService.refreshAwardList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    });
  }
}
