<div class="proposals">
  <div [hidden]="isLoading">
    <div class="mb mat-elevation-z8">
    <table [dataSource]="proposals" class="mat-elevation-z8 proposals__table" mat-table matSort matSortActive="id" matSortDirection="desc">
      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-header-cell class="id-column" mat-sort-header> ID</th>
        <td *matCellDef="let element" mat-cell> {{ element.id }} </td>
      </ng-container>
      <ng-container matColumnDef="pool">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Pula</th>
        <td *matCellDef="let element" mat-cell> {{ element.pool.name }} </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Kategoria</th>
        <td *matCellDef="let element" mat-cell> {{ element.poolPublicationType.name }} </td>
      </ng-container>
      <ng-container matColumnDef="publicationName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Tytuł publikacji</th>
        <td *matCellDef="let element" mat-cell> {{ element.publishTitle }} </td>
      </ng-container>
      <ng-container matColumnDef="isbn">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> ISBN</th>
        <td *matCellDef="let element" mat-cell> {{ element.isbn }} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> Status</th>
        <td *matCellDef="let element" mat-cell> {{ element.status | proposalStatusToText }} </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th *matHeaderCellDef mat-header-cell class="options-column"> Opcje</th>
        <td *matCellDef="let element" mat-cell>
          <div class="actions-container">
            <button [routerLink]="'/publisher/proposal/edit-email/'+element.id" mat-mini-fab title="Zmień e-mail">
              <mat-icon>alternate_email</mat-icon>
            </button>
            <button [routerLink]="'/publisher/proposal/view/'+element.id" mat-mini-fab title="Podgląd">
              <mat-icon>description</mat-icon>
            </button>
            <button (click)="preview(element.id)" mat-mini-fab title="Pobierz plik z podglądem">
              <mat-icon>download</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <ng-container *ngIf="proposals">
        <mat-card *ngIf="proposals.length === 0" [hidden]="isLoading">
          Brak wyników do wyświetlenia
        </mat-card>
      </ng-container>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
