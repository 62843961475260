import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {AwardSettingsModel} from '../../../../models/requests/award/award-settings.model';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-award-settings',
  templateUrl: './award-settings.component.html',
  styleUrls: ['./award-settings.component.scss']
})
export class AwardSettingsComponent implements OnInit {
  public awardSettings: AwardSettingsModel;
  public isLoading: boolean;

  constructor(
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getAwardSettings().subscribe((response: any) => {
        this.isLoading = false;
        this.awardSettings = response.data[0];
      },
      error => {
        this.errorService.handleError(error);
      });
  }

  public onFormSubmit() {
    this.apiService.updateAwardSettings(this.awardSettings).subscribe((response: any) => {
        this.sweetalertService.success();
      },
      error => {
        this.errorService.handleError(error);
      });
  }
}
