import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Subject} from 'rxjs';
import {ErrorService} from '../../../../services/error.service';

@Injectable()
export class ProposalManageService {
  private observer = new Subject();
  public proposal = this.observer.asObservable();

  constructor(private apiService: ApiService, private errorService: ErrorService) {
  }

  public updateProposal(id) {
    this.apiService.getProposal(id).subscribe((response: any) => {
      this.observer.next(response.data);
    }, error => {
      this.errorService.handleError(error);
    });
  }
}
