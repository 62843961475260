<div class="proposal-information">
  <div class="proposal-information__name">
    {{ proposal.publishTitle }}
  </div>
  <mat-list role="list">
    <mat-list-item role="listitem">
      <b>ID:</b>
      <span>{{ proposal.id }}</span>
    </mat-list-item>
    <mat-list-item role="listitem">
      <b>Utworzono:</b>
      <span>{{ dateService.prepareDate(proposal.createdAt) }}</span>
    </mat-list-item>
    <mat-list-item role="listitem">
      <b>Ostatnia aktualizacja:</b>
      <span>{{ dateService.prepareDate(proposal.updatedAt) }}</span>
    </mat-list-item>
  </mat-list>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Akceptacje</h2>
  <div class="accept-card__wrapper">
    <div *ngIf="proposal.proposalStatuses.length === 0">
      Nikt jeszcze nie zaakceptował tego wniosku.
    </div>
    <ng-container *ngFor="let action of this.proposal.proposalStatuses">
      <mat-card [class.accept-card--success]="action.isAccepted"
                [class.accept-card--warn]="!action.isAccepted" class="accept-card" *ngIf="action.isActive">
        <mat-card-title *ngIf="action.isAccepted"> Zaakceptowane</mat-card-title>
        <mat-card-title *ngIf="!action.isAccepted"> Odrzucone</mat-card-title>
        <mat-card-subtitle> Przez {{ action.user.username }}
          dnia {{ dateService.prepareFullDate(action.createdAt) }}</mat-card-subtitle>
        <mat-card-content>
          {{ action.message }}
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Rozliczenia</h2>
  <div class="settlement-card__wrapper">
    <div *ngIf="!proposal.proposalSettlement">
      Jeszcze nie rozliczono tego wniosku.
    </div>
    <div *ngIf="proposal.proposalSettlement">
      <mat-list role="list">
        <mat-list-item *ngIf="proposal.proposalSettlement.isCorrect" role="listitem">
          Rozliczono poprawnie
        </mat-list-item>
        <mat-list-item *ngIf="!proposal.proposalSettlement.isCorrect" role="listitem">
          Rozliczono niepoprawnie
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>Komentarz:</b>
          <span>{{ proposal.proposalSettlement.comment }}</span>
        </mat-list-item>
        <mat-list-item role="listitem">
          <b>Data rozliczenia:</b>
          <span>{{ proposal.proposalSettlement.billingDate|date:'yyyy-MM-dd' }}</span>
        </mat-list-item>
      </mat-list>
      <div class="files-card-wrapper">
        <mat-card *ngFor="let file of proposal.proposalSettlement.files" class="file-card">
          <div class="file-card__content">
            <div class="title">
              {{ file.name }}
            </div>
            <div class="sub-title">
              Utworzono {{ dateService.prepareDate(file.createdAt) }}
            </div>
          </div>
          <button (click)="downloadFile(file)" *ngIf="!fileDownloader.isDownloading(file)" color="primary" mat-mini-fab>
            <mat-icon> get_app</mat-icon>
          </button>
          <button
            *ngIf="fileDownloader.isDownloading(file)" class="admin-sidenav-component__button"
            color="primary"
            mat-mini-fab>
            <mat-icon> hourglass_bottom</mat-icon>
          </button>
        </mat-card>
        <mat-card *ngIf="proposal.proposalSettlement.files.length === 0">
          <h2> Brak plików</h2>
        </mat-card>
      </div>
    </div>
  </div>
</div>
