import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {AwardManageService} from '../award-manage/award-manage.service';
import {AwardListService} from '../award-list/award-list.service';
import {AWARD_STATUS} from '../../../../enums/award-status.enum';
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-award-settlements',
  templateUrl: './award-settlements.component.html',
  styleUrls: ['./award-settlements.component.scss']
})
export class AwardSettlementsComponent implements OnInit {
  public fileToUpload: File = null;
  public files = [];
  public AWARD_STATUS: typeof AWARD_STATUS = AWARD_STATUS;
  @Input() public award;
  private isNew;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private errorService: ErrorService,
    private awardManageService: AwardManageService,
    private dialogRef: MatDialog,
    private awardAgreementService: AwardListService,
  ) {
  }

  ngOnInit(): void {
    if (this.award.awardSettlement) {
      this.isNew = false;
      this.files = this.award.awardSettlement.files;
    } else {
      this.isNew = true;
      this.award.awardSettlement = {
        comment: '',
        isCorrect: false,
        files: [],
        award: this.award.id
      };
    }
  }

  public deleteFile(id: number) {
    let filePosition;

    for (let x = 0; x < this.files.length; x++) {
      if (this.files[x].id === id) {
        filePosition = x;
        break;
      }
    }

    this.files.splice(filePosition, 1);
  }

  public uploadFile(files: FileList) {
    this.fileToUpload = files.item(0);

    this.apiService.postFile(this.fileToUpload).subscribe((response: any) => {
      this.files.push(response.data);
      this.fileToUpload = null;
    }, error => {
      this.errorService.handleError(error);
      this.fileToUpload = null;
    });
  }

  public onFormSubmit() {
    this.award.awardSettlement.award = this.award.id;
    this.award.awardSettlement.files = [];
    for (let x = 0; x < this.files.length; x++) {
      this.award.awardSettlement.files.push(this.files[x].id);
    }

    if (this.isNew) {
      this.apiService.postAwardSettlement(this.award.awardSettlement).subscribe((response: any) => {
        this.sweetalertService.success();
        this.awardAgreementService.refreshAwardList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
      });
    } else {
      this.apiService.updateAwardSettlement(this.award.awardSettlement.id, this.award.awardSettlement).subscribe((response: any) => {
        this.sweetalertService.success();
        this.awardAgreementService.refreshAwardList();
        this.dialogRef.closeAll();
      }, error => {
        this.errorService.handleError(error);
      });
    }
  }
}
