<div class="pool-preview">
  <div class="pool-preview__name">{{ pool.name }}</div>
  <mat-list role="list">
    <mat-list-item role="listitem">
      <div class="field-title">Data rozpoczęcia:</div>
      {{ dateService.prepareDate(pool.startDate) }}
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="field-title">Data zakończenia:</div>
      {{ dateService.prepareDate(pool.endDate) }}
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="field-title">Utworzono:</div>
      {{ dateService.prepareDate(pool.createdAt) }}
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="field-title">Ostatnia aktualizacja:</div>
      {{ dateService.prepareDate(pool.updatedAt) }}
    </mat-list-item>
  </mat-list>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Warunki</h2>
  <div class="conditions__wrapper">
    <mat-card *ngFor="let condition of pool.poolConditions" class="mb">
      <mat-card-subtitle>{{ condition.name }}</mat-card-subtitle>
    </mat-card>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Typy publikacji</h2>
  <div class="conditions__wrapper">
    <mat-card *ngFor="let publicationType of pool.poolPublicationTypes" class="mb">
      <mat-card-subtitle>{{ publicationType.name }}</mat-card-subtitle>
    </mat-card>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <h2> Akceptacje</h2>
  <div class="accept-card__wrapper">
    <div *ngIf="pool.poolStatuses.length === 0">
      Nikt jeszcze nie zaakceptował tej puli.
    </div>
    <ng-container *ngFor="let action of this.pool.poolStatuses">
      <mat-card [class.accept-card--success]="action.isAccepted"
                [class.accept-card--warn]="!action.isAccepted" class="accept-card" *ngIf="action.isActive">
        <mat-card-title *ngIf="action.isAccepted">Zaakceptowane</mat-card-title>
        <mat-card-title *ngIf="!action.isAccepted">Odrzucone</mat-card-title>
        <mat-card-subtitle>
          Przez {{ action.user.username }} dnia {{ dateService.prepareFullDate(action.createdAt) }}
        </mat-card-subtitle>
        <mat-card-content>
          {{ action.message }}
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
  <mat-divider class="section-divider"></mat-divider>
</div>
