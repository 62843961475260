import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {SweetalertService} from '../../../../services/sweetalert.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-proposal-upload',
  templateUrl: './proposal-upload.component.html',
  styleUrls: ['./proposal-upload.component.scss']
})
export class ProposalUploadComponent implements OnInit {
  // };
  public fileToUpload: File = null;
  // @Input() public award: AwardModel = {
  //   name: '',
  //   files: []
  private isNew;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private dialogRef: MatDialog,
  ) {
  }

  ngOnInit(): void {
    (this.data) ? this.isNew = false : this.isNew = true;
  }

  // public deleteFile(id: number) {
  //   let filePosition;
  //
  //   for (let x = 0; x < this.award.files.length; x++) {
  //     if (this.award.files[x].id === id) {
  //       filePosition = x;
  //       break;
  //     }
  //   }
  //
  //   this.award.files.splice(filePosition, 1);
  // }
  //
  // public uploadFile(files: FileList) {
  //   this.fileToUpload = files.item(0);
  //
  //   this.apiService.postFile(this.fileToUpload).subscribe((response: any) => {
  //     this.award.files.push(response.data);
  //     this.fileToUpload = null;
  //   }, error => {
  //     this.errorService.handleError(error);
  //     this.fileToUpload = null;
  //   })
  // }
  //
  // public onFormSubmit() {
  //   let requestAward: AwardModel = {
  //     name: '',
  //     files: []
  //   };
  //
  //   requestAward.name = this.award.name
  //   for (let x = 0; x < this.award.files.length; x++) {
  //     requestAward.files[x] = this.award.files[x].id;
  //   }
  //
  //   if (this.isNew) {
  //     this.apiService.postAward(requestAward).subscribe((response: any) => {
  //       this.sweetalertService.success();
  //       this.awardAgreementService.refreshAwardList();
  //       this.dialogRef.closeAll();
  //     }, error => {
  //       this.errorService.handleError(error);
  //     })
  //   } else {
  //     this.apiService.updateAward(this.award.id, requestAward).subscribe((response: any) => {
  //       this.sweetalertService.success();
  //       this.awardAgreementService.refreshAwardList();
  //       this.dialogRef.closeAll();
  //     }, error => {
  //       this.errorService.handleError(error);
  //     })
  //   }
  // }
}
