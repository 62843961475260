<div class="account-activation">
  <img src="assets/images/logo.jpg" alt="logo" class="account-activation__logo">
  <mat-card class="account-activation-card" *ngIf="!isWaiting">
    <mat-card-title class="account-activation-card__title">Aktywowanie konta</mat-card-title>

    <mat-card-footer class="account-activation-card__footer">
      <a routerLink="/login" routerLinkActive="active">Zaloguj się</a>
    </mat-card-footer>
  </mat-card>
</div>
<app-loader [isOpen]="isWaiting"></app-loader>
