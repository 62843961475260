<div class="account-info">
  <mat-card-title>
    Twoje dane
  </mat-card-title>
  <ng-container *ngIf="user !== null; else userLoader">
    <mat-list role="list">
      <mat-list-item role="listitem">
        <b>Nazwa użytkownika:</b> {{user.username}}
      </mat-list-item>
      <mat-list-item role="listitem">
        <b>E-mail:</b> {{user.email}}
      </mat-list-item>
    </mat-list>

    <mat-divider [inset]="true"></mat-divider>

    <button mat-raised-button color="primary" *ngIf="!changingEmail" (click)="startChangingEmail()">
      Zmień adres e-mail
    </button>
    <form [formGroup]="emailChangeForm" (ngSubmit)="changeEmail(emailChangeForm)" *ngIf="changingEmail">
      <div class="email-change-info">
        Na aktualny adres e-mail zostanie wysłany link potwierdzający zmianę adresu e-mail.<br />
        Dopiero po potwierdzeniu zmieni się adres e-mail
      </div>
      <mat-form-field class="login-card__input">
        <mat-label>E-mail</mat-label>
        <input matInput type="email" placeholder="E-mail" aria-label="email" formControlName="email">
        <mat-hint *ngIf="emailChangeForm?.controls.email?.touched && emailChangeForm?.controls.email?.errors" [class.error]="true">
          Wymagane jest wprowadzenie poprawnego adresu e-mail
        </mat-hint>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" [disabled]="isWaiting">
        Wyślij
      </button>
    </form>
  </ng-container>
  <ng-template #userLoader>
    <div class="account-info__loader">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
