<h4>Publikacja {{publicationNumber}}</h4>
<div class="row">
  <app-summary-field name="Tytuły są zgłoszone w PLR">
    {{ publication.is_reported_plr | booleanToString }}
  </app-summary-field>
  <app-summary-field name="Tytuły są zgłoszone umowie powierzenia praw">
    {{ publication.is_reported_sub_contract | booleanToString }}
  </app-summary-field>
  <app-summary-field name="Tytuły są zgłoszone w deklaracji członkostwa">
    {{ publication.is_reported_membership_declaration | booleanToString }}
  </app-summary-field>
  <app-summary-field name="Debiutant bez dorobku literackiego" *ngIf="publication.is_debutant_without_literary_achievements !== undefined">
    {{ publication.is_debutant_without_literary_achievements | booleanToString }}
  </app-summary-field>
</div>
<div class="row">
  <app-summary-field name="Autor">
    {{ publication.author }}
  </app-summary-field>
  <app-summary-field name="Tytuł">
    {{ publication.title }}
  </app-summary-field>
  <app-summary-field name="ISBN">
    {{ publication.isbn }}
  </app-summary-field>
  <app-summary-field name="Wydawca">
    {{ publication.publisher }}
  </app-summary-field>
  <app-summary-field name="Rok wydania">
    {{ publication.publish_date }}
  </app-summary-field>
  <app-summary-field name="Rola w publikacji">
    {{ publication.role_in_publication }}
  </app-summary-field>
</div>
