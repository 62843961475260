import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-summary-field',
  templateUrl: './summary-field.component.html',
  styleUrls: ['./summary-field.component.scss']
})
export class SummaryFieldComponent {
  @Input() public name: string;
}
