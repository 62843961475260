<div class="proposal-home">
  <div class="proposal-home__navbar">
    <mat-card (click)="filterProposals(null, pool)"
              [class]="!router.url.includes('status') && !router.url.includes('settings') ? 'card--active': 'card'"> Wszystkie
    </mat-card>
    <mat-card (click)="filterProposals(PROPOSAL_STATUS.NEW, pool)"
              [class]="router.url.includes('status=' + PROPOSAL_STATUS.NEW) ? 'card--active': 'card'">
      Nowe
    </mat-card>
    <mat-card (click)="filterProposals(PROPOSAL_STATUS.ACCEPTED, pool)"
              [class]="router.url.includes('status=' + PROPOSAL_STATUS.ACCEPTED) ? 'card--active': 'card'">
      Zaakceptowane
    </mat-card>
    <mat-card (click)="filterProposals(PROPOSAL_STATUS.REJECTED, pool)"
              [class]="router.url.includes('status=' + PROPOSAL_STATUS.REJECTED) ? 'card--active': 'card'">
      Odrzucone
    </mat-card>
    <mat-card (click)="filterProposals(PROPOSAL_STATUS.CLOSED, pool)"
              [class]="router.url.includes('status=' + PROPOSAL_STATUS.CLOSED) ? 'card--active': 'card'">
      Zakończone
    </mat-card>
    <mat-card (click)="goToSettings(pool)"
              [class]="router.url.includes('settings') ? 'card--active': 'card'"> Ustawienia
    </mat-card>
  </div>
  <div class="proposal-home__content">
    <router-outlet></router-outlet>
  </div>
</div>
