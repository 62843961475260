<div class="options">
  <mat-card class="options__card">
    <app-change-password></app-change-password>
  </mat-card>
  <mat-card class="options__card">
    <app-deactivate-account></app-deactivate-account>
  </mat-card>
  <mat-card class="options__card">
    <app-account-info></app-account-info>
  </mat-card>
</div>
