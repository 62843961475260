<div class="pool-home">
  <div class="pool-home__navbar">
    <mat-card (click)="filterPools()"
              [class]="router.url === '/admin/co-financing/pool' ? 'card--active': 'card'"> Wszystkie
    </mat-card>
    <mat-card (click)="filterPools(POOL_STATUS.NEW)"
              [class]="router.url === '/admin/co-financing/pool?status=' + POOL_STATUS.NEW ? 'card--active': 'card'"> Nowe
    </mat-card>
    <mat-card (click)="filterPools(POOL_STATUS.ACCEPTED)"
              [class]="router.url === '/admin/co-financing/pool?status=' + POOL_STATUS.ACCEPTED ? 'card--active': 'card'"> Zatwierdzone
    </mat-card>
    <mat-card (click)="filterPools(POOL_STATUS.FINISHED)"
              [class]="router.url === '/admin/co-financing/pool?status=' + POOL_STATUS.FINISHED ? 'card--active': 'card'"> Zakończone
    </mat-card>
    <mat-card (click)="this.router.navigate(['admin/co-financing/pool/settings'])"
              [class]="router.url === '/admin/co-financing/pool/settings' ? 'card--active': 'card'"> Ustawienia
    </mat-card>
  </div>
  <div class="pool-home__content">
    <router-outlet></router-outlet>
  </div>
</div>
