<div class="proposal-settings">
  <h2> Ustawienia dofinansowań</h2>
  <div *ngIf="!isLoading" class="row">
    <mat-card class="proposal-settings__card">
      <h4> Osoby potrzebne do zaakceptowania dofinansowania</h4>
      <mat-slider [(ngModel)]="proposalSettings.neededToAccept" class="proposal-settings__slider" max="10" min="1" step="1"
                  thumbLabel="true">
      </mat-slider>
    </mat-card>
    <mat-card class="proposal-settings__card">
      <h4> Osoby potrzebne do odrzucenia dofinansowania</h4>
      <mat-slider [(ngModel)]="proposalSettings.neededToDecline" class="proposal-settings__slider" max="10" min="1" step="1"
                  thumbLabel="true">
      </mat-slider>
    </mat-card>
  </div>
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row">
    <button (click)="onFormSubmit()" color="primary" mat-raised-button> Zapisz</button>
  </div>
</div>
