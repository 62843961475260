import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-proposal-summary',
  templateUrl: './proposal-summary.component.html',
  styleUrls: ['./proposal-summary.component.scss']
})
export class ProposalSummaryComponent implements OnInit {
  @Input() public stage1: any;
  @Input() public stage2: any;
  @Input() public stage3: any;
  @Input() public stage4: any;
  @Input() public stage5: any;
  @Input() public files: any[];
  @Input() public pools: Observable<any>;

  private poolNames = new Map<number, string>();
  private publicationTypeNames = new Map<string, string>();

  constructor() {
  }

  ngOnInit(): void {
    this.pools.subscribe(pools => {
      pools.forEach(pool => {
        this.poolNames.set(pool.id, pool.name);

        pool.publicationTypes.forEach(publicationType => {
          this.publicationTypeNames.set(pool.id + '-' + publicationType.id, publicationType.name);
        });
      });
    });
  }

  getPoolNameById(poolId: number): string {
    return this.poolNames.get(poolId);
  }

  getPublicationTypeNameById(poolId: number, publicationTypeId?: number): string {
    if (publicationTypeId !== null) {
      return this.publicationTypeNames.get(poolId + '-' + publicationTypeId);
    }
    return publicationTypeId?.toString() || '';
  }

  displayBookForm(value: string): string {
    switch (value) {
      case '1':
        return 'Druk';
      case '2':
        return 'E-book';
      default:
        return value;
    }
  }
}
