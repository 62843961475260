import {Component, Inject, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {SweetalertService} from "../../../../services/sweetalert.service";
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ScholarshipManageService} from "./scholarship-manage.service";
import {ScholarshipListService} from "../scholarship-list/scholarship-list.service";
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-scholarship-manage',
  templateUrl: './scholarship-manage.component.html',
  styleUrls: ['./scholarship-manage.component.scss']
})
export class ScholarshipManageComponent implements OnInit {
  public activeSection = 1;
  public scholarship;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private dialogRef: MatDialog,
    private scholarshipManageService: ScholarshipManageService,
    private scholarshipAgreementService: ScholarshipListService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit(): void {
    this.scholarshipManageService.updateScholarship(this.data.scholarshipId);
    this.scholarshipManageService.scholarship.subscribe(data => {
      this.scholarship = data;
    });
  }

  public setActiveSection(section: number) {
    this.activeSection = section;
  }

  public deleteScholarship(id: number) {
    this.apiService.deleteScholarship(id).subscribe((response: any) => {
      this.scholarshipAgreementService.refreshScholarshipList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    })
  }
}
