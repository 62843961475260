import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../../services/api.service';
import {ErrorService} from '../../../../../services/error.service';
import {SweetalertService} from '../../../../../services/sweetalert.service';
import {AuthService} from '../../../../../services/auth.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-edit-email-confirm',
  templateUrl: './edit-email-confirm.component.html',
  styleUrls: ['./edit-email-confirm.component.scss']
})
export class EditEmailConfirmComponent implements OnInit {

  isLoading = false;
  isWaiting = false;
  hash: string;
  proposal = {
    authors: [],
    publishers: [],
  };
  proposalEmailsModel = [];

  emailForm: FormGroup = this.formBuilder.group({
    emails: this.formBuilder.array([])
  });

  constructor(
    private apiService: ApiService,
    private errorService: ErrorService,
    private sweetalertService: SweetalertService,
    private authService: AuthService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.hash = route.snapshot.paramMap.get('hash');
  }

  ngOnInit(): void {
    this.confirmChangeEmail(this.hash);
  }

  private confirmChangeEmail(hash: string) {
    this.isLoading = true;
    this.apiService.getProposalEmailsRequestConfirm(hash).subscribe((response: any) => {
      this.sweetalertService.success();
      this.router.navigateByUrl('');
    }, error => {
      this.errorService.handleError(error);
      this.router.navigateByUrl('');
    });
  }
}
