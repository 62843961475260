import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AWARD_STATUS} from "../../../enums/award-status.enum";

@Component({
  selector: 'app-award-home',
  templateUrl: './award-home.component.html',
  styleUrls: ['./award-home.component.scss']
})
export class AwardHomeComponent implements OnInit {
  public AWARD_STATUS: typeof AWARD_STATUS = AWARD_STATUS;

  constructor(
    public router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  public filterAwards(status = null) {
    this.router.navigate(['admin/award'], {queryParams: {status: status}});
  }
}
