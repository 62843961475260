import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {FileDownloaderService} from "../../components/admin/file-downloader/file-downloader.service";
import {RwdService} from '../../services/rwd.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public filesQuantity: any = 0;
  public width;
  public isMenuOpen = true;
  public isButtonClicked = false;
  public isMobileResolution;

  constructor(
    private elementRef: ElementRef,
    private fileDownloaderService: FileDownloaderService,
    private rwdService: RwdService,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
    this.isMobileResolution = this.rwdService.isSmall(this.width);

    this.isMenuOpen = !this.isMobileResolution;
  }

  ngOnInit(): void {
    this.isMobileResolution = this.rwdService.isSmall(window.innerWidth);
    this.fileDownloaderService.filesList.subscribe(data => {
      this.filesQuantity = data.length;
    });
  }

  public closeMenu(sidenav) {
    if (this.isMobileResolution) {
      sidenav.toggle();
    }
  }
}
