import {FormGroup, ValidatorFn} from '@angular/forms';

export class MinThreePublisherPublicationValidator {
  static validatePublisher(controlName: string, min: number): ValidatorFn {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      if (
        (control.value.length >= 1 && control.value[0].reportedPlr === true) ||
        (control.value.length >= 2 && control.value[1].reportedPlr === true)
      ) {
        return null;
      }
      if (control.value.length >= min && control.errors && !control.errors.MinLengthArray) {
        return null;
      }

      if (control.value.length < min) {
        control.setErrors({MinLengthArray: true});
      } else {
        control.setErrors(null);
      }

      return null;
    };
  }
}
