import {Component, Input, OnInit} from '@angular/core';
import {DateService} from "../../../../services/date.service";

@Component({
  selector: 'app-pool-preview',
  templateUrl: './pool-preview.component.html',
  styleUrls: ['./pool-preview.component.scss']
})
export class PoolPreviewComponent implements OnInit {
  @Input() public pool;

  constructor(
    public dateService: DateService,
  ) {
  }

  ngOnInit(): void {
  }
}
