import {Component, Input, OnInit} from '@angular/core';
import {USER_PERMISSIONS} from "../../../../enums/user-permissions.enum";
import {DateService} from "../../../../services/date.service";

@Component({
  selector: 'app-account-preview',
  templateUrl: './account-preview.component.html',
  styleUrls: ['./account-preview.component.scss']
})
export class AccountPreviewComponent implements OnInit {
  @Input() public account;
  public USER_PERMISSIONS: typeof USER_PERMISSIONS = USER_PERMISSIONS;

  constructor(
    public dateService: DateService
  ) {
  }

  ngOnInit(): void {
  }

}
