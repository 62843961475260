import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {POOL_STATUS} from '../../../enums/pool-status.enum';

@Component({
  selector: 'app-pool-home',
  templateUrl: './pool-home.component.html',
  styleUrls: ['./pool-home.component.scss']
})
export class PoolHomeComponent {
  public POOL_STATUS: typeof POOL_STATUS = POOL_STATUS;

  constructor(
    public router: Router,
  ) {
  }

  public filterPools(status = null) {
    this.router.navigate(['admin/co-financing/pool'], {queryParams: {status: status}});
  }
}
