<div class="pool-settings">
  <h2> Ustawienia pul</h2>
  <div *ngIf="!isLoading" class="row">
    <mat-card class="pool-settings__card">
      <h4> Osoby potrzebne do zaakceptowania puli</h4>
      <mat-slider [(ngModel)]="poolSettings.neededToAccept" class="pool-settings__slider" max="10" min="1" step="1"
                  thumbLabel="true">
      </mat-slider>
    </mat-card>
    <mat-card class="pool-settings__card">
      <h4> Osoby potrzebne do odrzucenia puli</h4>
      <mat-slider [(ngModel)]="poolSettings.neededToDecline" class="pool-settings__slider" max="10" min="1" step="1"
                  thumbLabel="true">
      </mat-slider>
    </mat-card>
  </div>
  <div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row">
    <button (click)="onFormSubmit()" color="primary" mat-raised-button> Zapisz</button>
  </div>
</div>
