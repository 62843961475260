import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {SweetalertService} from "../../../../services/sweetalert.service";
import {AccountHomeService} from "../account-home.service";
import {AccountManageService} from "./account-manage.service";
import {ErrorService} from '../../../../services/error.service';

@Component({
  selector: 'app-account-manage',
  templateUrl: './account-manage.component.html',
  styleUrls: ['./account-manage.component.scss']
})
export class AccountManageComponent implements OnInit {
  public activeSection = 1;
  public account;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private sweetalertService: SweetalertService,
    private dialogRef: MatDialog,
    private accountManageService: AccountManageService,
    private accountHomeService: AccountHomeService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.accountManageService.refreshUser(this.data.accountId);
    this.accountManageService.account.subscribe(data => {
      this.account = data;
    });
  }

  public setActiveSection(section: number) {
    this.activeSection = section;
  }

  public deleteAccount(id: number) {
    this.apiService.deleteUser(id).subscribe((response: any) => {
      this.accountHomeService.refreshUsersList();
      this.dialogRef.closeAll();
    }, error => {
      this.errorService.handleError(error);
    })
  }
}
