import {NgModule} from '@angular/core';
import {BooleanToString} from './pipes/booleanToString';
import {ProposalStatusToText} from './pipes/proposalStatusToText';
import {LoaderComponent} from './components/shared/loader/loader.component';
import {SummaryFieldComponent} from './components/shared/summary-field/summary-field.component';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './pages/shared/login/login.component';
import {RegisterComponent} from './pages/shared/register/register.component';
import {AccountRecoveryComponent} from './pages/shared/account-recovery/account-recovery.component';
import {RulesComponent} from './pages/shared/rules/rules.component';
import {RulesUsudeComponent} from './pages/shared/rules-usude/rules-usude.component';
import {ChangePasswordByRecoveryComponent} from './pages/shared/change-password-by-recovery/change-password-by-recovery.component';
import {ChangePasswordExpiredComponent} from './pages/shared/change-password-expired/change-password-expired.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {ScholarshipStatusToText} from './pipes/scholarshipStatusToText';
import {PoolStatusToText} from './pipes/poolStatusToText';
import {FileDownloadComponent} from './components/shared/file-download/file-download.component';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AccountActivateComponent} from './pages/shared/account-activate/account-activate.component';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatCardModule,
        RouterModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        MatCheckboxModule,
    ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    AccountActivateComponent,
    AccountRecoveryComponent,
    RulesComponent,
    RulesUsudeComponent,
    ChangePasswordByRecoveryComponent,
    ChangePasswordExpiredComponent,
    BooleanToString,
    ProposalStatusToText,
    ScholarshipStatusToText,
    PoolStatusToText,
    SummaryFieldComponent,
    FileDownloadComponent,
    LoaderComponent,
  ],
  exports: [
    BooleanToString,
    ProposalStatusToText,
    ScholarshipStatusToText,
    PoolStatusToText,
    SummaryFieldComponent,
    FileDownloadComponent,
    LoaderComponent,
  ],
  providers: []
})
export class SharedModule {}
